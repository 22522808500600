const fix = (num, args) => {
    const { decimals = 2, toLocaleString = true } = args || {};
    const val = Number(Number(num).toFixed(decimals));
    if (!val || isNaN(val)) return 0;
    else return toLocaleString ? val.toLocaleString() : val;
};

const epc = (cost, clicks, toLocaleString) => fix(cost / (clicks || 1), { toLocaleString });
const epl = (cost, leads, toLocaleString) => fix(cost / (leads || 1), { toLocaleString });
const signupPerc = (leads, impressions, toLocaleString) => fix((leads / (impressions || 1)) * 100, { toLocaleString });
const qftdPerc = (conversions, impressions, toLocaleString) =>
    fix((conversions / (impressions || 1)) * 100, toLocaleString);

export const calc = {
    fix,
    epc,
    epl,
    signupPerc,
    qftdPerc,
};
