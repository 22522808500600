import { styled, g, css } from "UIV2/base/styledMixins/styledMixins.js";

export const SnackbarContainer = styled.div`
    width: 60rem;
    max-height: 100vh;
    position: fixed;
    bottom: 0;
    right: 0;
    display: ${(props) => (props.snackState ? "flex" : "none")};
    flex-direction: column;
    z-index: 2003;
    align-items: flex-end;
    ${g.phone(
        css`
            max-width: 100vw;
        `,
    )}
`;
export const SingleSnackbarContainer = styled.div`
    opacity: 0.9;
    color: white;
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 250ms ease-in-out;
    width: 100%;
    min-height: 8rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    box-sizing: border-box;
    background-color: ${(props) =>
        props.status === "success"
            ? g.getColor("success")
            : props.status === "error"
            ? g.getColor("error")
            : g.getColor("primary")};
`;

export const IconArea = styled.div`
    display: flex;
    width: 4rem;
    min-width: 4rem;
    justify-content: flex-start;
`;
export const TextArea = styled.div`
    flex: 1 auto;
    white-space: wrap;
    overflow: hidden;
`;
export const ControlArea = styled.div`
    width: 2rem;
    display: flex;
    justify-content: ${(props) => (props.manualControl ? "flex-end" : "center")};
`;
