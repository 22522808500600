import { _, typeOfAdvanced, DateTime } from "UIV2";
import { LOCAL_STORAGE_NAMES, LOCAL_STORAGE_VALID_TIME } from "UIV2/base/context/variables/variables.js";
import { dummyResponses } from "./callApiISO.dummyResponses.js";
import axios from "axios";

const latestApiResponses = LOCAL_STORAGE_NAMES.latestApiResponses;

const getLsData = () => {
    const latestStoreEntries = JSON.parse(localStorage.getItem(latestApiResponses)) || {};
    return {
        latestStoreEntries,
        currentTs: DateTime.now().ts,
        allowedTimeDiff: LOCAL_STORAGE_VALID_TIME,
    };
};
const removeExpiredLines = () => {
    const { latestStoreEntries, currentTs, allowedTimeDiff } = getLsData();
    _.forOwn(latestStoreEntries, (it, url) => {
        const { isValid } = controller(it, currentTs, allowedTimeDiff);
        if (!isValid) {
            delete latestStoreEntries[url];
        }
    });
    localStorage.setItem(latestApiResponses, JSON.stringify(latestStoreEntries));
};
const controller = (latestStoreEntry, currentTs, allowedTimeDiff) => {
    let isValid = true;
    if (
        !latestStoreEntry ||
        !latestStoreEntry?.ts ||
        !latestStoreEntry?.data ||
        latestStoreEntry?.ts < currentTs - allowedTimeDiff
    )
        isValid = false;
    return { isValid, responseFromLocalStore: isValid ? latestStoreEntry?.data : null };
};
const getLatestApiResponseFromLocalStore = (url) => {
    if (!url) return { isValid: false };
    const { latestStoreEntries, currentTs, allowedTimeDiff } = getLsData();
    const latestStoreEntry = latestStoreEntries && latestStoreEntries[url];
    removeExpiredLines();
    return controller(latestStoreEntry, currentTs, allowedTimeDiff);
};
const saveLatestApiResponseToLocalStore = (url, parsed) => {
    if (!url) return;
    const latestStoreEntries = JSON.parse(localStorage.getItem(latestApiResponses));
    localStorage.setItem(
        latestApiResponses,
        JSON.stringify({
            ...latestStoreEntries,
            [url]: {
                ts: DateTime.now().ts,
                data: parsed,
            },
        }),
    );
};
export const callApiISO = (p = {}, states = {}) => {
    const { method = "GET", url, token, params, thenFn, catchFn, killOnError } = p;
    const { useDummy, useLocalStore, hideApiLoading, useNyxar, tokenOverwrite } = p;
    const { isDevMode, VAR, setApiLoading, addToSnackbar, hideApiCallsLogs, goTo } = states;

    /**
     * Remember Latest ApiResponse and Serve Them without a new call
     * To enable this feature, props has to include useLocalStore: true;
     * This function has to pass metadata calls.
     */
    if (useLocalStore && method === "GET" && !url.includes("metadata")) {
        const { isValid, responseFromLocalStore } = getLatestApiResponseFromLocalStore(url);
        if (isValid) {
            if (setApiLoading) setApiLoading(false);
            if (isDevMode && !hideApiCallsLogs) console.log("call via LOCAL>>", url);
            const parsed = responseParser(responseFromLocalStore, true, VAR);
            return parsed;
        }
        // if it is not valid, continue to ordinary process
    }

    /**
     * Default Api Call Process
     */
    if (setApiLoading && !hideApiLoading) setApiLoading(true);

    const config = axiosConfig(method, url, params, token, addToSnackbar, goTo, useNyxar, tokenOverwrite);
    const handleResponse = (res, err = false) => {
        let parsed = responseParser(res, err, VAR);
        if (!parsed.isValid) {
            if (isDevMode && dummyResponses[url]) {
                /** Try to get data from dummy responses for test purposes. */
                addToSnackbar({ status: "error", msg: "Endpoint error. devMode uses a dummy response." });
                if (setApiLoading) setApiLoading(false);
                return responseParser(dummyResponses[url], false, VAR);
            } else if (killOnError) {
                addToSnackbar({ status: "error", msg: "Fatal error on " + url });
                goTo("/error/400");
                if (setApiLoading) setApiLoading(false);
                return parsed;
            } else {
                addToSnackbar({ status: "error", msg: parsed.message });
                if (setApiLoading) setApiLoading(false);
                return parsed;
            }
        }

        if (setApiLoading) setApiLoading(false);
        if (isDevMode && !hideApiCallsLogs) console.log("call via API>>", url);
        if (!err && thenFn) thenFn(parsed);
        if (err && catchFn) catchFn(parsed);

        saveLatestApiResponseToLocalStore(url, parsed);
        return parsed;
    };

    if (useDummy) {
        const match = Object.keys(dummyResponses).find((k) => url.startsWith(k));
        if (match) {
            return handleResponse(dummyResponses[match]);
        } else {
            addToSnackbar({ status: "error", msg: "Dummy Responses doesn't have " + url });
            return undefined;
        }
    }

    return axios(config)
        .then((res) => handleResponse(res))
        .catch((err) => handleResponse(err, true));
};

const localStoreName = LOCAL_STORAGE_NAMES.user;
export const axiosConfig = (method, url, params, token, addToSnackbar, goTo, useNyxar, tokenOverwrite) => {
    const config = {
        method: method,
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
        },
        url: `${useNyxar ? process?.env?.REACT_APP_NYXAR_URL : process?.env?.REACT_APP_API_URL}${url}`,
        withCredentials: useNyxar ? false : true,
    };

    if (token !== false || tokenOverwrite) {
        if (!token) {
            const getUserDataFromLocalStorage = JSON.parse(localStorage.getItem(localStoreName));
            if (!getUserDataFromLocalStorage) {
                addToSnackbar({ status: "error", msg: "You have to sign-in for this action." });
                goTo("sign-in");
            }
            const localToken = getUserDataFromLocalStorage.authToken;
            config.headers["Authorization"] = `Bearer ${localToken}`;
        } else {
            config.headers["Authorization"] = tokenOverwrite || `Bearer ${token}`;
        }
    }
    if (params) {
        config.data = JSON.stringify(params);
    }
    return config;
};
const catchError = (arr) => {
    let error;
    _.forEach(arr, (val) => {
        if (!val) return;

        const type = typeOfAdvanced(val);

        if (type === "string") {
            error = val;
            return false;
        } else if (type === "array") {
            error = val[0];
            return false;
        } else if (type === "object") {
            const values = Object.values(val);
            // error = values[0] || undefined;
            error = values.join(" - ") || undefined;
            return false;
        } else if (type === "boolean") {
            error = Boolean(val);
            return false;
        }
    });

    return error;
};
const responseParser = (res, then = true, VAR) => {
    if (!res) return { isValid: false, message: "There isn't any response." };
    const status = res.status || res.statusCode || res.response?.status;
    const serverErrors = VAR.SERVER_ERRORS;
    const isValid = serverErrors[status] ? serverErrors[status][0] : serverErrors.default[0];
    // Try to Catch Error Msg or Show status
    const message =
        catchError([
            res.response?.data?.error,
            res.response?.data?.errors,
            res.response?.data?.data?.error,
            res.response?.data?.data?.errors,
        ]) ||
        serverErrors?.[status]?.[1] ||
        serverErrors?.default?.[1];
    const data = res.data?.data || res.data || undefined;
    const meta = res.data?.meta || res.meta || undefined;
    return {
        isValid: Boolean(isValid),
        status,
        message,
        ...(meta && { meta: meta }),
        ...(data && { data: data }),
        ...(res.response &&
            !then && {
                err: {
                    response: res.response || undefined,
                    errors: res.response?.data?.data?.errors || undefined,
                },
            }),
    };
};
