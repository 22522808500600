import { styled, css, g } from "UIV2/base/styledMixins/styledMixins.js";

export const Container = styled.div`
    position: absolute;
    background-color: ${g.getColor("primary")};
    opacity: 0.5;
    top: 0;
    height: 100%;
    z-index: 1;
    transition: all 250ms linear;

    left: 5rem;
    width: calc(100vw - 5rem);
    opacity: 0;
    pointer-events: none;

    ${(props) => {
        if (props.selectedItem) {
            return css`
                left: 10rem;
                width: calc(100vw - 10rem);
                opacity: 0.4;
                pointer-events: auto;
            `;
        }
    }}
`;
