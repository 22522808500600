import { _, stringCaseConvertor, VAR, queryString, typeOfAdvanced, isNumber } from "UIV2";

export const setFieldValueISO = (val, name, setForm) => {
    setForm(val, `fields.${name}.value`);
    setForm(val, `values.${name}`);
};
export const setIsFormReadyISO = ({ isFormLoaded, setForm, setIsFormLoaded }) => {
    if (isFormLoaded) return;
    setTimeout(() => {
        setIsFormLoaded(true);
        setForm(true, `isFormReady`);
    }, 250);
};
export const setFieldIsTouchedISO = (val, name, setForm) => setForm(val, `fields.${name}.isTouched`);
export const setFieldDisableValCheckISO = (val, name, setForm) => setForm(val, `fields.${name}.disableValidationCheck`);
export const bulkUpdateForFieldValuesISO = (obj, setForm, getForm) => {
    const currForm = getForm();
    _.forOwn(obj, (val, key) => {
        const currField = currForm?.fields && currForm.fields[key] ? currForm.fields[key] : undefined;
        if (!currField) return;
        const currValue = currField?.value;
        const type = typeOfAdvanced(val);
        let conVal = val;
        if (type === "string" && (val.toLowerCase() === "true" || val.toLowerCase() === "false")) {
            conVal = val.toLowerCase() === "true" ? true : false;
        }
        if (_.isEqual(currValue, conVal)) return;
        setFieldValueISO(conVal, key, setForm);
    });
};
export const createSchemaISO = ({
    setForm,
    getFieldValues,
    onResetHandler,
    getFormValues,
    bulkUpdateForFieldValues,
    setIsFormLoaded,
    setFieldValue,
    onChangeHandler,
    urlParams,
    localStore,
}) => {
    setForm({
        isDirty: false,
        isTouched: false,
        isValid: true,
        isSubmitting: false,
        isFormReady: false,
        fn: {
            getFieldValues,
            getFormValues,
            bulkUpdateForFieldValues,
            resetForm: onResetHandler,
            setFieldSilently: setFieldValue,
            setField: onChangeHandler,
        },
    });
    setTimeout(() => {
        if (urlParams) bulkUpdateForFieldValues(urlParams);
        else if (localStore) bulkUpdateForFieldValues(localStore);
    }, 250);
};

export const fieldBuilderISO = ({ p, setForm }) => {
    const { validationRules, name, defaultValue, outboundAdaptor } = p || {};
    const fieldSchema = {
        ...(validationRules && { validationRules: validationRules }),
        ...(outboundAdaptor && { outboundAdaptor: outboundAdaptor }),
        value: defaultValue,
        defaultValue: defaultValue,
        isTouched: false,
        isDirty: false,
        isValid: false,
        disableValidationCheck: false,
    };
    setForm(fieldSchema, `fields.${name}`);
    setForm(defaultValue, `values.${name}`);
};
export const fieldRemoverISO = ({ p, setForm }) => {
    const { name } = p || {};
    setForm(undefined, `fields.${name}`);
    setForm(undefined, `values.${name}`);
};

const prepareFormOutput = (getForm) => {
    const currentValues = {};
    const form = getForm();
    const { fields, isValid } = form;
    _.forOwn(fields, (field, fieldName) => {
        if (field?.value === undefined || field?.value === null) return;
        currentValues[fieldName] = field.outboundAdaptor ? field.outboundAdaptor(field.value, form) : field.value;
    });

    return { values: currentValues, isFormValid: isValid, form };
};

export const getFormValuesISO = (getForm) => prepareFormOutput(getForm);

export const getFieldValuesISO = (getForm) => {
    const { values } = prepareFormOutput(getForm);
    return { ...values };
};

export const resetFormISO = (setForm, getForm) => {
    const form = getForm();
    const fields = form?.fields;
    if (!fields) return;
    _.forOwn(fields, (field, fieldName) => {
        setFieldValueISO(field.defaultValue, fieldName, setForm);
    });
};

export const localStoreUrlParamsISO = (p) => {
    const {
        localStoreName,
        enableUrlParamsLocalStore,
        location,
        navigate,
        urlParamsInboundAdaptor,
        urlParamsOutboundAdaptor,
    } = p || {};
    /**
     * Local Storage
     */
    const variablesPrefix = VAR?.LOCAL_STORAGE_NAMES?.prefix || "";
    const camelPagename = stringCaseConvertor(localStoreName || "unknownName");
    const convertedName = `${variablesPrefix}${camelPagename}`;
    const localStore = JSON.parse(localStorage.getItem(convertedName));
    const setLocalStorage = (values) => {
        if (!enableUrlParamsLocalStore) return;
        return localStorage.setItem(convertedName, JSON.stringify(values));
    };
    /**
     * Search Params
     */
    let urlParams;
    if (location?.search) {
        const parsed = queryString.parse(location?.search, { arrayFormat: "bracket" });
        if (parsed) {
            //queryString converts numbers to string. Check it in here because otherwise Select input can cause issue.
            _.forOwn(parsed, (val, key) => {
                const type = typeOfAdvanced(val);
                if (type === "number") parsed[key] = val * 1;
                else if (type === "array") parsed[key] = val.map((it) => (isNumber(it) ? it * 1 : it));
            });
            urlParams = urlParamsInboundAdaptor ? urlParamsInboundAdaptor(parsed) : parsed;
        }
    }
    const setUrlParams = (values) => {
        if (!enableUrlParamsLocalStore) return;
        const stringified = queryString.stringify(
            urlParamsOutboundAdaptor ? urlParamsOutboundAdaptor(values) : values,
            { arrayFormat: "bracket" },
        );
        navigate(`${location?.pathname}?${stringified}`, { replace: true });
    };
    /**
     * Return
     */
    return { localStore, setLocalStorage, urlParams, setUrlParams };
};
