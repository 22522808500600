import { useDebouncedCallback, useContext, useEffect, _, useRef } from "UIV2";
import { XFormContext } from "../XForm";

export const ErrorDirtyController = () => {
    const { form, setForm, getForm, setIsFormReady } = useContext(XFormContext);
    const lastCheckedForm = useRef();

    const controller = () => {
        const formRef = getForm();
        const fields = formRef?.fields;
        if (_.isEqual(lastCheckedForm?.current?.fields, fields)) return;
        let isFormTouched = false;
        let isFormDirty = false;
        let isFormValid = true;

        if (fields) {
            _.forOwn(fields, (field, fieldName) => {
                if (!field) return;

                let fieldError = undefined;
                let isFieldDirty = false;
                let isFieldValid = true;

                // touch kontrolü
                if (field.isTouched && !isFormTouched) isFormTouched = true;
                // dirty kontrolü
                if (!_.isEqual(field.value, field.defaultValue)) {
                    isFieldDirty = true;
                    if (!isFormDirty) isFormDirty = true;
                }
                // error ve isValid kontrolü
                if (field.validationRules && !field.disableValidationCheck && field.validationRules.length > 0) {
                    _.forEach(field.validationRules, (ruleFn) => {
                        const { msg, status } = ruleFn(field.value, fields);
                        if (status === false) {
                            isFieldValid = false;
                            fieldError = msg;
                            isFormValid = false;
                            return false;
                        }
                    });
                }

                setForm(isFieldDirty, `fields.${fieldName}.isDirty`);
                setForm(isFieldValid, `fields.${fieldName}.isValid`);
                setForm(fieldError, `fields.${fieldName}.error`);
            });
        }
        setForm(isFormDirty, "isDirty");
        setForm(isFormTouched, "isTouched");
        setForm(isFormValid, "isValid");
        lastCheckedForm.current = getForm();
        setIsFormReady();
    };
    const controlDebouncer = useDebouncedCallback(controller, 500);
    useEffect(() => controlDebouncer(), [form?.fields]);

    /**
     * Return
     */
    return null;
};
