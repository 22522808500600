/*
HOW TO USE->

-- SETUP
const [myState, myStateSet, myStateGet] = useXState(currentValue or empty);
//call variables just same with useState
//myState is a hook. myStateGet is a ref file and optional.
//Use myState to keep hook active. Use ref if you need to access data before react async process.

-- SET
myStateSet(newValue, "formValues.test1");
// use this to access nested paths directly.
//OR
myStateSet(newValue);
// use this to change entire myState
const { updatedData } = setPageState(fieldState, `${sectionName}.formCreatorFields.${fieldName}`);
// update returns entire latest, updated state. This feature can use optionally.

-- GET from state
{myState}
// it is same with useState.

-- GET from ref
console.log(myStateGet("formValues.test1"));
// use this for aspecific path. ".current" doesn't needed.
// OR
console.log(myStateGet());
// use this to get entire ref.current value.

*/

import { useState, useRef } from "react";
import { default as _ } from "lodash";

export const useXState = (currentVal) => {
    const [state, setState] = useState(currentVal);
    const stateRef = useRef(currentVal);

    /**
     * State Manager
     */
    const isCurrValueEqualToNew = ({ path = "", ref, val }) => {
        let currVal = ref?.current;
        if (currVal) {
            const split = path.split(".");
            for (const it of split) {
                if (currVal[it]) currVal = currVal[it];
            }
        }
        if (_.isEqual(val, currVal)) return true;
        return false;
    };
    const stateManager = ({ path, val, set, ref }) => {
        const createNewState = (state, selector, newval) => {
            if (selector.length > 1) {
                let field = selector.shift();
                let subObject = {};
                try {
                    subObject = { ...createNewState(state[field], selector, newval) };
                } catch {
                    subObject = {
                        ...createNewState(state, selector, newval),
                    };
                }
                return { ...state, [field]: subObject };
            } else {
                let updatedState = {};
                updatedState[selector.shift()] = newval;
                return { ...state, ...updatedState };
            }
        };
        if (!path && !val) {
            ref.current = undefined;
            set();
            return { updatedData: undefined };
        } else if (!path) {
            const isEqual = isCurrValueEqualToNew({ path, ref, val });
            if (isEqual) return;
            ref.current = val;
            set(val);
            return { updatedData: val };
        } else {
            const pageState = ref.current || {};
            let selector = path?.split(".") || ["undefinedPath"];
            const newData = createNewState(pageState, selector, val);
            const isEqual = isCurrValueEqualToNew({ path, ref, val });
            if (isEqual) return;
            ref.current = newData;
            set(newData);
            return { updatedData: newData };
        }
    };
    const setHandler = (val, path) => stateManager({ path: path, val: val, set: setState, ref: stateRef });

    /**
     * Get Handler
     */
    const valueFromNestedPath = (source, path) => {
        // USAGE * valueFromNestedPath(testObj3, "test1.test2.test3")
        const incomingPath = path ? "current." + path : "current";
        if (!source) return undefined;
        if (!incomingPath || incomingPath === "") return source;
        const splittedPath = incomingPath.split(".");
        let currentLevel;
        let error;
        for (let i = 0; i < splittedPath.length; i++) {
            if (!currentLevel) {
                currentLevel = source[splittedPath[i]];
            } else {
                const newLevel = currentLevel[splittedPath[i]];
                if (newLevel === undefined) {
                    error = true;
                    break;
                }
                currentLevel = currentLevel[splittedPath[i]];
            }
        }
        if (error) return undefined;
        else return currentLevel;
    };
    const getHandler = (path) => {
        return valueFromNestedPath(stateRef, path);
    };

    /**
     * Return
     */
    return [state, setHandler, getHandler];
};
