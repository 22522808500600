import { useContext, MainContext, useState, useEffect, useRef, _, useEventListener, Scrollbars } from "UIV2";
import { OuterDiv, InnerDiv } from "./_styled.js";

export const FilterWrapper = ({ children, recalculate }) => {
    const { isFullScreen } = useContext(MainContext);
    const [height, setHeight] = useState();
    const outerArea = useRef();
    const innerArea = useRef();

    /**
     * Check if the view needs scrollBar or not
     */
    const calculateOverflown = () => {
        const coverElem = outerArea?.current;
        const innerElem = innerArea?.current;

        if (!coverElem || !innerElem) {
            setTimeout(calculateOverflown, 1000);
            setHeight();
            return;
        }
        const innerElemRect = innerElem.getBoundingClientRect();
        const innerElemHeight = innerElemRect.height;

        setHeight(innerElemHeight);
    };
    useEffect(() => calculateOverflown(), [recalculate]);
    useEventListener("resize", _.debounce(calculateOverflown, 500), window, { passive: true });

    /**
     * Return
     */
    return (
        <OuterDiv ref={outerArea} {...{ isFullScreen }}>
            <Scrollbars
                style={{ width: "100%", height: height || 75 }}
                // renderThumbHorizontal={(p) => <ThumbHorizontal {...p} />}
                // renderTrackHorizontal={(p) => <TrackHorizontal {...p} />}
                // autoHide
            >
                <InnerDiv ref={innerArea}>{children}</InnerDiv>
            </Scrollbars>
        </OuterDiv>
    );
};
