import { MainContext, useContext } from "UIV2";
import { StyledSvg } from "./Logo.styled.js";

export const Logo = ({ blockOnClick, style, negative, ...props }) => {
    const { goTo, isUserAllowedFor } = useContext(MainContext) || {};
    return (
        <StyledSvg
            viewBox="0 0 167 39"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
                if (!blockOnClick) {
                    if (isUserAllowedFor("GUEST_OR_INVALIDATED")) goTo("/sign-in");
                    else goTo("");
                }
            }}
            style={style}
        >
            <path
                fill={negative ? "#fff" : "#FAAD13"}
                d="M110.8,18.3l-12.2,8.5c-0.3,0.2-0.2,0.7,0.2,0.7l4.5,0c0.3,0,0.5,0.3,0.3,0.6l-1.9,3.2c-0.1,0.2,0,0.4,0.1,0.5
	l2.1,1.2c0.2,0.1,0.4,0,0.5-0.1l1.9-3.2c0.2-0.3,0.5-0.3,0.7,0l2.3,3.9c0.2,0.3,0.7,0.2,0.7-0.2l1.2-14.8
	C111.5,18.3,111.1,18.1,110.8,18.3z"
            />
            <path
                fill={negative ? "#fff" : "#0045DB"}
                d="M6.8,19.6c0,5.1,3.7,8.9,8.6,8.9c2.6,0,4.7-0.9,6.4-2.4c0.3-0.3,0.6-0.3,0.9,0l3.3,3.5v0c0.3,0.3,0.3,0.6,0,0.9
	c-2.6,2.7-6.4,4.4-10.5,4.4C6.9,35,0,28.2,0,19.6C0,11.1,6.9,4.2,15.4,4.2c4.2,0,7.9,1.7,10.5,4.3c0.3,0.3,0.3,0.6,0,0.9l-3.3,3.6
	c-0.3,0.3-0.6,0.3-0.9,0c-1.7-1.5-3.8-2.4-6.4-2.4C10.6,10.7,6.8,14.5,6.8,19.6z M35.1,3.3H30c-0.4,0-0.6,0.2-0.6,0.6v29.9
	c0,0.4,0.2,0.6,0.6,0.6h5.1c0.4,0,0.6-0.2,0.6-0.6V3.9C35.7,3.6,35.5,3.3,35.1,3.3z M42.7,3c-1.9,0-3.5,1.5-3.5,3.5
	c0,1.9,1.6,3.4,3.5,3.4c1.9,0,3.4-1.5,3.4-3.4C46.1,4.5,44.6,3,42.7,3z M45.3,13.5h-5.1v0c-0.4,0-0.6,0.2-0.6,0.6v19.6
	c0,0.4,0.2,0.6,0.6,0.6h5.1c0.4,0,0.6-0.2,0.6-0.6V14.2C45.9,13.8,45.7,13.5,45.3,13.5z M64.9,27.5c-0.3-0.3-0.6-0.3-0.9,0h0
	c-1.2,1-2.4,1.6-4,1.6c-2.8,0-4.7-2.2-4.7-5s2-5,4.7-5c1.5,0,2.6,0.6,3.8,1.6c0.3,0.3,0.6,0.3,0.9,0l3.1-3c0.2-0.3,0.3-0.6,0-0.9
	c-2-2.3-4.8-3.7-8.1-3.7c-6,0-10.7,4.8-10.7,11c0,6.1,4.7,11,10.7,11c3.3,0,6.2-1.5,8.2-3.7c0.3-0.3,0.2-0.6,0-0.9L64.9,27.5z
	 M92.3,13.5h-5.8c-0.3,0-0.5,0.1-0.7,0.3l-8.3,7.4V3.9c0-0.4-0.2-0.6-0.6-0.6h-5.1c-0.4,0-0.6,0.2-0.6,0.6v29.9
	c0,0.4,0.2,0.6,0.6,0.6h5.1c0.4,0,0.6-0.2,0.6-0.6v-4.7l2.5-2.7l5.8,7.7c0.2,0.3,0.4,0.4,0.8,0.4h5.5c0.5,0,0.6-0.3,0.4-0.8
	l-8.6-11.5l8.8-7.8C93.1,13.9,92.9,13.5,92.3,13.5z M149,13.5h-5.1c-0.4,0-0.6,0.2-0.6,0.6v11.1c0,1.6-1.2,3.7-4,3.7
	c-1.6,0-3.7-0.9-3.7-4.3V14.2c0-0.4-0.2-0.6-0.6-0.6h-5.1c-0.4,0-0.6,0.2-0.6,0.6V26c0,5.6,3.3,9,8,9c2.5,0,4.6-1.4,6.1-3.5l0.2,2.3
	c0,0.4,0.2,0.6,0.6,0.6h4.9c0.4,0,0.6-0.2,0.6-0.6V14.2h0C149.6,13.8,149.4,13.5,149,13.5z M165.5,13.5h-3V7.8
	c0-0.4-0.2-0.6-0.6-0.6h-5.1c-0.4,0-0.6,0.2-0.6,0.6v5.8h-3c-0.4,0-0.6,0.2-0.6,0.6v3.9c0,0.4,0.2,0.6,0.6,0.6h3v15.1
	c0,0.4,0.2,0.6,0.6,0.6h5.1c0.4,0,0.6-0.2,0.6-0.6V18.7h3c0.4,0,0.6-0.2,0.6-0.6v-3.9v0C166.1,13.8,165.9,13.5,165.5,13.5z
	 M106.4,4.8c-8.3,2.2-13,10.7-10.9,18.8c0.2,0.7,0.5,1.4,0.7,2.1l5.5-3.6l0,0c0,0,0-0.1,0-0.2c-1.3-4.7,1.5-9.4,6.4-10.7
	c4.8-1.3,9.7,1.4,10.9,6.1c1.3,4.7-1.5,9.5-6.4,10.8c0,0,0,0-0.1,0l-0.3,6.8c0.7-0.1,1.4-0.2,2.1-0.4c8.3-2.2,13.1-10.7,10.9-18.9
	C123.1,7.5,114.7,2.6,106.4,4.8z"
            />
        </StyledSvg>
    );
};
