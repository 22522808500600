//  background-color: ${(props) => (props.test2Color ? props.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.phone(css` background-color: green; `)}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};

import { styled, g } from "UIV2/base/styledMixins/styledMixins.js";

export const PublisherContainer = styled.div`
    position: relative;
    width: 100%;
    height: 5rem;
    margin-bottom: 0.3rem;
    transition: all 250ms ease-in-out;

    & > label {
        position: absolute;
        width: max-content;
        font-size: 1.2rem;
        font-weight: 500;
        margin-top: -1rem;
        margin-left: 1.5rem;
        padding: 0 1.5rem;
        z-index: 2;
        opacity: ${(props) => (props.value || props.isFocused ? 1 : 0)};
        color: ${(props) => (props.error ? g.getColor("error") : g.getColor("grey40"))};
        pointer-events: none;
        user-select: none;
        background: ${g.getColor("background")};
        border-radius: 1rem;
    }

    & > .ant-input {
        height: 5rem;
        background-color: transparent;
        transition: all 250ms ease-in-out;
        border: ${(props) => `0.1rem solid ${props.error ? g.getColor("error") : g.getColor("grey40")}`};
        border-radius: 1rem;
        font-size: 1.4rem;
        padding-left: 2rem;
        padding-top: 1.3rem;

        // chrome hack
        font-family: inherit;
        background-color: rgba(0, 0, 0, 0);
        color: inherit;
        -webkit-text-fill-color: inherit;
        box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
        font-variant-ligatures: no-common-ligatures;

        &::placeholder {
            font-size: 1.4rem;
            color: ${(props) => (props.error ? g.getColor("error") : g.getColor("grey60"))};
            font-variant-ligatures: no-common-ligatures;
            opacity: ${(props) => (props.isFocused ? 0 : 1)};
        }

        &:focus,
        &:hover {
            background-color: rgba(0, 0, 0, 0);
            color: inherit;
            -webkit-text-fill-color: inherit;
            box-shadow: 0 0 0px 1000px white inset;
            border-right-width: 0;
            outline: 0;
            box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
        }
    }
`;
