//  background-color: ${(props) => (props.test2Color ? props.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.phone(css` background-color: green; `)}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};

import { styled, g, css } from "UIV2/base/styledMixins/styledMixins.js";

export const PublisherButton = styled.button`
    ${(props) => {
        const { priority, disabled, isLoading } = props;
        return css`
            pointer-events: ${disabled || isLoading ? "none" : "auto"};
            opacity: ${disabled ? 0.5 : 1};
            border-color: ${disabled
                ? "transparent"
                : priority === "primary"
                ? g.getColor("accent")
                : priority === "success"
                ? g.getColor("success")
                : priority === "error"
                ? g.getColor("error")
                : g.getColor("primary")};
            background-color: ${disabled
                ? g.getColor("grey20")
                : isLoading
                ? g.getColor("background")
                : priority === "primary"
                ? g.getColor("accent")
                : priority === "success"
                ? g.getColor("success")
                : priority === "error"
                ? g.getColor("error")
                : "transparent"};
            color: ${priority === "primary"
                ? g.getColor("background")
                : priority === "success"
                ? g.getColor("background")
                : priority === "error"
                ? g.getColor("background")
                : g.getColor("primary")};
        `;
    }}

    width: 100%;
    height: 5rem;
    min-width: 10rem;
    white-space: nowrap;
    font-variant-ligatures: no-common-ligatures;
    border-width: 1px;
    border-style: solid;
    border-radius: 1rem;
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0.3rem;
    text-transform: uppercase;
    margin: 0.3rem 0 0.3rem 0;
    padding: 1rem 2rem;
    transition: all 150ms ease;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        margin: 0 0 0.6rem 0;
        box-shadow: 0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2);
    }

    &:active {
        margin: 0.6rem 0 0 0;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
`;
export const LandingButton = styled.button`
    ${(props) => {
        const { priority, disabled, loading, innerLoading } = props;
        return css`
            pointer-events: ${disabled || loading || innerLoading ? "none" : "auto"};
            opacity: ${disabled ? 0.5 : 1};
            border-color: ${disabled
                ? "transparent"
                : priority === "primary"
                ? g.getColor("accent")
                : priority === "success"
                ? g.getColor("success")
                : priority === "error"
                ? g.getColor("error")
                : g.getColor("primary")};
            background-color: ${disabled
                ? g.getColor("grey20")
                : loading || innerLoading
                ? g.getColor("background")
                : priority === "primary"
                ? g.getColor("accent")
                : priority === "success"
                ? g.getColor("success")
                : priority === "error"
                ? g.getColor("error")
                : "transparent"};
            color: ${priority === "primary"
                ? g.getColor("background")
                : priority === "success"
                ? g.getColor("background")
                : priority === "error"
                ? g.getColor("background")
                : g.getColor("primary")};
        `;
    }}

    width: 100%;
    height: 5rem;
    min-width: 10rem;
    font-variant-ligatures: no-common-ligatures;
    border-width: 0.1rem;
    border-style: solid;
    border-radius: 5rem;
    font-size: 1.6rem;
    font-weight: 700;
    margin: 0 0 0.2rem 0;
    padding: 0 2rem;
    transition: background-color 250ms ease-in-out;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.3);
    }

    &:active {
        margin: 0.2rem 0 0 0;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    }
`;
export const TextButton = styled.button`
    font-variant-ligatures: no-common-ligatures;
    position: relative;
    margin: 0 0.5rem;
    margin-top: -0.1rem;
    user-select: none;

    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 3px;
        height: 0.2rem;
        width: 100%;
        transition: all 200ms ease-in-out;
        background-color: ${g.getColor("grey30")};
    }
    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 3px;
        height: 0.2rem;
        width: 0%;
        transition: all 200ms ease-in-out;
        background-color: ${g.getColor("primary")};
    }

    &:hover::after {
        width: 100%;
        background-color: ${g.getColor("accent")};
    }
    &.disabled {
        cursor: default;
        pointer-events: none;
        opacity: 0.3;
        text-decoration: line-through;
    }
`;
export const SpinnerArea = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const LoadingIconArea = styled.div`
    position: absolute;
    padding-top: 1rem;
`;
export const LoadingIcon = styled.img`
    height: 2.5rem;
`;
