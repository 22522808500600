import { PxaLottiePlayer } from "UIV2";
import clickOutLogo from "./clickOut2.json";

export const startTimeoutFnISO = ({
    forIsLoading,
    timeoutRef,
    setInnerTimer,
    setIsFirstPlay,
    isFirstPlay,
    setPlayTriggerForLottie,
}) => {
    clearTimeout(timeoutRef.current);
    setInnerTimer(true);
    timeoutRef.current = setTimeout(
        () => {
            setInnerTimer(false);
            if (isFirstPlay) setIsFirstPlay(false);
        },
        forIsLoading && isFirstPlay ? 1000 : 250,
    );
    if (forIsLoading && isFirstPlay) setPlayTriggerForLottie(1);
};

export const closeTriggersISO = ({
    isLoading,
    apiLoading,
    innerTimer,
    setIsLoadingAfterDelay,
    setInnerApiTrigger,
    setIsLoadingAfterDelayX2,
    timeoutRefX2,
}) => {
    if (!isLoading && !innerTimer) {
        setIsLoadingAfterDelay(false);
        timeoutRefX2.current = setTimeout(() => setIsLoadingAfterDelayX2(false), 500);
        setInnerApiTrigger(false);
    }
};

export const setScrollISO = (isLoadingAfterDelay, innerApiTrigger, isMobileMenuOpen, isDevMode) => {
    if (isDevMode) return;
    if (isLoadingAfterDelay || isMobileMenuOpen || innerApiTrigger) {
        document
            .getElementsByTagName("body")[0]
            .setAttribute("style", "overflow-x:hidden;overflow-y:scroll;cursor:wait");
    } else {
        // document.getElementsByTagName("body")[0].setAttribute("style", "overflow:inherit;cursor:auto");
        document
            .getElementsByTagName("body")[0]
            .setAttribute("style", "overflow-x:hidden;overflow-y:scroll;cursor:auto");
    }
};

export const ClickOutLogoAnimation = ({ play, ...props }) => {
    return (
        <PxaLottiePlayer
            name="clickOut"
            src={clickOutLogo}
            autoplay={true}
            loop={true}
            speed="1"
            playPauseManually={play}
        />
    );
};
