import { stringCaseConvertor } from "UIV2";

export const newPageISO = (pageName, states) => {
    const { setIsLoading, setIsMobileMenuOpen, setPageName, isDevMode } = states || {};
    setIsLoading(isDevMode ? false : true);
    if (!isDevMode) window.scrollTo(0, 0);
    setIsMobileMenuOpen(false);
    setPageName(stringCaseConvertor(pageName, "camel") || "newPage");
    document.title = pageName ? `${pageName} - ClickOut` : "ClickOut";
};
