//  background-color: ${(props) => (props.test2Color ? props.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};

import { styled, g } from "UIV2/base/styledMixins/styledMixins.js";

export const TableContainer = styled.div`
    width: 100%;
    min-width: ${(p) => p.minWidthPx + "px"};
    margin: 1rem 0 3rem 0;

    &
        > .ant-table-wrapper
        > .ant-spin-nested-loading
        > .ant-spin-container
        > .ant-table
        > .ant-table-container
        > .ant-table-content
        > table {
        font-size: 1.2rem !important;
        font-weight: 500;
        color: ${g.getColor("primary")};

        & > thead {
            font-size: 1.4rem !important;
            font-weight: 700 !important;
            & > tr > .ant-table-column-sort {
                background-color: #e7edf1;
            }
        }

        & > tbody > tr {
            height: 4rem;

            &:nth-child(2n) {
                background-color: ${g.getColor("grey05")};
                & > .ant-table-cell-fix-left {
                    background-color: ${g.getColor("grey05")};
                }
            }

            & > td {
                padding: 0.1rem !important;
                &:first-child {
                    font-size: 1.3rem;
                    font-weight: 700;
                    padding-left: 1.5rem !important;
                }
            }

            & > .ant-table-cell-fix-left {
                background-color: white;
            }

            & > .ant-table-column-sort {
                background-color: inherit;
            }

            /* hover fix */
            & > .ant-table-cell-row-hover {
                /* background-color: ${g.getColor("grey30")} !important; */
                background-color: inherit !important;
            }
        }
    }
`;
