export const PROD_URL = "https://www.clickout.com";
export const SIGNUP_URL = "/sign-up";
export const CONTACT_URL = "https://www.clickout.com/contact_us";
export const API_DEBOUNCE_TIME = 1500;
export const SERVER_ERRORS = {
    200: [1, "Process successfully completed."],
    201: [1, "Process successfully completed."],
    204: [1, "Process successfully without content."],
    400: [0, "Bad request."],
    401: [0, "You're not allowed to see this page."],
    403: [0, "You're not allowed to see this page."],
    404: [0, "We couldn't find that you're looking for."],
    405: [0, "Method not allowed."],

    406: [0, "Not acceptable."],
    407: [0, "Proxy authentication required"],
    408: [0, "Request timeout"],

    500: [0, "Internal server error."],
    502: [0, "Bad gateway"],
    default: [0, "Unexpected error."],
};
export const LOCAL_STORAGE_NAMES = {
    prefix: "pub_",
    metadata: "pub_metadata",
    user: "pub_user",
    homeFilters: "pub_homeFilters",
    latestApiResponses: "pub_latestApiResponses",
};
export const LOCAL_STORAGE_VALID_TIME = 10 * 60 * 1000;
export const VAR = {
    PROD_URL,
    SIGNUP_URL,
    CONTACT_URL,
    SERVER_ERRORS,
    LOCAL_STORAGE_NAMES,
    API_DEBOUNCE_TIME,
    LOCAL_STORAGE_VALID_TIME,
};
