import { styled, g, css } from "UIV2/base/styledMixins/styledMixins.js";

const Container = styled.div`
    position: absolute;
    top: 0;
    left: ${(props) => (props.Side ? "calc(100% / 4)" : "-20rem")};
    z-index: 1;
    opacity: 1;
    mix-blend-mode: multiply;
    pointer-events: none;
    ${g.media(
        "phone,tablet",
        css`
            left: 0;
        `,
    )}
`;

export const ContentCircleTop = ({ Side }) => (
    <Container {...{ Side }}>
        <svg width="703" height="280" viewBox="0 0 703 280" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_1" data-name="Rectangle 1" width="703" height="280" fill="red" />
                </clipPath>
            </defs>
            <g id="Mask_Group_1" data-name="Mask Group 1" clipPath="url(#clip-path)">
                <circle
                    cx="293.5"
                    cy="293.5"
                    r="293.5"
                    transform="translate(58 -370)"
                    fill="none"
                    stroke="#F5F9FF"
                    strokeWidth="100"
                />
            </g>
        </svg>
    </Container>
);
