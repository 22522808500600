import { styled } from "UIV2/base/styledMixins/styledMixins.js";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;
export const HeaderTitle = styled.div`
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 3rem;
`;
export const BulletContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 2rem;
`;
export const IconArea = styled.div`
    flex: 0 5rem;
    min-width: 5rem;
    align-self: flex-start;
`;
export const TextArea = styled.div`
    align-self: flex-start;
`;
