import { PropTypes, _ } from "UIV2";

/**
 * USAGE
 * stringCaseConvertor("sdad asd as", "camel")
 */

export const stringCaseConvertor = (string, caseName = "camel") => {
    if (caseName === "camel") return _.camelCase(string);
    if (caseName === "pascal") return _.upperFirst(_.camelCase(string));
    if (caseName === "kebab") return _.kebabCase(string);
    if (caseName === "snake") return _.snakeCase(string);
    if (caseName === "constant") return _.upperCase(string).replace(/ /g, "_");
    if (caseName === "dot") return _.lowerCase(string).replace(/ /g, ".");
    if (caseName === "path") return _.lowerCase(string).replace(/ /g, "/");
    if (caseName === "lower") return _.lowerCase(string);
    if (caseName === "sentence") return _.upperFirst(_.lowerCase(string));
    if (caseName === "title") return _.startCase(_.camelCase(string));
    else return "undefined caseName > " + caseName;
};

//-------------------------------------------------------------
// 		PROPTYPES
//-------------------------------------------------------------

stringCaseConvertor.propTypes = {
    caseName: PropTypes.oneOf([
        "camel",
        "pascal",
        "kebab",
        "snake",
        "constant",
        "dot",
        "path",
        "lower",
        "sentence",
        "title",
    ]),
};
