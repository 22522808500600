/**
 * Usage
 * hexToRgbA("#ff0000",0.5) //
 * returns rgba(255,0,0,0.5)
 *
 * 1 is default for alpha
 */

export const hexToRgbA = (hex, alpha = 1) => {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
};
