//  background-color: ${(props) => (props.test2Color ? props.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.phone(css` background-color: green; `)}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};

import { styled, keyframes } from "UIV2/base/styledMixins/styledMixins.js";

const rotatingGradient = keyframes`
  0% {
        transform:rotate(0deg);
    }
    25% {
        transform:rotate(90deg);
    }
    50% {
        transform:rotate(180deg);
    }
    75% {
        transform:rotate(270deg);
    }
    100% {
        transform:rotate(360deg);
    }


`;

export const Purple = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 2002;
    background: #5f1ef6;
    background-image: -webkit-linear-gradient(125deg, rgb(0, 0, 219) 0%, rgb(0, 110, 219) 100%);
    // loading screen kapanış animasyonu süresi
    transition: ${(props) => (props.showMe ? "none" : "opacity 250ms ease-in-out")};
    pointer-events: ${(props) => (props.showMe ? "auto" : "none")};
    opacity: ${(props) => (props.showMe ? 1 : 0)};

    &:after {
        content: "";
        position: absolute;
        width: max(150vw, 150vh);
        height: max(150vw, 150vh);
        background-image: -webkit-linear-gradient(125deg, rgb(0, 0, 219) 0%, rgb(0, 150, 219) 100%);
        animation: ${rotatingGradient} 4s infinite;
        animation-timing-function: linear;
        z-index: -1;
    }
`;
export const LogoArea = styled.div`
    width: 25rem;
    max-width: 25rem;
    margin-bottom: 3rem;
`;
const fadeInOut = keyframes`
  0% {
        transform: rotate(-90deg);
    }
    100% {
        transform: rotate(0deg);
    }
`;
export const UpCircle = styled.div`
    transform: rotate(-90deg);
    opacity: 0.05;
    position: absolute;
    top: 0;
    left: 0;
    width: 30vw;
    min-width: 30rem;
    max-width: 50rem;
    height: 30vw;
    animation: ${fadeInOut} 500ms forwards;
    transform-origin: top left;
    animation-timing-function: ease-out;
`;
const fadeInOut2 = keyframes`
  0% {
        transform: rotate(181deg);
    }
    100% {
        transform: rotate(0deg);
    }
`;
export const UpSmallCircle = styled.div`
    transform: rotate(180deg);
    opacity: 1;
    position: absolute;
    top: 0;
    left: 20%;
    width: 15vw;
    height: 15vw;
    min-width: 20rem;
    max-width: 35rem;
    animation: ${fadeInOut2} 500ms forwards 250ms;
    transform-origin: top center;
    animation-timing-function: ease-out;
`;
