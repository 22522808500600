//  background-color: ${(props) => (props.test2Color ? props.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.phone(css` background-color: green; `)}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};

import { styled } from "UIV2/base/styledMixins/styledMixins.js";

export const StyledSvg = styled.svg`
    min-width: 10rem;
    cursor: pointer;
    opacity: 1;
    transition: all 500ms ease-in-out;
`;
