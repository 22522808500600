export const dummyResponses = {
    "metadata/widgetTypes": {
        statusCode: 200,
        data: {
            widgetTypes: [{ id: 1, label: "Native Grid" }],
            hashes: {
                widgetTypes: "251b5dc8eb972a35fbea3f526e9ea956",
            },
        },
    },
    "publisher/statistics/query": {
        isValid: true,
        status: 200,
        message: "Process successfully completed.",
        meta: {
            chart: [
                {
                    label: "24-03",
                    pre_impressions: 5250,
                    impressions: 1,
                    clicks: 5250,
                    submissions: 5250,
                    leads: 5250,
                    failures: 5250,
                    uftds: 5250,
                    conversions: 5250,
                    cost: 5250,
                    publisher_lead: 5250,
                    publisher_ftd: 5250,
                    deposit: 5250,
                    pstart: "2024-03-24 19:00:00+00",
                    ecpm: 5250,
                },
            ],
        },
        data: [
            {
                name: "row1",
                pre_impressions: 15255,
                impressions: 1,
                clicks: 5250,
                submissions: 5250,
                leads: 5250,
                failures: 5250,
                uftds: 5250,
                conversions: 5250,
                publisher_lead: 5250,
                publisher_ftd: 5250,
                presale_revenue: 5250,
                recurring_revenue: 5250,
                ftd_revenue: 5250,
                evergreen_cost: 5250,
                news_cost: 5250,
                link_building_cost: 5250,
                marketing_cost: 5250,
                deposit: 5250,
                revenue_share: 5250,
                flat_revenue: 5250,
                cpa_revenue: 5250,
                bet: 5250,
                flat_fee_cost: 5250,
                waterfall_blocked: 5250,
                publisher_referral_cost: 5250,
                cost: 0,
                ecpm: 5250,
            },
            {
                name: "row2",
                pre_impressions: 5250,
                impressions: 1,
                clicks: 5250,
                submissions: 5250,
                leads: 5250,
                failures: 5250,
                uftds: 5250,
                conversions: 5250,
                publisher_lead: 5250,
                publisher_ftd: 5250,
                presale_revenue: 5250,
                recurring_revenue: 5250,
                ftd_revenue: 5250,
                evergreen_cost: 5250,
                news_cost: 5250,
                link_building_cost: 5250,
                marketing_cost: 5250,
                deposit: 5250,
                revenue_share: 5250,
                flat_revenue: 5250,
                cpa_revenue: 5250,
                bet: 5250,
                flat_fee_cost: 5250,
                waterfall_blocked: 5250,
                publisher_referral_cost: 5250,
                cost: 0,
                ecpm: 5250,
            },
            {
                name: "",
                pre_impressions: 5250,
                impressions: 1,
                clicks: 5250,
                submissions: 5250,
                leads: 5250,
                failures: 5250,
                uftds: 5250,
                conversions: 5250,
                publisher_lead: 5250,
                publisher_ftd: 5250,
                presale_revenue: 5250,
                recurring_revenue: 5250,
                ftd_revenue: 5250,
                evergreen_cost: 5250,
                news_cost: 5250,
                link_building_cost: 5250,
                marketing_cost: 5250,
                deposit: 5250,
                revenue_share: 5250,
                flat_revenue: 5250,
                cpa_revenue: 5250,
                bet: 5250,
                flat_fee_cost: 5250,
                waterfall_blocked: 5250,
                publisher_referral_cost: 5250,
                cost: 0,
                ecpm: 5250,
            },
        ],
    },
};
