import { Logo, useContext, MainContext } from "UIV2";
import { Button } from "UIV2/libraries/useXForm";
import { Box, BoxHeaderArea, BoxFooterArea, BoxTitle, LogoArea, LogoSuffix } from "./Layout.styled.js";
import { BoxArea, FooterArea, FullWidth } from "./Layout.styled.js";

export const LandingBox = (p = {}) => {
    const { children, Side, logoSuffix, title, BoxFooter, wideBox } = p || {};
    const { goTo } = useContext(MainContext);

    /* */
    return (
        <>
            <BoxArea>
                <BoxHeaderArea>
                    <LogoArea onClick={() => (window.location.href = "https://www.clickout.com/")}>
                        <Logo blockOnClick />
                    </LogoArea>
                    {!logoSuffix ? null : <LogoSuffix>{logoSuffix}</LogoSuffix>}
                </BoxHeaderArea>
                <Box {...{ Side, wideBox }}>
                    {!title ? null : <BoxTitle>{title}</BoxTitle>}
                    <FullWidth>{children}</FullWidth>
                </Box>
                {!BoxFooter ? null : (
                    <BoxFooterArea>
                        <BoxFooter />
                    </BoxFooterArea>
                )}
            </BoxArea>
            <FooterArea>
                <div>
                    <Button
                        variant="text"
                        label="Terms of Use"
                        onClick={() => goTo("terms-of-use", "openAtBlank")}
                        marginLeft="0"
                    />
                    <Button
                        variant="text"
                        label="Privacy & Cookie Policies"
                        onClick={() => goTo("policies", "openAtBlank")}
                    />
                </div>
            </FooterArea>
        </>
    );
};
