//  background-color: ${(props) => (props.test2Color ? props.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.phone(css` background-color: green; `)}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};

import { styled, g } from "UIV2/base/styledMixins/styledMixins.js";

export const Container = styled.div`
    flex: 0 0 8rem;
    background: ${(props) => (!props.isMouseOver ? g.getColor("accent") : "rgba(0,0,0,0)")};
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 11;
    transition: all 100ms linear;
    width: 100%;
    cursor: pointer;

    & > div {
        flex: 0 0 8rem;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const Svg = styled.svg`
    width: 3rem;
    fill: ${g.getColor("background")};
    transition: all 150ms linear;
    opacity: 1;
`;
