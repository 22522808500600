import { useState, useRef, _, useContext, MainContext, useEffect } from "UIV2";
import { PublisherContainer } from "./TextArea.styled.js";
import { Input } from "antd";
const { TextArea: AntTextArea } = Input;
export const TextArea = ({
    name,
    value,
    label,
    error,
    required,
    style,
    onBlur,
    onFocus,
    onChange,
    forwardRef,
    ...props
}) => {
    const [autocompleteDisabled, setAutocompleteDisabled] = useState();
    const { currentTimes } = useContext(MainContext);
    const [isFocused, setIsFocused] = useState();
    const selectRef = useRef();

    /**
     * ForwardRef
     */
    useEffect(() => {
        if (forwardRef) forwardRef.current = selectRef?.current;
    }, [selectRef?.current]);

    /**
     * Functions
     */
    const onChangeAdaptor = (e) => onChange(e.target.value, name);

    /**
     * Hack for autuFill and autoComplete
     */
    const onFocusHack = () => {
        if (!autocompleteDisabled) {
            const el = document.getElementsByClassName("ant-input");
            _.forEach(el, (it) => {
                it.setAttribute("autocomplete", `new-${name}-${currentTimes?.dateTime?.ts}`);
                it.setAttribute("name", `new-${name}-${currentTimes?.dateTime?.ts}`);
                it.setAttribute("id", `new-${name}-${currentTimes?.dateTime?.ts}`);
            });
            setAutocompleteDisabled(true);
        }
    };

    /**
     * onBlur onFocus
     */
    const onInnerFocus = (e) => {
        onFocusHack();
        if (onFocus) onFocus(e, name);
        setIsFocused(true);
    };
    const onInnerBlur = (e) => {
        if (onBlur) onBlur(e, name);
        setIsFocused(false);
    };

    /**
     * Return
     */
    return (
        <PublisherContainer aria-label={label} {...{ value, error, isFocused }}>
            <label htmlFor={name}> {label + (required ? "*" : "") + (error ? " " + error : "")} </label>
            <AntTextArea
                ref={selectRef}
                value={value}
                onChange={onChangeAdaptor}
                placeholder={(required ? "*" : "") + label}
                name={name}
                rows={2}
                //
                // GET THOSE FROM PROP
                //
                style={{ resize: "none", minWidth: "20rem", ...style }}
                //
                // DEFAULT SETTINGS
                //
                size="small"
                autoComplete={`new-${name}`}
                onFocus={onInnerFocus}
                onBlur={onInnerBlur}
                {...props}
            />
        </PublisherContainer>
    );
};
