import { Routes, Route } from "react-router-dom";
import ErrorPages from "UIV2/pages/LandingPages/ErrorPages.js";
import { PrivateRoute } from "./PrivateRoute.js";
import { siteMap } from "./siteMap.js";

export const RouteBase = () => (
    <Routes>
        {siteMap.map((page, index) => (
            <Route key={index} exact path={page[2]} element={<PrivateRoute key={index} {...{ page, index }} />} />
        ))}
        <Route path="/error/:type" element={<ErrorPages pageName="Ups!" />} />
        <Route path="*" element={<ErrorPages pageName="Ups!" type={404} />} />
    </Routes>
);
