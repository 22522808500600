import { styled, g } from "UIV2/base/styledMixins/styledMixins.js";

const Container = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 0;
    pointer-events: none;

    & > svg {
        width: 100%;
        height: 100vh;
        min-height: 70rem;
        fill: ${g.getColor("accent")};
    }
`;

export const SideAreaBack = () => (
    <Container aria-label="SideAreaBack">
        <svg
            width="300"
            height="350"
            viewBox="0 0 300 350"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
        >
            <path d="M299.2,0H0.8v350h298.3c-22.5-29-38.3-96.4-38.3-175C260.8,96.4,276.6,29,299.2,0z" />
        </svg>
    </Container>
);
