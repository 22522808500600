import { Icon, IconArrowRight, useContext, MainContext } from "UIV2";
import { Container, BoxContainer, Col1, TitleArea } from "./DontHaveAnAccount.styled.js";
import { SubTextArea, Col2, HeaderTitle } from "./DontHaveAnAccount.styled.js";

export const DontHaveAnAccount = (p = {}) => {
    const { hideAdvertisersSignUp } = p;
    const { goTo, VAR } = useContext(MainContext);
    const { SIGNUP_URL, CONTACT_URL } = VAR;

    /**
     * Return
     */
    return (
        <Container aria-label="DontHaveAnAccount">
            <HeaderTitle>Don't Have An Account</HeaderTitle>
            <Box
                title="Affiliate Sign Up"
                subText="Accelerate your revenue and monetize your traffic."
                onClick={() => goTo(SIGNUP_URL)}
            />
            {!hideAdvertisersSignUp && (
                <Box
                    title="Advertisers Sign Up"
                    subText="Promote your product and acquire the right audience."
                    onClick={() => window.open(CONTACT_URL, "_blank")}
                />
            )}
        </Container>
    );
};

const Box = ({ title, subText, onClick }) => (
    <BoxContainer onClick={onClick}>
        <Col1>
            <TitleArea>{title}</TitleArea>
            <SubTextArea>{subText}</SubTextArea>
        </Col1>
        <Col2>
            <Icon color="background" icon={IconArrowRight} width={2} />
        </Col2>
    </BoxContainer>
);
