//  background-color: ${(props) => (props.test2Color ? props.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.phone(css` background-color: green; `)}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};

import { styled, g, css } from "UIV2/base/styledMixins/styledMixins.js";

export const Container = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    height: ${(props) => (props.isLoadingAfterDelayX2 || props.hideHeader || props.isFullScreen ? 0 : "8rem")};
    overflow: hidden;
    background: white;
    border-bottom: 1px solid ${g.getColor("grey20")};
    transition: all 250ms ease-in-out 500ms;
    margin-bottom: 2rem;

    ${g.phone(css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: ${(props) =>
            props.isLoadingAfterDelayX2 ? 0 : props.hideHeader || !props.headerActions ? "8rem" : "14rem"};
        border-bottom: none;
    `)}
`;

/**
 *
 */
export const HeaderTitleArea = styled.div`
    display: flex;
    height: 8rem;
    flex-wrap: nowrap;
    align-items: baseline;
    border-right: ${(props) => (props.isThereAnyActions ? "1px solid" + g.getColor("grey20") : "none")};

    ${g.phone(css`
        border-right: none;
    `)}
`;
export const TitleText = styled.div`
    font-weight: 300;
    font-size: 2.8rem;
    color: ${g.getColor("grey40")};
    line-height: 30px;
    padding: 2.5rem 0 0 3rem;

    & > span {
        margin: 0 3rem 0 0.5rem;
        font-size: 1.4rem;
        font-weight: 500;
    }

    ${g.phone(css`
        padding: 4rem 2rem 1rem 2rem;
        font-size: 2rem;
        font-weight: 500;
        color: ${g.getColor("primary")};
        & > span {
            font-size: 2rem;
            font-weight: 500;
        }
    `)}
`;

/**
 * Actions
 */
export const SlidableActionsAreaContainer = styled.div`
    flex: 1 auto;
    height: 8rem;
    display: ${(props) => (props.hideHeader ? "none" : "flex")};
    align-items: baseline;
    overflow: hidden;

    ${g.phone(css`
        height: 3rem;
        background: ${g.getColor("background")};
    `)}
`;
export const ActionsCoverElem = styled.div`
    flex: 1 auto;
    align-self: baseline;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;

    ${g.phone(css`
        align-self: center;
        padding: 0 2rem;
        overflow: auto;
        overflow-y: hidden;
    `)}
`;
export const ActionsInnerElem = styled.div`
    display: flex;
    flex-wrap: nowrap;
    color: ${g.getColor("grey40")};
    font-size: 1.4rem;
    font-weight: 500;
    width: max-content;
    transition: all 250ms ease-in-out;
`;
export const SliderButton = styled.button`
    width: 5rem;
    height: 8rem;
    min-height: 8rem;
    min-width: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${(props) => (props.hide ? 0 : 1)};
    pointer-events: ${(props) => (props.hide ? "none" : "auto")};
    &:hover {
        background: ${g.getColor("grey10")};
    }

    ${g.phone(css`
        display: none;
    `)}
`;
export const SwitchToFullScreenContainer = styled.div`
    width: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid ${g.getColor("grey20")};
    cursor: pointer;
    transition: all 250ms ease-in-out;
    &:hover {
        background-color: ${g.getColor("grey10")};
    }
    ${g.phone(css`
        display: none;
    `)}
`;
