/*

<Icon
  backgroundColor="#780000"
  backgroundHoverColor="#00f7ff"
  color="blue"
  hoverColor="red"
  icon={ IconHamburger }
  onClick={()=>{}}
  width={4.5}
/>

*/

import { React, useState } from "UIV2";
import { ColorFinderFromScss } from "UIV2";
import { IconComponent, SvgWrapper } from "./IconLib.styled";

//-------------------------------------------------------------
// 		ICON WRAPPER
//-------------------------------------------------------------

export const Icon = ({
    icon = IconHamburger,
    iconName,
    color,
    hoverColor,
    backgroundColor,
    backgroundHoverColor,
    width = 2,
    onClick,
    style,
    ...props
}) => {
    const [isHover, setIsHover] = useState(false);
    const IncomingSvg = icon;

    const colorStyle = !isHover ? ColorFinderFromScss(color || "primary") : ColorFinderFromScss(hoverColor || "accent");
    const bColorStyle = !isHover
        ? ColorFinderFromScss(backgroundColor || "transparent")
        : ColorFinderFromScss(backgroundHoverColor || "transparent");

    return (
        <IconComponent
            {...{ width, bColorStyle, hoverEnabled: onClick }}
            tabIndex={onClick ? 0 : ""}
            aria-label="I am an Icon"
            onMouseEnter={() => {
                if (onClick) setIsHover(true);
            }}
            onMouseLeave={() => {
                if (onClick) setIsHover(false);
            }}
            onClick={!onClick ? null : () => onClick()}
            style={style}
            {...props}
        >
            <SvgWrapper
                {...{ colorStyle }}
                fill={!isHover ? ColorFinderFromScss(color || "primary") : ColorFinderFromScss(hoverColor || "accent")}
                width={width}
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 20 20"
                space="preserve"
            >
                <IncomingSvg />
                <rect style={{ fill: "rgba(0,0,0,0)" }} width="20" height="20" />
            </SvgWrapper>
        </IconComponent>
    );
};

//-------------------------------------------------------------
// 		ICON LIB
//-------------------------------------------------------------

export const IconFx = () => (
    <path d="M7,2.72v2l-7,0v-2ZM0,8.25v9H2l0-7,5,0,0-2Zm11,9L19.78,2.74H17.31L8.57,17.28Zm.61-14.51H9.15l2.76,4.79,1.27-2Zm5.91,14.51H20l-3.16-5.44-1.25,2.07Z" />
);
export const IconClickOut = () => (
    <path d="M10.3,9.2l-8,5.5c-0.2,0.1-0.1,0.5,0.2,0.5h3c0.2,0,0.3,0.2,0.2,0.4l-1.2,2.1c-0.1,0.1,0,0.3,0.1,0.4l1.4,0.8 c0.1,0.1,0.3,0,0.4-0.1l1.2-2.1c0.1-0.2,0.3-0.2,0.5,0l1.5,2.5c0.1,0.2,0.5,0.1,0.5-0.1l0.8-9.6C10.7,9.2,10.4,9,10.3,9.2z M4.4,11.7c0,0,0-0.1,0-0.1c-0.8-3,1-6.1,4.1-7s6.3,0.9,7.1,4c0.8,3-1,6.2-4.2,7h0L11.2,20c0.5,0,0.9-0.1,1.4-0.2 c5.4-1.5,8.5-6.9,7.1-12.2C18.2,2.1,12.8-1,7.4,0.4C2,1.8-1.1,7.3,0.3,12.6c0.1,0.5,0.3,0.9,0.5,1.3L4.4,11.7L4.4,11.7z" />
);
export const IconCheck = () => <path d="M7.5,12.5l10-10L20,5,7.5,17.5,0,10,2.5,7.5l5,5Z" />;

export const IconClose = () => (
    <path d="M17.11,15.24h0a1.32,1.32,0,1,1-1.87,1.87L10,11.87,4.76,17.11h0a1.32,1.32,0,1,1-1.87-1.87L8.12,10,2.89,4.76A1.32,1.32,0,0,1,4.76,2.89L10,8.13l5.24-5.24a1.32,1.32,0,0,1,1.87,1.87L11.87,10Z" />
);
export const IconCloseThin = () => (
    <path d="M19,18.27a.48.48,0,0,1,0,.7.48.48,0,0,1-.7,0L10,10.71,1.73,19A.48.48,0,0,1,1,19a.48.48,0,0,1,0-.7L9.29,10,1,1.73A.48.48,0,0,1,1,1a.48.48,0,0,1,.7,0L10,9.29,18.27,1A.48.48,0,0,1,19,1a.48.48,0,0,1,0,.7L10.71,10Z" />
);
export const IconDot = () => <path d="M12,10a2,2,0,1,1-2-2h0A2,2,0,0,1,12,10Z" />;
export const IconHamburger = () => (
    <path d="M0,4A1,1,0,0,1,1,3H19a1,1,0,0,1,0,2H1A1,1,0,0,1,0,4Zm1,7H19a1,1,0,0,0,0-2H1a1,1,0,1,0,0,2Zm0,4a1,1,0,0,0-1,1,1,1,0,0,0,1,1H19a1,1,0,0,0,1-1,1,1,0,0,0-1-1Z" />
);
export const IconThreeDot = () => (
    <path d="M12,2c0,1.1-0.9,2-2,2C8.9,4,8,3.1,8,2c0-1.1,0.9-2,2-2C11.1,0,12,0.9,12,2C12,2,12,2,12,2z M10,8C10,8,10,8,10,8 c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2S11.1,8,10,8z M10,16C10,16,10,16,10,16c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2 c1.1,0,2-0.9,2-2S11.1,16,10,16z" />
);
export const IconTrash = () => (
    <path d="M3.3,17.8c0,1.2,1,2.2,2.2,2.2h8.9 c1.2,0,2.2-1,2.2-2.2V5H3.3V17.8z M18.3,1.7h-4.2L12.8,0H7.2L5.8,1.7H1.7v1.7h16.7V1.7z" />
);
export const IconCancel = () => (
    <path d="M10,0a10,10,0,1,0,.07,0Zm5,13.6L13.6,15,10,11.4,6.4,15,5,13.6,8.6,10,5,6.4,6.4,5,10,8.6,13.6,5,15,6.4,11.4,10,15,13.6Z" />
);
export const IconNotification = () => (
    <path d="M18.73,13.3l-1.85-1.86V8.28a6.93,6.93,0,0,0-6.19-6.87V0H9.31V1.41A7,7,0,0,0,3.12,8.28v3.16L1.27,13.3a.63.63,0,0,0-.21.48v2.06a.65.65,0,0,0,.6.69h4.9a3.44,3.44,0,0,0,6.88,0h4.81a.64.64,0,0,0,.69-.6V13.78A.63.63,0,0,0,18.73,13.3ZM10,18.59a2.06,2.06,0,0,1-2.06-2.06h4.12A2.06,2.06,0,0,1,10,18.59Z" />
);
export const IconReset = () => (
    <path d="M18.8,9.2C18.6,8,18.1,6.9,17.5,6C16.8,5,16,4.2,15,3.5C13.5,2.5,11.8,2,10,2V0L6,3l4,3V4c3.9,0,7,3.1,7,7 c0,0.9-0.2,1.9-0.6,2.7c-0.2,0.4-0.4,0.8-0.6,1.2c-0.3,0.4-0.5,0.7-0.9,1c-1,1-2.2,1.6-3.5,1.9c-0.9,0.2-1.9,0.2-2.8,0 C5.3,17.2,3,14.3,3,11H1c0,5,4,9,9,9c1.8,0,3.5-0.5,5-1.5c0.5-0.3,0.9-0.7,1.3-1.1c0.4-0.4,0.8-0.9,1.1-1.3c1-1.5,1.5-3.2,1.5-5 C19,10.4,18.9,9.8,18.8,9.2L18.8,9.2z" />
);
export const IconReport = () => (
    <path d="M17.5,20h-15c-0.3,0-0.6-0.1-0.9-0.4 c-0.2-0.2-0.4-0.6-0.4-0.9V1.3c0-0.3,0.1-0.7,0.4-0.9C1.9,0.1,2.2,0,2.5,0h8.7v6.9c0,0.3,0.3,0.6,0.6,0.6h6.9v11.2 C18.8,19.4,18.2,20,17.5,20C17.5,20,17.5,20,17.5,20L17.5,20z M15.6,16.2H15v-4.4c0-0.3-0.3-0.6-0.6-0.6h-1.2 c-0.3,0-0.6,0.3-0.6,0.6v4.4h-1.2V9.4c0-0.3-0.3-0.6-0.6-0.6H9.4C9,8.8,8.8,9,8.7,9.4v6.9H7.5v-3.1c0-0.3-0.3-0.6-0.6-0.6H5.6 c-0.3,0-0.6,0.3-0.6,0.6v3.1H4.4c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h11.3c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4 C16.2,16.5,16,16.3,15.6,16.2L15.6,16.2z M12.5,0c0.3,0,0.6,0.1,0.9,0.4l5,5c0.2,0.2,0.4,0.6,0.4,0.9h-6.3V0z" />
);
export const IconOffer = () => (
    <path d="M11,20C10.3,20,9.7,20,9,20l0.2-2c0.5,0.1,1.1,0.1,1.6,0L11,20L11,20z M7.1,19.6l0.6-1.9c-0.5-0.2-1-0.4-1.4-0.6l-0.9,1.8 C5.9,19.1,6.5,19.4,7.1,19.6L7.1,19.6z M3.7,17.7l1.3-1.5c-0.4-0.3-0.8-0.7-1.1-1.1l-1.5,1.3C2.7,16.8,3.1,17.3,3.7,17.7z M1.2,14.7 l1.8-0.9c-0.2-0.5-0.4-0.9-0.6-1.4l-1.9,0.6C0.6,13.5,0.9,14.1,1.2,14.7L1.2,14.7z M0,11l2-0.2C2,10.3,2,9.7,2,9.2L0,9 C0,9.7,0,10.3,0,11L0,11z M0.4,7.1l1.9,0.6c0.2-0.5,0.4-1,0.6-1.4L1.2,5.3C0.9,5.9,0.6,6.5,0.4,7.1L0.4,7.1z M2.3,3.7l1.5,1.3 c0.3-0.4,0.7-0.8,1.1-1.1L3.7,2.3C3.2,2.7,2.7,3.1,2.3,3.7L2.3,3.7z M5.3,1.2l0.9,1.8c0.5-0.2,0.9-0.4,1.4-0.6L7.1,0.4 C6.5,0.6,5.9,0.9,5.3,1.2L5.3,1.2z M9,0C9.7,0,10.3,0,11,0l-0.2,2C10.3,2,9.7,2,9.2,2L9,0L9,0z M12.9,0.4l-0.6,1.9 c0.5,0.2,1,0.4,1.4,0.6l0.9-1.8C14.1,0.9,13.5,0.6,12.9,0.4L12.9,0.4z M16.3,2.3l-1.3,1.5c0.4,0.3,0.8,0.7,1.1,1.1l1.5-1.3 C17.3,3.1,16.9,2.7,16.3,2.3L16.3,2.3z M18.8,5.3l-1.8,0.9c0.2,0.5,0.4,0.9,0.6,1.4l1.9-0.6C19.4,6.5,19.1,5.9,18.8,5.3L18.8,5.3z M20,9l-2,0.2c0.1,0.5,0.1,1.1,0,1.6l2,0.2C20,10.3,20,9.7,20,9L20,9z M19.6,12.9l-1.9-0.6c-0.2,0.5-0.4,1-0.6,1.4l1.8,0.9 C19.1,14.1,19.4,13.5,19.6,12.9L19.6,12.9z M17.7,16.3l-1.5-1.3c-0.3,0.4-0.7,0.8-1.1,1.1l1.3,1.5C16.9,17.3,17.3,16.9,17.7,16.3z M14.7,18.8l-0.9-1.8c-0.5,0.2-0.9,0.4-1.4,0.6l0.6,1.9C13.5,19.4,14.1,19.1,14.7,18.8L14.7,18.8z M10,7c-1.7,0-3,1.3-3,3s1.3,3,3,3 s3-1.3,3-3S11.7,7,10,7z M10,11c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1C11,10.6,10.6,11,10,11z M10,4c-3.3,0-6,2.7-6,6 s2.7,6,6,6s6-2.7,6-6S13.3,4,10,4z M10,14c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4c2.2,0,4,1.8,4,4C14,12.2,12.2,14,10,14z" />
);
export const IconPixel = () => <path d="M0,0h9.6v9.6H0V0z M9.6,9.6H20V20H9.6V9.6z" />;
export const IconDash = () => (
    <path d="M2.3,17.2c1.3,0,3.1,0,5.3-0.1c1.9,0,3.8-0.7,5.1-2.1 c1.4-1.4,2.1-3.3,2-5.3c0-1.9-0.7-3.7-2.1-5c-1.4-1.3-3.3-2-5.2-1.9L2.3,2.8V0c0.8,0,2.3,0,4.5,0c2.9-0.2,5.7,0.8,7.9,2.6 c2,1.9,3.1,4.5,3,7.3c0,1.2-0.2,2.4-0.5,3.5c-0.3,1-0.9,2-1.5,2.8c-0.6,0.8-1.4,1.5-2.2,2c-0.8,0.5-1.7,1-2.6,1.3 C10,19.8,9.1,19.9,8.2,20c-0.9,0-1.8,0-2.8,0H2.3L2.3,17.2z" />
);
export const IconUser = () => (
    <path d="M3.4,20c-0.9,0-1.6-0.7-1.7-1.6c0,0,0,0,0-0.1c0-1.7,1.7-6.7,8.3-6.7s8.3,5,8.3,6.7c0,0.9-0.7,1.6-1.6,1.7 c0,0,0,0-0.1,0H3.4z M10.1,10c2.8,0,5-2.2,5-5s-2.2-5-5-5s-5,2.2-5,5S7.3,10,10.1,10z" />
);
export const IconMail = () => (
    <path d="M19.3,2.3H0.7C0.3,2.3,0,2.6,0,2.9l0,0v1.3c0,0.1,0.1,0.1,0.1,0.2L9.9,10c0.1,0,0.1,0,0.2,0l9.5-5.6c0.1,0,0.2-0.1,0.2-0.1 c0.1,0,0.1-0.1,0.1-0.2V2.9C20,2.6,19.7,2.3,19.3,2.3z M19.9,6.3c-0.1,0-0.1,0-0.2,0l-5.4,3.2c-0.1,0-0.1,0.1-0.1,0.2c0,0,0,0,0,0 l5.4,5.8c0.1,0.1,0.2,0.1,0.2,0c0,0,0.1-0.1,0.1-0.1v-9C20,6.4,20,6.3,19.9,6.3L19.9,6.3z M12.8,10.5c-0.1-0.1-0.1-0.1-0.2,0 l-2.2,1.3c-0.3,0.1-0.6,0.1-0.8,0l-1.9-1.1c-0.1,0-0.1,0-0.2,0l-7.2,6.7c-0.1,0.1-0.1,0.2,0,0.2c0,0,0,0,0,0 c0.1,0.1,0.2,0.1,0.3,0.1h18.5c0.1,0,0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.1L12.8,10.5z M5.9,9.9C6,9.8,6,9.7,5.9,9.7c0,0,0,0,0,0L0.2,6.4 c-0.1,0-0.2,0-0.2,0.1c0,0,0,0.1,0,0.1V15c0,0.1,0.1,0.2,0.2,0.2c0,0,0.1,0,0.1,0L5.9,9.9z" />
);
export const IconPhone = () => (
    <path d="M20,15.8c0,0.3-0.1,0.7-0.1,1c-0.1,0.3-0.2,0.7-0.3,1c-0.4,0.7-1,1.2-1.7,1.5C17,19.7,16.1,20,15.2,20 c-0.3,0-0.5,0-0.8,0c-0.3,0-0.5-0.1-0.8-0.2c-0.3-0.1-0.5-0.2-0.7-0.2c-0.1-0.1-0.4-0.1-0.8-0.3c-0.4-0.1-0.6-0.2-0.7-0.3 c-0.9-0.3-1.7-0.7-2.5-1.2c-1.4-0.9-2.6-1.9-3.7-3.1C4.1,13.6,3,12.4,2.2,11C1.7,10.2,1.3,9.4,1,8.5c0-0.1-0.1-0.3-0.3-0.7 S0.5,7.2,0.4,7.1c0-0.1-0.1-0.4-0.2-0.7C0.1,6.1,0.1,5.8,0,5.6c0-0.2,0-0.5,0-0.8C0,3.9,0.3,3,0.7,2.2C1,1.5,1.6,0.9,2.2,0.4 c0.3-0.1,0.6-0.2,1-0.3C3.5,0.1,3.9,0,4.2,0c0.1,0,0.2,0,0.3,0c0.2,0.1,0.4,0.4,0.8,1.1c0.1,0.2,0.2,0.4,0.4,0.8 C5.9,2.2,6,2.5,6.2,2.8s0.3,0.5,0.4,0.8c0,0,0.1,0.2,0.2,0.4C7,4.1,7.1,4.2,7.2,4.4c0.1,0.1,0.1,0.3,0.1,0.4c0,0.3-0.2,0.5-0.4,0.7 C6.6,5.8,6.3,6.1,6,6.3C5.7,6.5,5.4,6.8,5.1,7.1C4.9,7.2,4.8,7.5,4.7,7.7c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.3 c0,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.2,0.3,0.2,0.3c0.7,1.2,1.5,2.3,2.5,3.3c1,1,2.1,1.8,3.3,2.5c0,0,0.1,0.1,0.3,0.2s0.3,0.2,0.3,0.2 c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.3,0.1c0.3-0.1,0.5-0.2,0.7-0.4c0.3-0.3,0.5-0.6,0.8-0.9c0.2-0.3,0.5-0.6,0.8-0.9 c0.2-0.2,0.4-0.4,0.7-0.4c0.1,0,0.3,0,0.4,0.1c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.2,0.4,0.2c0.2,0.1,0.5,0.3,0.8,0.4 c0.3,0.2,0.6,0.3,0.9,0.5c0.3,0.2,0.6,0.3,0.8,0.4c0.7,0.3,1,0.6,1.1,0.8C20,15.6,20,15.7,20,15.8L20,15.8z" />
);
export const IconArrowDown = () => (
    <path d="M0,6.4C0,5.6,0.6,5,1.4,5 c0.3,0,0.7,0.1,0.9,0.3l7.6,6.4l7.7-6.2c0.6-0.5,1.6-0.4,2,0.2s0.4,1.6-0.2,2l-8.6,6.9c-0.5,0.4-1.3,0.4-1.8,0L0.5,7.6 C0.2,7.3,0,6.8,0,6.4z" />
);
export const IconArrowLeft = () => (
    <path d="M13.6,0C14.4,0,15,0.6,15,1.4 c0,0.3-0.1,0.7-0.3,0.9L8.3,10l6.2,7.7c0.5,0.6,0.4,1.6-0.2,2s-1.6,0.4-2-0.2l-6.9-8.6c-0.4-0.5-0.4-1.3,0-1.8l7.1-8.6 C12.7,0.2,13.2,0,13.6,0z" />
);
export const IconArrowRight = () => (
    <path d="M6.4,20C5.6,20,5,19.4,5,18.6c0-0.3,0.1-0.7,0.3-0.9l6.4-7.6L5.5,2.4C5,1.7,5.1,0.8,5.8,0.3 s1.6-0.4,2,0.2l6.9,8.6c0.4,0.5,0.4,1.3,0,1.8l-7.1,8.6C7.3,19.8,6.8,20,6.4,20z" />
);
export const IconArrowUp = () => (
    <path d="M20,13.6c0,0.8-0.6,1.4-1.4,1.4 c-0.3,0-0.7-0.1-0.9-0.3L10,8.3l-7.7,6.2c-0.6,0.5-1.6,0.4-2-0.2s-0.4-1.6,0.2-2l8.6-6.9c0.5-0.4,1.3-0.4,1.8,0l8.6,7.1 C19.8,12.7,20,13.2,20,13.6z" />
);
export const IconDoubleArrow = () => (
    <path d="M0,10.2c0-0.8,0.6-1.4,1.4-1.4c0.3,0,0.7,0.1,0.9,0.3l7.6,6.4l7.7-6.2c0.6-0.5,1.6-0.4,2,0.2s0.4,1.6-0.2,2l-8.6,6.9 c-0.5,0.4-1.3,0.4-1.8,0l-8.6-7.1C0.2,11.1,0,10.6,0,10.2z M0.5,3.8l8.6,7.1c0.5,0.4,1.3,0.4,1.8,0L19.4,4c0.6-0.5,0.7-1.4,0.2-2 s-1.4-0.7-2-0.2L10,7.9L2.3,1.5C2.1,1.3,1.8,1.2,1.4,1.2C0.6,1.2,0,1.8,0,2.6C0,3.1,0.2,3.5,0.5,3.8z" />
);
export const IconSave = () => (
    <path d="M10.8,1.1h2.8v5.8h-2.8V1.1z M19.8,1.7L19.8,1.7l-1.4-1.4C18.3,0.1,18.1,0,17.9,0h-2.5v0c-0.1,0-0.2,0-0.2,0.1 c-0.1,0.1-0.1,0.1-0.1,0.2h0v6.8l0,0c0,0.3-0.3,0.6-0.6,0.6H5.2l0,0c-0.3,0-0.6-0.3-0.6-0.6l0,0V0.4h0c0-0.1-0.1-0.1-0.1-0.2 C4.4,0.1,4.3,0,4.1,0.1v0H0.6C0.3,0.1,0,0.3,0,0.7c0,0,0,0,0,0v18.6C0,19.7,0.3,20,0.6,20c0,0,0,0,0,0h2V11l0,0 c0-0.3,0.2-0.6,0.5-0.6l0,0h0c0,0,0.1,0,0.1,0h13.4l0,0c0.3,0,0.6,0.3,0.6,0.6l0,0V20h2c0.4,0,0.6-0.3,0.6-0.6V2.2c0,0,0,0,0,0 C20,2,19.9,1.8,19.8,1.7L19.8,1.7z" />
);
export const IconStar = () => (
    <path d="M19.46,9.11a1.34,1.34,0,0,0-.78-2.41l-5.43-.2a.14.14,0,0,1-.11-.1L11.26,1.34A1.35,1.35,0,0,0,9.54.54a1.37,1.37,0,0,0-.8.8L6.87,6.42a.12.12,0,0,1-.11.09l-5.44.21A1.34,1.34,0,0,0,0,8.08,1.37,1.37,0,0,0,.54,9.13L4.8,12.48a.15.15,0,0,1,0,.14l-1.47,5.2a1.34,1.34,0,0,0,2,1.48l4.5-3a.14.14,0,0,1,.15,0l4.5,3A1.34,1.34,0,0,0,16.42,19a1.32,1.32,0,0,0,.19-1.14l-1.48-5.22a.15.15,0,0,1,0-.14Z" />
);
export const IconGlobe = () => (
    <path d="M17.07,2.93a10,10,0,1,0,0,14.14,10,10,0,0,0,0-14.14ZM1.43,10a8.59,8.59,0,0,1,.3-2.25c.33.71.8,1.32,1.12,2,.42.93,1.55.67,2,1.49s0,1.64.3,2.4c.24.55.81.67,1.19,1.07a2.27,2.27,0,0,1,.45,1.49,12.94,12.94,0,0,0,.34,1.84h0A8.58,8.58,0,0,1,1.43,10ZM10,18.57a8.54,8.54,0,0,1-1.43-.12c0-.1,0-.21,0-.31a3.88,3.88,0,0,1,.94-1.94,13.29,13.29,0,0,0,1.53-1.47,2.66,2.66,0,0,0,.35-2c-.24-1-1.6-1.33-2.34-1.88-.42-.31-.8-.79-1.35-.83A4.34,4.34,0,0,1,7,10a1.52,1.52,0,0,0-.66-.15c-.47.06-.76.55-1.25.48A1.53,1.53,0,0,1,4,9.22c-.13-.57.32-.76.81-.81a1.34,1.34,0,0,1,.63,0c.26.09.38.34.61.47.44.24.52-.14.46-.53-.1-.57-.22-.81.3-1.21.35-.27.66-.47.6-1,0-.29-.19-.42,0-.71A1.83,1.83,0,0,1,8,5c.52-.33,2.21-.31,1.52-1.25-.2-.28-.58-.78-.94-.84s-.64.41-1,.63-.95.49-1.27.13.29-.62.44-1a.62.62,0,0,0-.12-.57l.49-.2A.67.67,0,0,0,7.54,2c.3,0,.58-.14.84.06s.5.51.88.58a.76.76,0,0,0,.86-.53,1.76,1.76,0,0,0-.06-.72,8.52,8.52,0,0,1,4.69,1.44.47.47,0,0,0-.33,0c-.27.13-.65.45-.68.77s.49.41.75.41.76-.17.64-.61A1.22,1.22,0,0,0,14.88,3c.29.2.55.41.81.63h0a4,4,0,0,0-.73.81.71.71,0,0,1-.52.4A1,1,0,0,0,14,5c-.32.25-.14.86.17,1a1.05,1.05,0,0,0,1.25-.21c.23-.26.36-.71.77-.71a.66.66,0,0,1,.48.2c.17.17.14.34.18.56.06.39.4.18.61,0a8.22,8.22,0,0,1,.42.84c-.23.33-.42.69-1,.31-.33-.24-.54-.57-.95-.68a2.72,2.72,0,0,0-1.1.07,2.09,2.09,0,0,0-1.2.41c-.3.3-.46.7-.78,1a1.6,1.6,0,0,0-.48,2A2.11,2.11,0,0,0,14.48,11c.85-.06,1.73-.55,1.71.68a3,3,0,0,0,.22,1.15,4.79,4.79,0,0,1,.14,1.13,7.62,7.62,0,0,0,.21,1.34A8.56,8.56,0,0,1,10,18.57Z" />
);
export const IconLink = () => (
    <path d="M13.43,10l1.72,1.72,3.43-3.44a4.85,4.85,0,0,0-6.86-6.86L8.28,4.85,10,6.57l3.43-3.43a2.43,2.43,0,0,1,3.43,3.43ZM10,13.43l1.72,1.72L8.28,18.58a4.85,4.85,0,0,1-6.86-6.86L4.85,8.28,6.57,10,3.14,13.43a2.43,2.43,0,0,0,3.43,3.43h0L10,13.43Zm3.43-5.15a1.21,1.21,0,0,0-1.71-1.71h0L6.57,11.72a1.21,1.21,0,1,0,1.68,1.74l0,0,5.15-5.15Z" />
);
export const IconBackspace = () => (
    <path d="M20,3.62a2.55,2.55,0,0,0-2.55-2.55h-9A2.59,2.59,0,0,0,6.48,2L.31,9.17a1.27,1.27,0,0,0,0,1.66L6.48,18a2.59,2.59,0,0,0,1.94.89h9A2.55,2.55,0,0,0,20,16.38V3.62ZM7.43,7.26a.64.64,0,0,1,.9-.9L11.07,9.1l2.74-2.74a.64.64,0,0,1,.9,0,.63.63,0,0,1,0,.9l0,0L12,10l2.74,2.74a.64.64,0,0,1-.9.9L11.07,10.9,8.33,13.64a.64.64,0,0,1-.9-.9L10.17,10Z" />
);
export const IconKey = () => (
    <path d="M10.62,8.17H20v3.66H18.21v3.63H14.54V11.83H10.62a5.56,5.56,0,0,1-2,2.6,5.19,5.19,0,0,1-3.15,1,5.29,5.29,0,0,1-3.86-1.6A5.29,5.29,0,0,1,0,10,5.29,5.29,0,0,1,1.6,6.14a5.29,5.29,0,0,1,3.86-1.6,5.19,5.19,0,0,1,3.15,1,5.63,5.63,0,0,1,2,2.6ZM5.46,11.83A1.82,1.82,0,0,0,7.29,10v0a1.81,1.81,0,0,0-1.8-1.83h0A1.81,1.81,0,0,0,3.63,10v0a1.81,1.81,0,0,0,1.8,1.83Z" />
);
export const IconWidget = () => (
    <path d="M0,0v6.7h20V0H0z M17.8,4.4H2.2V2.2h15.6V4.4z M0,20h8.9V8.9H0V20z M2.2,11.1h4.4v6.7H2.2V11.1z M11.1,8.9H20v2.2h-8.9V8.9z M11.1,13.3H20v2.2h-8.9V13.3z M11.1,17.8H20V20h-8.9V17.8z" />
);
export const IconTag = () => (
    <path d="M19.2,10.6l-8.7-9.6C9.8,0.4,8.8,0,7.8,0H1.9C1.1,0,0.4,0.7,0.4,1.5v5.8c0.1,1,0.5,1.9,1.1,2.6l8.7,9.6 c0.6,0.6,1.6,0.6,2.2,0l6.8-6.7C19.8,12.2,19.8,11.3,19.2,10.6z M4.4,5.5C3.6,5.5,2.9,4.8,2.9,4c0-0.8,0.7-1.5,1.5-1.5 c0.8,0,1.5,0.6,1.5,1.5C5.9,4.8,5.2,5.5,4.4,5.5z" />
);
export const IconStyle = () => (
    <path d="M15.5,5.6L15.5,5.6c-1.8,0-3.5,1-4.2,2.7c-0.2,0.5,0.1,1.1,0.6,1.2c0.5,0.2,1,0,1.2-0.5c0.4-0.9,1.3-1.5,2.3-1.5 c1.4,0,2.6,1.2,2.6,2.6c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c-1,0-2.1,0.1-3.1,0.2c-2,0.2-3.3,1.2-3.7,2.6l-4-9.8C6.9,2.7,6.3,2.4,5.8,2.7 C5.6,2.8,5.4,2.9,5.3,3.2l-5.3,13c-0.2,0.5,0.1,1,0.5,1.1c0,0,0,0,0.1,0c0.5,0.2,1,0,1.2-0.5l1.6-3.9c0,0,0-0.1,0.1-0.1H9 c0,0,0.1,0,0.1,0.1l1.6,3.9c0.2,0.5,0.8,0.7,1.2,0.5c0.3-0.1,0.5-0.3,0.6-0.6c0.6,0.4,1.4,0.7,2.2,0.7c1.2,0,2.4-0.3,3.4-1 c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1v-6.3C20,7.6,18,5.6,15.5,5.6z M8.2,11C8.2,11,8.2,11,8.2,11l-3.9,0c0,0-0.1,0-0.1,0c0,0,0,0,0-0.1 l0,0l1.9-4.7c0,0,0.1-0.1,0.1,0c0,0,0,0,0,0L8.2,11C8.3,10.9,8.3,11,8.2,11z M18.1,12.2c-0.1,2.2-1.2,3.3-3.4,3.3 c-0.8,0.1-1.6-0.5-1.7-1.4c0-0.1,0-0.1,0-0.2c0-0.4,0.2-0.8,0.5-1.1c0.5-0.3,1-0.5,1.6-0.5C16,12.2,17.1,12.1,18.1,12.2 C18,12.1,18.1,12.1,18.1,12.2C18.1,12.2,18.1,12.2,18.1,12.2z" />
);
export const IconDesktop = () => (
    <path d="M18.7,15V4.4c0-1-0.8-1.9-1.9-1.9c0,0,0,0,0,0H3.1c-1,0-1.9,0.9-1.9,1.9c0,0,0,0,0,0V15H0v0.6c0,0,0,0,0,0 c0,1,0.8,1.9,1.9,1.9h16.2c0,0,0,0,0,0c1,0,1.9-0.9,1.9-1.9V15H18.7z M2.5,4.4L2.5,4.4c0-0.3,0.3-0.6,0.6-0.6h13.7c0,0,0,0,0,0 c0.3,0,0.6,0.3,0.6,0.6v9.5h-15V4.4z" />
);
export const IconTablet = () => (
    <path d="M18.2,2.3c0-0.6-0.2-1.2-0.7-1.6c-0.4-0.4-1-0.7-1.6-0.7H4.1C3.5,0,2.9,0.2,2.5,0.7c-0.4,0.4-0.7,1-0.7,1.6v15.5 c0,0.6,0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7h11.8c1.3,0,2.3-1,2.3-2.3L18.2,2.3L18.2,2.3z M10.6,18.8c-0.2,0.2-0.4,0.3-0.7,0.3 c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9C10.9,18.4,10.8,18.7,10.6,18.8z M16.4,15.9L16.4,15.9 c0,0.2-0.2,0.5-0.5,0.5H4.1c-0.2,0-0.5-0.2-0.5-0.5V2.3c0-0.2,0.2-0.5,0.5-0.5h11.8c0.2,0,0.5,0.2,0.5,0.5V15.9z" />
);
export const IconMobile = () => (
    <path d="M15.4,0.6C15,0.2,14.5,0,14,0H6C5.5,0,5,0.2,4.6,0.6C4.2,1,4,1.5,4,2v16c0,0.5,0.2,1,0.6,1.4C5,19.8,5.5,20,6,20h8 c1.1,0,2-0.9,2-2V2C16,1.5,15.8,1,15.4,0.6z M8.5,2.2C8.5,2.1,8.6,2,8.8,2h2.5c0,0,0,0,0.1,0c0.1,0,0.2,0.1,0.2,0.2c0,0,0,0,0,0.1 c0,0.1-0.1,0.2-0.2,0.2H8.8c0,0,0,0-0.1,0C8.6,2.5,8.5,2.4,8.5,2.2C8.5,2.2,8.5,2.2,8.5,2.2z M10.9,18.9c-0.2,0.2-0.6,0.4-0.9,0.4 c-0.7,0-1.2-0.6-1.2-1.3c0-0.7,0.6-1.2,1.3-1.2c0.7,0,1.2,0.6,1.2,1.2C11.3,18.3,11.1,18.7,10.9,18.9z M14.5,15.5 c0,0.3-0.2,0.5-0.5,0.5H6c-0.3,0-0.5-0.2-0.5-0.5v-11C5.5,4.2,5.7,4,6,4h8c0.3,0,0.5,0.2,0.5,0.5V15.5z" />
);
export const IconPasswordEyeOpen = () => (
    <path d="M0,10c1-4.7,5.2-8,10-8c4.8,0,9,3.3,10,8c-1,4.7-5.2,8-10,8C5.2,18,1,14.7,0,10z M10,15 c2.8,0,5-2.2,5-5s-2.2-5-5-5s-5,2.2-5,5S7.2,15,10,15z M10,13c1.7,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3S8.3,13,10,13z" />
);
export const IconPasswordEyeClose = () => (
    <path d="M16.8,4.6l2.4-2.4c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-2.6,2.6C13.7,2.5,11.9,2,10,2C5.2,2,1,5.3,0,10c0.5,2.2,1.6,4,3.2,5.4 l-2.4,2.4c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l2.6-2.6C6.3,17.5,8.1,18,10,18c4.8,0,9-3.3,10-8 C19.5,7.8,18.4,6,16.8,4.6z M5,10c0-2.8,2.2-5,5-5c1,0,2,0.3,2.8,0.8l-1.5,1.5C10.9,7.1,10.5,7,10,7c-1.7,0-3,1.3-3,3 c0,0.5,0.1,0.9,0.3,1.3l-1.5,1.5C5.3,12,5,11,5,10z M10,15c-1,0-2-0.3-2.8-0.8l1.5-1.5C9.1,12.9,9.5,13,10,13c1.7,0,3-1.3,3-3 c0-0.5-0.1-0.9-0.3-1.3l1.5-1.5C14.7,8,15,9,15,10C15,12.8,12.8,15,10,15z" />
);
export const IconHome = () => (
    <path d="M18.9,6.8l-8.6-6.7C10,0,9.7,0,9.4,0.1L1.1,6.8C1,6.9,0.9,7.1,0.9,7.3v12c0,0.4,0.3,0.7,0.7,0.7h4.9 c0.4,0,0.7-0.3,0.7-0.7V12h5.7v7.4c0,0.4,0.3,0.7,0.7,0.7h4.9c0.4,0,0.7-0.3,0.7-0.7v-12C19.1,7.1,19,6.9,18.9,6.8L18.9,6.8z" />
);
export const IconExpand = () => (
    <path d="M12.5,0h7.5V7.5l-2.5-2.5-5,5-2.5-2.5L15,2.5,12.5,0ZM7.5,20H0v-7.5l2.5,2.5,5-5,2.5,2.5-5,5,2.5,2.5Z" />
);
export const IconCollapse = () => (
    <path d="M17.5,10h-7.5V2.5l2.5,2.5L17.5,0l2.5,2.5-5,5,2.5,2.5Zm-15,0h7.5v7.5l-2.5-2.5-5,5L0,17.5l5-5-2.5-2.5Z" />
);
export const IconAdd = () => (
    <path d="M17.07,2.93A10,10,0,0,0,3,2.88l0,.05a10,10,0,1,0,14.14,0Zm-1,8.06H11v5.09H9V11H3.92V9H9V3.92h2V9h5.09Z" />
);
export const IconWarning = () => (
    <path d="M17.07,2.93c-3.88-3.91-10.19-3.93-14.09-.05l-.05,.05C-.98,6.83-.98,13.17,2.93,17.07c3.91,3.91,10.24,3.91,14.14,0,3.91-3.91,3.91-10.24,0-14.14Zm-7.07,15c-1.22,0-2.17-.94-2.17-2.14s.95-2.16,2.17-2.16,2.17,.97,2.17,2.16-.95,2.14-2.17,2.14Zm1.19-8.37l-.57,2.22h-1.24l-.57-2.22c-.82-2.9-1.03-4.48-1.03-5.32,0-1.44,.86-2.19,2.22-2.19s2.22,.75,2.22,2.19c0,.84-.22,2.43-1.03,5.32Z" />
);
export const IconDesctiption = () => (
    <path d="M13.11,0H1.17V20H18.83V5.72L13.11,0Zm1.06,17.55H4.83v-1.33H14.17v1.33Zm0-3.32H4.83v-1.33H14.17v1.33Zm0-3.32H4.83v-1.33H14.17v1.33Zm3.33-4.58h-5V1.33h.06l4.94,4.94v.06Z" />
);
export const IconQuestion = () => (
    <path d="M12.3,15.6v3.7c0,0.3-0.3,0.6-0.6,0.6H7.9c-0.3,0-0.6-0.3-0.6-0.6v-3.7c0-0.3,0.3-0.6,0.6-0.6h3.7 C12,15,12.3,15.3,12.3,15.6z M17.2,6.3c0,0.5-0.1,1.1-0.2,1.6c-0.1,0.4-0.3,0.8-0.5,1.2c-0.2,0.3-0.5,0.7-0.9,0.9 c-0.3,0.2-0.6,0.5-0.9,0.7c-0.2,0.1-0.5,0.3-1,0.6c-0.4,0.2-0.8,0.6-1.1,1c-0.2,0.3-0.4,0.7-0.4,1c0,0.2-0.1,0.4-0.2,0.5 c-0.1,0.1-0.3,0.2-0.4,0.2H7.8c-0.2,0-0.3-0.1-0.4-0.3c-0.1-0.2-0.2-0.4-0.2-0.6v-0.7c0-0.9,0.4-1.8,1-2.4c0.6-0.7,1.4-1.3,2.2-1.7 c0.5-0.2,0.9-0.5,1.3-0.9c0.3-0.3,0.4-0.8,0.4-1.2c0-0.5-0.3-0.9-0.7-1.2C11,4.7,10.4,4.6,9.8,4.6C9.2,4.5,8.6,4.7,8.1,5 C7.5,5.6,7,6.2,6.5,6.8C6.4,7,6.2,7.1,6,7.1c-0.1,0-0.3,0-0.4-0.1L3,5C2.9,4.9,2.8,4.8,2.8,4.6c0-0.2,0-0.3,0.1-0.4 C4.3,1.5,7.1-0.1,10.1,0c0.9,0,1.7,0.2,2.5,0.5c0.8,0.3,1.6,0.7,2.3,1.3c0.7,0.5,1.2,1.2,1.7,2C17,4.5,17.2,5.4,17.2,6.3z" />
);
export const IconEdit = () => (
    <path d="M3,18h14c0.6,0,1,0.4,1,1s-0.4,1-1,1H3c-0.6,0-1-0.4-1-1S2.4,18,3,18z M2,13L12,3l3,3L5,16H2V13z M13,2l2-2l3,3 l-2,2L13,2z" />
);
export const IconShare = () => (
    <path
        d="M16.7,6.7c1.8,0,3.3-1.5,3.3-3.3S18.5,0,16.7,0c-1.8,0-3.3,1.5-3.3,3.3c0,0.1,0,0.3,0,0.4L5.6,7.6C4.3,6.3,2.2,6.4,0.9,7.7
	s-1.2,3.4,0.1,4.7c1.3,1.2,3.3,1.2,4.6,0l7.7,3.9c-0.2,1.8,1.1,3.5,2.9,3.7c1.8,0.2,3.5-1.1,3.7-2.9c0.2-1.8-1.1-3.5-2.9-3.7
	c-1-0.1-2,0.2-2.7,0.9l-7.7-3.9c0-0.3,0-0.5,0-0.8l7.7-3.9C15,6.3,15.8,6.7,16.7,6.7z"
    />
);
export const IconFilterClear = () => (
    <path d="M13.64,10.5L5.25,2.16h13.71c.44-.06.86.25.92.69,0,.04,0,.07,0,.11v.74c0,.33-.14.64-.37.86l-5.86,5.93h0ZM19.57,18.15L1.42,0,.49.86l1.3,1.3h-.74c-.44-.06-.86.25-.92.69,0,.04,0,.07,0,.11v.74c0,.33.14.64.37.86l7.04,7.1v6.23l4.94,2.1v-7.16l6.23,6.23.87-.93Z" />
);
export const IconRefresh = () => (
    <path d="M17.22,10.65c-.59,0-1.06.48-1.06,1.06,0,3.4-2.76,6.16-6.16,6.16s-6.16-2.76-6.16-6.16,2.76-6.16,6.16-6.16c.49,0,.88,0,1.2.02l-1.95,1.95c-.42.42-.42,1.09,0,1.5.21.21.48.31.75.31s.54-.1.75-.31l3.61-3.61c.2-.2.31-.47.31-.75s-.11-.55-.31-.75L10.75.31c-.42-.42-1.09-.42-1.5,0s-.42,1.09,0,1.5l1.62,1.62c-.27,0-.56,0-.87,0C5.43,3.43,1.71,7.15,1.71,11.71s3.72,8.29,8.28,8.29,8.29-3.72,8.29-8.29c0-.59-.48-1.06-1.06-1.06Z" />
);
export const IconCopy = () => (
    <path
        d="M18,0H8C6.9,0,6,0.9,6,2v2h8c1.1,0,2,0.9,2,2v8h2c1.1,0,2-0.9,2-2V2C20,0.9,19.1,0,18,0z M2,20h10c1.1,0,2-0.9,2-2V8
	c0-1.1-0.9-2-2-2H2C0.9,6,0,6.9,0,8v10C0,19.1,0.9,20,2,20z M4,10h6v2H4V10z M4,14h6v2H4V14z"
    />
);
export const IconStat = () => (
    <path d="M19.4,15H2.5V3.1c0-.3-.3-.6-.6-.6H.6c-.3,0-.6.3-.6.6v13.1c0,.7.6,1.2,1.2,1.2h18.1c.3,0,.6-.3.6-.6v-1.2c0-.3-.3-.6-.6-.6ZM18.1,3.8h-4.6c-.8,0-1.3,1-.7,1.6l1.3,1.3-2.9,2.9-2.9-2.9c-.5-.5-1.3-.5-1.8,0l-2.7,2.7c-.2.2-.2.6,0,.9l.9.9c.2.2.6.2.9,0l1.8-1.8,2.9,2.9c.5.5,1.3.5,1.8,0l3.8-3.7,1.3,1.3c.6.6,1.6.2,1.6-.7v-4.6c0-.3-.3-.6-.6-.6Z" />
);
export const IconMoney = () => (
    <path d="M19.4,3.7c-1.2-.5-2.4-.7-3.6-.7-3.8,0-7.7,1.9-11.5,1.9s-1.9-.1-2.9-.4c-.1,0-.2,0-.3,0-.5,0-1,.4-1,1v9.9c0,.4.2.8.6.9,1.2.5,2.4.7,3.6.7,3.8,0,7.7-1.9,11.5-1.9s1.9.1,2.9.4c.1,0,.2,0,.3,0,.5,0,1-.4,1-1V4.6c0-.4-.2-.8-.6-.9ZM1.5,6.1c.6.2,1.3.2,2,.3-.2.9-1,1.6-2,1.6v-1.9ZM1.5,15v-1.5c1.1,0,1.9.9,2,1.9-.7,0-1.4-.2-2-.4ZM10,13c-1.4,0-2.5-1.3-2.5-3s1.1-3,2.5-3,2.5,1.3,2.5,3-1.1,3-2.5,3ZM18.5,13.9c-.5-.1-1.1-.2-1.7-.3.2-.8.9-1.4,1.7-1.5v1.8ZM18.5,6.5c-1-.1-1.7-.9-1.7-1.9.6,0,1.2.2,1.7.4v1.5Z" />
);
export const IconColumns = () => (
    <path d="M2.6,4.2h5.7c.5,0,.8.4.8.8v14.2c0,.5-.4.8-.8.8H2.6c-.5,0-.8-.4-.8-.8V5c0-.5.4-.8.8-.8ZM11.8,0h5.7c.5,0,.8.4.8.8v14.2c0,.5-.4.8-.8.8h-5.7c-.5,0-.8-.4-.8-.8V.8c0-.5.4-.8.8-.8Z" />
);
export const IconThreeUser = () => (
    <path d="M9.5,1.6c-1.1.1-2.1.9-2.6,2-.2.5-.3.6-.2,1.4,0,.8,0,.9.2,1.4.6,1.3,1.8,2,3.2,2s2.6-.8,3.2-2c.2-.5.2-.6.2-1.4,0-.9,0-.9-.2-1.4-.7-1.4-2.1-2.2-3.7-2ZM3.1,4.9c-1.9.6-2.4,2.9-1.1,4.3.2.2.5.4.7.5.3.2.5.2,1.1.2s.8,0,1.1-.2c.5-.3,1-.8,1.2-1.3.3-.6.2-1.5,0-2.1-.3-.5-.8-1-1.3-1.3-.5-.2-1.3-.3-1.8-.1ZM15.3,4.9c-1.4.5-2.1,2.1-1.5,3.5.2.5.7,1,1.2,1.3.3.2.5.2,1.1.2s.8,0,1.1-.2c.9-.5,1.4-1.2,1.4-2.2,0-.5,0-.7-.1-1.1-.5-1.3-2-2-3.3-1.5ZM7.7,9.2c-.9.1-1.7.7-2.1,1.5-.3.6-.4,1.2-.3,4.4v2.9s.2.2.2.2l.2.2h8.6l.2-.2.2-.2v-2.9c0-1.8,0-3.2,0-3.5-.2-1.3-1.1-2.3-2.4-2.5-.6,0-3.8,0-4.4,0ZM1.9,10.9c-.8.3-1.3.8-1.6,1.5-.2.4-.2.5-.2,2.5,0,2.3,0,2.5.5,3,.4.5.8.5,2.4.5h1.4v-3.7c0-2,0-3.8.1-3.9v-.2c0,0-1,0-1,0-.9,0-1.1,0-1.5.2ZM15.7,10.9c0,.1,0,1.9,0,3.9v3.7h1.4c1.6,0,1.9,0,2.4-.5.5-.5.5-.7.5-3v-2.1s-.3-.5-.3-.5c-.3-.6-.8-1.1-1.4-1.4-.4-.2-.5-.2-1.6-.2h-1.1s0,.2,0,.2Z" />
);
export const IconBrush = () => (
    <path d="M6.5,12.1c-1.6.1-3,.7-3.8,2.8,0,.2-.3.4-.6.4-.4,0-1.8-1.1-2.2-1.3,0,3.2,1.5,6,5,6s5-1.7,5-4.7,0-.2,0-.4l-3.4-2.9ZM17.9,0c-.6,0-1.1.3-1.6.6C8.3,7.8,7.5,7.9,7.5,10s.1,1,.3,1.5l2.5,2.1c.3,0,.6.1.9.1,2.4,0,3.8-1.8,8.2-10,.3-.6.5-1.2.5-1.8C20,.8,19,0,17.9,0Z" />
);
