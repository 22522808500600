import { PublisherContext, MainContext, useContext, useMemo, Icon, IconExpand } from "UIV2";
import { ActionButton } from "./ActionButton/ActionButton";
import { LogoForMobile } from "./LogoForMobile/LogoForMobile";
import { HamburgerAndHomeIconForMobile } from "./HamburgerAndHomeIconForMobile/HamburgerAndHomeIconForMobile.js";
import { SlidableActionsArea } from "./SlidableActionsArea/SlidableActionsArea";
import { Container, HeaderTitleArea, TitleText, SwitchToFullScreenContainer } from "./Header.styled.js";

export const Header = () => {
    const { isFullScreen, setIsFullScreen } = useContext(MainContext);
    const { pageName = "Missing pageName", headerActions, hideHeader } = useContext(PublisherContext);
    const { isLoadingAfterDelayX2 } = useContext(MainContext);

    /**
     * Generate Title
     */
    const title = useMemo(() => {
        const split = pageName.split(" ");
        const largeText = split[0];
        split.shift();
        const smallText = split.join(" ");
        return [largeText, smallText];
    }, [pageName]);

    /**
     * Generate Actions
     */
    const generatedActions = useMemo(() => {
        if (!headerActions || headerActions.length < 1) return null;
        return (
            <>
                {headerActions.map((action, key) => (
                    <ActionButton {...{ key, action }} />
                ))}
            </>
        );
    }, [headerActions]);

    /**
     * Return
     */
    return (
        <Container aria-label="Header" {...{ isLoadingAfterDelayX2, hideHeader, headerActions, isFullScreen }}>
            <HeaderTitleArea isThereAnyActions={headerActions && headerActions.length > 0}>
                <LogoForMobile />
                <TitleText>
                    {title[0]}
                    <span>{title[1]}</span>
                </TitleText>
                <HamburgerAndHomeIconForMobile />
            </HeaderTitleArea>
            <SlidableActionsArea>{generatedActions}</SlidableActionsArea>
            <SwitchToFullScreenContainer onClick={() => setIsFullScreen(true)}>
                <Icon color="grey50" icon={IconExpand} width={2} />
            </SwitchToFullScreenContainer>
        </Container>
    );
};
