import { useContext, MainContext, Icon, IconCollapse, useEventListener, useEffect } from "UIV2";
import { styled, g } from "UIV2/base/styledMixins/styledMixins.js";

export const Container = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 2.5rem;
    height: 2.5rem;
    opacity: ${(props) => (props.hide ? 0 : 1)};
    pointer-events: ${(props) => (props.hide ? "none" : "auto")};
    transition: ${(props) => (props.hide ? "all 250ms ease-in-out 0ms" : "all 250ms ease-in-out 1000ms")};
    background: ${g.getColor("error")};
    border: 1px solid ${g.getColor("grey20")};
    border-radius: 0;
    border-bottom-left-radius: 1.2rem;
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const FullScreenController = () => {
    const { isFullScreen, setIsFullScreen, addToSnackbar, clientEvents } = useContext(MainContext);

    /**
     * Liste isFullScreen true for snackBar
     */
    useEffect(() => {
        if (isFullScreen)
            addToSnackbar({
                status: "default",
                msg: "You can enable/disable 'Full Screen' mode via 'F4' key on your keyboard.",
            });
    }, [isFullScreen]);

    /**
     * Listen keyboard
     */
    useEventListener("keydown", (e) => {
        // 115 is F4 - 27 is esc|| e.keyCode === 27
        if (e.keyCode === 115) {
            e.preventDefault();
            setIsFullScreen((p) => !p);
        }
        return;
    });

    /**
     * Return
     */
    return (
        <Container
            aria-label="FullScreenController"
            onClick={() => setIsFullScreen()}
            hide={clientEvents?.isMobile || !isFullScreen}
        >
            <Icon color="grey10" icon={IconCollapse} width={1} />
        </Container>
    );
};
