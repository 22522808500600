import { Modal } from "antd";
import { styled } from "UIV2/base/styledMixins/styledMixins.js";

export const AntModal = styled(Modal)`
    padding-bottom: 0;
    min-width: 80rem;

    & > .ant-modal-content > .ant-modal-close {
        display: flex;
        justify-content: center;
        align-items: center;

        & > .ant-modal-close-x > .ant-modal-close-icon {
            display: flex;
        }
    }
`;
