import { _ } from "UIV2";
import { Select } from "antd";
const { Option } = Select;

/**
 * Option Creator
 */
export const optionCreator = ({
    arr,
    getKey = (i) => i,
    getLabel = (i) => i,
    sortByKey,
    CustomOptionComponent,
    optionButtons,
    value,
}) => {
    let selectedOptions = [];
    let unselectedOptions = [];
    let generatedOptions = [];

    if (!value || value.length < 1) unselectedOptions = arr;
    else {
        _.forEach(arr, (item) => {
            // console.log(item);
            const itemsKey = getKey(item) + "";
            if ((value + "").includes(itemsKey)) selectedOptions.push(item);
            else unselectedOptions.push(item);
        });
    }

    const sortFn = (a, b) => {
        if (a[sortByKey] < b[sortByKey]) return -1;
        else if (a[sortByKey] > b[sortByKey]) return 1;
        else return 0;
    };

    if (sortByKey) selectedOptions = selectedOptions.sort(sortFn);
    if (sortByKey) unselectedOptions = unselectedOptions.sort(sortFn);

    if (optionButtons) generatedOptions = [...optionButtons, ...selectedOptions, ...unselectedOptions];
    else generatedOptions = [...selectedOptions, ...unselectedOptions];

    return generatedOptions.map((item, index) => {
        const key = getKey(item); // +""
        const label = getLabel(item);
        if (CustomOptionComponent) {
            return (
                <Option key={index} value={key} label={label}>
                    <CustomOptionComponent label={label} />
                </Option>
            );
        } else {
            return (
                <Option key={index} value={key} label={label}>
                    {label}
                </Option>
            );
        }
    });
};

/**
 * Blur onSelect if it is a Single Select Box
 */
export const blurOnSelectForSingleSelect = (mode, selectRef) => {
    if (mode !== "multiple" && selectRef) {
        selectRef.current?.blur();
    }
};

/**
 * Clear Titles of selections - this is a hack for unwanted titles
 */

export const clearTitlesOfSelectedItem = () => {
    setTimeout(() => {
        const els = document.getElementsByClassName("ant-select-selection-item");
        _.forEach(els, (el) => (el.title = ""));
    }, 1000);
};
