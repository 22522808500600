// import { CircleTopLeftContainer } from "./Layout.styled.js";
import { styled } from "UIV2/base/styledMixins/styledMixins.js";

const Container = styled.div`
    position: absolute;
    top: 0;
    left: calc((100% - 243px) / 2);
    z-index: 0;
    pointer-events: none;
`;

export const CircleTopCenter = () => (
    <Container>
        <svg width="243" height="130" viewBox="0 0 243 130" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="121.491"
                cy="8.50844"
                r="120.04"
                transform="rotate(-90 121.491 8.50844)"
                stroke="#FEDC5A"
                strokeWidth="1.2"
            />
        </svg>
    </Container>
);
