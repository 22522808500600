import { DateTime } from "UIV2";

export const generateTimesISO = () => {
    const dateTime = DateTime.now();
    const formatter = "yyyy-LL-dd";
    const TODAY = dateTime.toFormat(formatter);
    const YESTERDAY = dateTime.minus({ days: 1 }).toFormat(formatter);
    const minusDay = (d) => dateTime.minus({ days: d }).toFormat(formatter);
    const FIRSTDAYOFTHEWEEK = dateTime.startOf("week").toFormat(formatter);
    const FIRSTDAYOFTHEMONTH = dateTime.startOf("month").toFormat(formatter);
    // const FIRSTDAYOFTHEYEAR = dateTime.startOf("year").toFormat(formatter);
    const FIRSTDAYOFTHELASTWEEK = dateTime.minus({ days: 7 }).startOf("week").toFormat(formatter);
    const LASTDAYOFTHELASTWEEK = dateTime.minus({ days: 7 }).endOf("week").toFormat(formatter);
    const FIRSTDAYOFTHELASTMONTH = dateTime.minus({ month: 1 }).startOf("month").toFormat(formatter);
    const LASTDAYOFTHELASTMONTH = dateTime.minus({ month: 1 }).endOf("month").toFormat(formatter);
    // const FIRSTDAYOFTHELASTYEAR = dateTime.minus({ year: 1 }).startOf("year").toFormat(formatter);
    // const LASTDAYOFTHELASTYEAR = dateTime.minus({ year: 1 }).endOf("year").toFormat(formatter);

    return {
        formatter,
        dateTime,
        TODAY,
        YESTERDAY,
        FIRSTDAYOFTHEWEEK,
        FIRSTDAYOFTHEMONTH,
        dateFilters: {
            today: { key: "today", label: "Today", set: [TODAY, TODAY] },
            yesterday: { key: "yesterday", label: "Yesterday", set: [YESTERDAY, YESTERDAY] },
            this_week: { key: "this_week", label: "This Week", set: [FIRSTDAYOFTHEWEEK, TODAY] },
            this_month: { key: "this_month", label: "This Month", set: [FIRSTDAYOFTHEMONTH, TODAY] },
            // this_year: { key: "this_year", label: "This Year", set: [FIRSTDAYOFTHEYEAR, TODAY] },
            last_week: { key: "last_week", label: "Last Week", set: [FIRSTDAYOFTHELASTWEEK, LASTDAYOFTHELASTWEEK] },
            last_month: {
                key: "last_month",
                label: "Last Month",
                set: [FIRSTDAYOFTHELASTMONTH, LASTDAYOFTHELASTMONTH],
            },
            // last_year: { key: "last_year", label: "Last Year", set: [FIRSTDAYOFTHELASTYEAR, LASTDAYOFTHELASTYEAR] },
            last_7_days: { key: "last_7_days", label: "Last 7 Days", set: [minusDay(7), TODAY] },
            last_30_days: { key: "last_30_days", label: "Last 30 Days", set: [minusDay(30), TODAY] },
            last_60_days: { key: "last_60_days", label: "Last 60 Days", set: [minusDay(60), TODAY] },
            last_90_days: { key: "last_90_days", label: "Last 90 Days", set: [minusDay(90), TODAY] },
        },
    };
};

export const getTimesISO = (states) => {
    const { setCurrentTimes } = states || {};
    setCurrentTimes(generateTimesISO());
};
