import { useContext, useRef, useEffect, useState } from "react";
import { PublisherContext, IconArrowLeft, IconArrowRight, Icon, useEventListener, _ } from "UIV2";
import { ActionsCoverElem, SliderButton, ActionsInnerElem, SlidableActionsAreaContainer } from "../Header.styled.js";

export const SlidableActionsArea = ({ children }) => {
    const { headerActions, hideHeader } = useContext(PublisherContext);
    const [isOverflown, setIsOverflown] = useState();
    const [marginLeftForSlider, setMarginLeftForSlider] = useState(0);
    const headerActionArea = useRef();
    const sliderArea = useRef();

    /**
     * Slider for Actions
     */
    const calculateOverflown = () => {
        if (!headerActions) return;
        const coverElem = headerActionArea?.current;
        const innerElem = sliderArea?.current;
        if (!coverElem || !innerElem) {
            if (isOverflown) setIsOverflown();
            setTimeout(calculateOverflown, 1000);
            return;
        }
        const coverElemWidth = coverElem.getBoundingClientRect().width;
        const innerElemWidth = innerElem.getBoundingClientRect().width;

        if (innerElemWidth > coverElemWidth) setIsOverflown(innerElemWidth - coverElemWidth);
        else setIsOverflown();
    };
    useEffect(() => calculateOverflown(), [children]);
    useEventListener("resize", _.debounce(calculateOverflown, 500), window, { passive: true });
    const moveSlider = (direction) => {
        const unit = 400;
        if (direction < 0) {
            // move sliderArea Left
            setMarginLeftForSlider((p) => {
                if (p + unit > 0) return 0;
                else return p + unit;
            });
        } else {
            setMarginLeftForSlider((p) => {
                if (p - unit < isOverflown * -1) return isOverflown * -1;
                else return p - unit;
            });
        }
    };

    /**
     * Return
     */
    return (
        <SlidableActionsAreaContainer aria-label="SlidableActionsArea" {...{ hideHeader }}>
            <SliderButtonComp {...{ toRight: false, isOverflown, marginLeftForSlider, moveSlider }} />
            <ActionsCoverElem ref={headerActionArea} aria-label="HeaderActionsArea">
                <ActionsInnerElem ref={sliderArea} aria-label="SliderArea" style={{ marginLeft: marginLeftForSlider }}>
                    {children}
                </ActionsInnerElem>
            </ActionsCoverElem>
            <SliderButtonComp {...{ toRight: true, isOverflown, marginLeftForSlider, moveSlider }} />
        </SlidableActionsAreaContainer>
    );
};

const SliderButtonComp = ({ toRight, isOverflown, marginLeftForSlider, moveSlider }) => (
    <SliderButton
        onClick={() => moveSlider(toRight ? 1 : -1)}
        hide={
            toRight
                ? !isOverflown || marginLeftForSlider < isOverflown * -1 + 2
                : !isOverflown || marginLeftForSlider > -1
        }
    >
        <Icon color="grey40" icon={toRight ? IconArrowRight : IconArrowLeft} width={2} />
    </SliderButton>
);
