import { useContext, useState, useEffect, useRef } from "react";
import { MainContext, Icon, IconClose, IconCheck, IconCancel, IconNotification } from "UIV2";
import { SnackbarContainer, SingleSnackbarContainer, IconArea, TextArea, ControlArea } from "./Snackbar.styled";

export const Snackbar = () => {
    const { snackState } = useContext(MainContext);

    return (
        <SnackbarContainer {...{ snackState }}>
            {!snackState
                ? null
                : Object.entries(snackState).map(([key, item]) => (
                      <SingleSnackbar key={key} snackKey={key} {...item} />
                  ))}
        </SnackbarContainer>
    );
};

const SingleSnackbar = (props) => {
    const { msg, snackKey } = props || {};
    const { removeFromSnackbar } = useContext(MainContext);
    let { status } = props || {};
    const [remainingTime, setRemainingTime] = useState(3);
    const [manualControl, setManualControl] = useState(false);
    const manualControlRef = useRef(false);
    const timeoutRef = useRef();

    status = status === "success" || status === "error" || status === "default" ? status : "default";

    const remove = () => removeFromSnackbar(snackKey);
    const findIcon = status === "success" ? IconCheck : status === "error" ? IconCancel : IconNotification;
    const setTrigger = () => {
        timeoutRef.current = setTimeout(() => reTriggerTimer(), 1000);
    };
    const reTriggerTimer = () => {
        if (manualControlRef.current) {
            clearTimeout(timeoutRef.current);
        } else if (remainingTime === 1) {
            remove();
        } else {
            setRemainingTime(remainingTime - 1);
            setTrigger();
        }
    };
    useEffect(() => {
        clearTimeout(timeoutRef.current);
        if (!manualControlRef.current) {
            setTrigger();
        }
    }, [remainingTime]);
    useEffect(() => () => clearTimeout(timeoutRef.current), []);

    return (
        <SingleSnackbarContainer
            {...{ status }}
            onMouseOver={() => {
                clearTimeout(timeoutRef.current);
                setManualControl(true);
                manualControlRef.current = true;
            }}
            onMouseOut={() => {
                clearTimeout(timeoutRef.current);
                setRemainingTime(10);
                setManualControl(false);
                manualControlRef.current = false;
                setTrigger();
            }}
        >
            <IconArea>
                <Icon color="background" icon={findIcon} width={2} />
            </IconArea>
            <TextArea>{msg}</TextArea>
            <ControlArea {...{ manualControl }}>
                {manualControl ? (
                    <Icon
                        color="background"
                        icon={IconClose}
                        width={2}
                        hoverColor="background"
                        onClick={() => remove()}
                    />
                ) : (
                    <span>{remainingTime}</span>
                )}
            </ControlArea>
        </SingleSnackbarContainer>
    );
};
