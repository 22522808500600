/*
USAGE WITHOUT HEADER 
<PublisherLayout {...{ pageName, ready4Screen }} hideHeader>

USAGE WITH ONLY HEADER TITLE
• Header title is page name.
<PublisherLayout {...{ pageName, ready4Screen }}>

USAGE WITH HEADER TITLE + ACTIONS
• 
    const action1fn = () => console.log("action1");
    const headerActions = useMemo(
        () => [
            [IconKey, 1, "Action 1", action1fn],
        ],[], // if there is a dynamic var in the actions, add them in to dependencies
    );
<PublisherLayout {...{ pageName, ready4Screen, headerActions }}>
*/

import { useEffect, useLayoutEffect, MainContext, useContext, useState, createContext } from "UIV2";
import { Sidebar } from "./Sidebar/Sidebar.js";
import { Header } from "./Header/Header.js";
import { MobileMenu } from "./MobileMenu/MobileMenu.js";
import { ObserverBar } from "./ObserverBar/ObserverBar.js";
import { Container, ContentArea } from "./PublisherLayout.styled.js";

export const PublisherContext = createContext();
export const PublisherLayout = ({ headerActions, hideHeader, ...props }) => {
    const { ready4Screen, pageName, children } = props;
    const [isMouseOver, setIsMouseOver] = useState();

    /**
     * Create a new page via contexts
     */
    const { isLoadingAfterDelay, newPage, disableIsLoadingWithDelay, isLoadingAfterDelayX2, isFullScreen } =
        useContext(MainContext);
    useLayoutEffect(() => newPage(pageName), [pageName]);
    useEffect(() => ready4Screen && disableIsLoadingWithDelay(), [ready4Screen]);

    /**
     * Return
     */
    const plAssets = {
        pageName,
        isMouseOver,
        setIsMouseOver,
        headerActions,
        hideHeader,
    };

    return (
        <PublisherContext.Provider value={{ plAssets, ...plAssets }}>
            <MobileMenu />
            <Container aria-label="PublisherLayout Container" {...{ isLoadingAfterDelay, isMouseOver }}>
                <Sidebar />
                <ContentArea {...{ isLoadingAfterDelayX2, isMouseOver, isFullScreen }}>
                    <ObserverBar />
                    <Header />
                    {children}
                </ContentArea>
            </Container>
        </PublisherContext.Provider>
    );
};
