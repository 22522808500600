import { _, useEffect, useContext, MainContext, useMemo, typeOfAdvanced } from "UIV2";
import dayjs from "dayjs"; // https://day.js.org/docs/en/installation/installation
import { DatePicker } from "antd";
import { PublisherContainer } from "./DateRange.styled.js";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
const updateLocale = require("dayjs/plugin/updateLocale");
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
    weekStart: 1,
});

export const DateRange = ({
    name,
    label,
    onChange,
    onBlur,
    onFocus,
    value,
    error,
    variant,
    //
    style,
    required,
    children,
    ...props
}) => {
    const { currentTimes } = useContext(MainContext);
    const Variant = useMemo(() => (variant === "landing" ? PublisherContainer : PublisherContainer), [variant]);

    /**
     * DayJS Adaptors
     */
    const stringToDayjs = (val) => {
        if (!val) return [dayjs(), dayjs()];
        const type = typeOfAdvanced(val);
        const realDate = type === "string" ? currentTimes.dateFilters[val].set : val;
        return {
            dateSet: [dayjs(realDate[0]), dayjs(realDate[1])],
            label: type === "string" ? currentTimes.dateFilters[val].label : label,
        };
    };
    const convertedValue = useMemo(() => stringToDayjs(value), [value]);
    const onChangeAdaptor = (set, strings) => {
        const find = _.find(currentTimes.dateFilters, (o) => _.isEqual(o.set, strings));
        onChange(find ? find.key : strings, name);
        blurField();
    };

    /**
     * Blur input on close
     */
    const blurField = () => {
        setTimeout(() => {
            const el = document.getElementsByClassName("ant-picker-input-active");
            _.forEach(el, (it) => it.classList.remove("ant-picker-input-active"));
        }, 250);
    };
    useEffect(blurField, []);
    const onOpenChange = (boo) => {
        if (boo === false) blurField();
    };

    /**
     * Generate presets
     */
    const presets = useMemo(() => {
        const arr = [];
        if (!currentTimes) return arr;
        _.forOwn(currentTimes.dateFilters, (obj) => {
            const start = obj.set[0];
            const end = obj.set[1];
            arr.push({
                label: obj.label,
                value: [dayjs(start), dayjs(end)],
            });
        });
        return arr;
    }, [currentTimes]);

    /**
     * Return
     */
    return (
        <Variant aria-label={label + " container"} {...{ value, error }}>
            <label htmlFor={name}>{convertedValue?.label}</label>
            <RangePicker
                format={dateFormat}
                disabledDate={(current) => current && current > dayjs().endOf("day")}
                presets={presets}
                bordered={false}
                changeOnBlur={true}
                value={convertedValue?.dateSet}
                onChange={onChangeAdaptor}
                inputReadOnly={true}
                onOpenChange={onOpenChange}
                {...props}
            />
        </Variant>
    );
};
