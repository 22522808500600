import { useRef, useEffect, useContext, PublisherContext } from "UIV2";
import { Container } from "./Blurbackground.styled.js";

export const Blurbackground = ({ selectedItem, setSelectedItem, closeSidebarWhenMouseLeaveWindow }) => {
    const { setIsMouseOver } = useContext(PublisherContext);
    const timeoutRef = useRef();

    const onMouseEnter = () => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            setSelectedItem();
            setIsMouseOver();
        }, 1000);
    };
    const onMouseLeave = () => clearTimeout(timeoutRef.current);
    useEffect(() => () => clearTimeout(timeoutRef.current), []);

    return (
        <Container
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={closeSidebarWhenMouseLeaveWindow}
            selectedItem={selectedItem}
        />
    );
};
