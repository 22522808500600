import { useMemo, useState, useDebouncedCallback, useEffect } from "UIV2";
import {
    LandingButton,
    TextButton,
    LoadingIcon,
    PublisherButton,
    LoadingIconArea,
    SpinnerArea,
} from "./Button.styled.js";

export const Button = (props) => {
    const {
        disabled,
        loading,
        label = "BUTTON",
        variant, // text - landing - publisher
        priority, // default - primary - success - error
        onClick,
        type = "button",
        children,
        manuallyHover,
        style,
        disableInnerLoading,
    } = props || {};

    /**
     * Define Variants
     */
    const [isManualLoading, setIsManualLoading] = useState();
    const Variant = useMemo(
        () => (variant === "landing" ? LandingButton : variant === "text" ? TextButton : PublisherButton),
        [variant],
    );

    /**
     * Check manualLoading
     * Bu, buttonun bir şekilde manual disabled yapılıp yapılmadığını kontrol eder.
     * Eğer 1 defa bile manuel disabled yapılmışsa, innerLoading'i devre dışı bırakır.
     */
    useEffect(() => {
        if (isManualLoading) return;
        if (loading) setIsManualLoading(true);
    }, [loading]);

    /**
     * Process Timer
     */
    const [innerLoading, setInnerLoading] = useState(false);
    const isLoading = useMemo(() => loading || innerLoading, [innerLoading, loading]);
    const defTriggerTime = 2000;

    const handleOnClick = (e) => {
        if (isLoading || disabled) {
            e.stopPropagation();
            e.preventDefault();
            return;
        }
        !disableInnerLoading && !isManualLoading && setInnerLoading(true);
        if (onClick) onClickHandler(e);
        !disableInnerLoading && !isManualLoading && setTimeout(() => setInnerLoading(), defTriggerTime);
    };
    const withoutOnClick = () => console.log("react you're a real dump!");
    const onClickHandler = useDebouncedCallback(onClick ? onClick : withoutOnClick, defTriggerTime, {
        leading: true,
        trailing: false,
    });

    /**
     * Return
     */
    return (
        <Variant
            type={type}
            onClick={handleOnClick}
            style={{ ...style }}
            {...{ priority, disabled, isLoading, manuallyHover, variant }}
        >
            {isLoading && variant !== "text" ? (
                <SpinnerArea>
                    <span style={{ opacity: 0 }}>{children || label}</span>
                    <LoadingIconArea>
                        <LoadingIcon src={require("./loadingIcon.gif").default} alt="" />
                    </LoadingIconArea>
                </SpinnerArea>
            ) : (
                children || label
            )}
        </Variant>
    );
};
