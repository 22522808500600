import anime from "animejs";
import { useState, useLayoutEffect, isNumber, useRef, useEffect, MainContext, useContext } from "UIV2";
import { Container, Prefix, LargeNum, SmallNum, Rounder, Suffix } from "./AnimatedShortNumber.styled.js";

const aimValue = {};
const currentNumber = {};

export const AnimatedShortNumber = (props) => {
    const { isLoadingAfterDelayX2 } = useContext(MainContext);
    const { pre, suf, num = 0, children, animated = false, table = false, dontShorten, name } = props || {};
    const [rawNum, setRawNum] = useState(0);
    const [large, setLarge] = useState(0);
    const [small, setSmall] = useState();
    const [rounder, setRounder] = useState();
    const activeNumberSpan = useRef();
    const activeNumberSpanPrinted = useRef();
    const animationRef = useRef();
    const time = useRef();

    /**
     * Define Const
     */
    const defineConst = () => {
        const currentNum = children || num;
        if (currentNum === undefined || currentNum === null) {
            setLarge();
            aimValue[name] = 0;
            return;
        }
        if (currentNum === 0 || !isNumber(currentNum)) {
            setLarge(0);
            aimValue[name] = 0;
            return;
        }
        setRawNum(currentNum);
        if (dontShorten) {
            setLarge(num);
            aimValue[name] = num;
            return;
        }
        const splitCurrentNum = currentNum.toString().split(".");
        const beforeDot = Number(splitCurrentNum[0]);
        const afterDot = Number(Number(splitCurrentNum[1]).toFixed(2));

        let [large, rounder, small] = [0, 0, 0];

        if (beforeDot > 1000000000) {
            large = Math.round(beforeDot / 1000000000);
            rounder = "B";
            small = undefined;
        } else if (beforeDot > 1000000) {
            large = Math.round(beforeDot / 1000000);
            rounder = "M";
            small = undefined;
        } else if (beforeDot > 1000) {
            large = Math.round(beforeDot / 1000);
            rounder = "K";
            small = undefined;
        } else {
            large = beforeDot;
            rounder = undefined;

            if (!afterDot) small = "00";
            else if (afterDot.toString().length === 1) small = afterDot + "0";
            else small = afterDot;
        }
        setLarge(large);
        aimValue[name] = large;
        setRounder(rounder);
        setSmall(small);
    };
    useLayoutEffect(() => defineConst(), [num, children]);

    /**
     * Activate number animations
     */
    const animateCurrentNumber = () => {
        if (aimValue[name] && currentNumber[name] === aimValue[name]) {
            activeNumberSpanPrinted.current.innerHTML = aimValue[name].toLocaleString();
            return;
        }
        if (isLoadingAfterDelayX2) return;
        if (!large || !animated || !activeNumberSpanPrinted.current || !activeNumberSpan.current) return;
        const FPSUnit = large / 2;
        const incrementor = 1;

        currentNumber[name] = (currentNumber[name] || large / 2) + FPSUnit;
        if (currentNumber[name] > large) currentNumber[name] = large;

        animationRef.current = anime({
            targets: activeNumberSpan.current,
            textContent: currentNumber[name],
            round: incrementor ? 1 / incrementor : 1 / 5,
            easing: "easeOutQuint",
            duration: 1000,
            update: function (a) {
                const value = a.animations[0].currentValue;
                if (activeNumberSpanPrinted.current) {
                    activeNumberSpanPrinted.current.innerHTML = value?.toLocaleString?.();
                }
            },
        });
    };

    useEffect(() => {
        if (time.current) clearTimeout(time.current);
        time.current = setTimeout(animateCurrentNumber, 500);

        return () => {
            if (time.current) clearTimeout(time.current);
        };
    }, [large, isLoadingAfterDelayX2, activeNumberSpanPrinted?.current]);

    /**
     * Return
     */
    return (
        <Container aria-label="ShortNum" title={rawNum.toLocaleString()}>
            {!pre ? null : <Prefix {...{ table }}>{pre}</Prefix>}
            {animated ? (
                <>
                    <LargeNum hide ref={activeNumberSpan}>
                        {0}
                    </LargeNum>
                    <LargeNum {...{ table }} ref={activeNumberSpanPrinted}>
                        {0}
                    </LargeNum>
                </>
            ) : (
                <LargeNum {...{ table }}>{large?.toLocaleString?.()}</LargeNum>
            )}
            {!small || table ? null : <SmallNum>.{small}</SmallNum>}
            {!rounder ? null : <Rounder {...{ table }}>{rounder}</Rounder>}
            {!suf ? null : <Suffix {...{ table }}>{suf}</Suffix>}
        </Container>
    );
};
