//  background-color: ${(props) => (props.test2Color ? props.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.phone(css` background-color: green; `)}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};

import { styled } from "UIV2/base/styledMixins/styledMixins.js";

export const Container = styled.div`
    width: 100%;
    height: 3rem;
    background: green;
    color: white;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;

    & > span {
        margin-left: 2rem;
    }
`;
