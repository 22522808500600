import { CustomScrollbar, useContext, MainContext, useMemo, useLocation } from "UIV2";
import { siteMap } from "UIV2/base/routes/siteMap.js";
import { AccountManager } from "../../../AccountManager/AccountManager";
import { Footer, Line, SubLinks, Logo, InlineLink, InlineLinkToOut } from "./UserSubMenu.styled.js";
import { Main, Header, LinkArea, SidebarLinks, LogoArea, LogoSized } from "./UserSubMenu.styled.js";

export const UserSubMenu = () => {
    const { user, userPerm, isUserAllowedFor } = useContext(MainContext);
    const isObserver = useMemo(() => (userPerm === "observer" ? true : false), [userPerm]);
    const location = useLocation();

    /**
     * Return
     */
    return (
        <CustomScrollbar>
            <Main>
                <Header>
                    {isObserver ? <span>Observing:</span> : <span>Hello,</span>}
                    <span>{`${user.firstname} ${user.lastname}`}</span>
                </Header>
                <LinkArea>
                    {siteMap.map((item, i) => {
                        if (item[4] === "user" && isUserAllowedFor(item[3]))
                            return (
                                <SidebarLinks key={i} to={item[2]} state={{ previousUrl: location?.pathname }}>
                                    {item[0]}
                                </SidebarLinks>
                            );
                        else return null;
                    })}
                </LinkArea>
                <FooterComponent />
            </Main>
        </CustomScrollbar>
    );
};

const FooterComponent = () => {
    const { user } = useContext(MainContext);

    return (
        <Footer>
            <AccountManager {...{ user }} />
            <Line />
            <LogoArea>
                <LogoSized>
                    <Logo negative />
                </LogoSized>
            </LogoArea>
            <Line />
            <SubLinks>
                <InlineLink to="terms-of-use" target="_blank" rel="noopener noreferrer">
                    Terms of Use
                </InlineLink>
                <InlineLink to="policies" target="_blank" rel="noopener noreferrer">
                    Privacy & Cookie Policies
                </InlineLink>
                <InlineLinkToOut href="https://www.clickout.com/contact" target="_blank" rel="noopener noreferrer">
                    Contact
                </InlineLinkToOut>
            </SubLinks>
        </Footer>
    );
};
