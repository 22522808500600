import { Icon, IconHome, IconHamburger, MainContext, useContext } from "UIV2";
import { styled, css, g } from "UIV2/base/styledMixins/styledMixins.js";

export const Container = styled.div`
    display: none;
    ${g.phone(css`
        flex: 1 auto;
        align-self: flex-start;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        float: right;
        height: 8rem;
    `)}
`;
export const IconArea = styled.div`
    width: 5rem;
    height: 5rem;
    margin-right: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const HamburgerAndHomeIconForMobile = () => {
    const { goTo, setIsMobileMenuOpen } = useContext(MainContext);

    return (
        <Container>
            <IconArea onClick={() => goTo()}>
                <Icon color="primary" icon={IconHome} width={2.2} />
            </IconArea>
            <IconArea onClick={() => setIsMobileMenuOpen(true)}>
                <Icon color="primary" icon={IconHamburger} width={2.2} />
            </IconArea>
        </Container>
    );
};
