import { styled, g, css } from "UIV2/base/styledMixins/styledMixins.js";

export const Container = styled.div`
    width: 100%;
    padding: 10rem;
    position: relative;
    ${g.media(
        "phone,tablet",
        css`
            padding: 2rem;
        `,
    )}
`;
export const BigText = styled.p`
    font-size: 3rem;
    font-weight: 100;
`;
export const SubText = styled.p`
    margin-bottom: 7rem;
`;
export const ButtonArea = styled.div`
    width: min-content;
    display: flex;
    gap: 2rem;
`;
export const IconArea = styled.div`
    position: absolute;
    top: -15rem;
    left: -15rem;
    width: 100%;
    opacity: 0.1;
`;
