import { useContext, useEffect, useMemo, typeOfAdvanced } from "UIV2";
import { XFormContext } from "./XForm";

export const XFormItem = ({
    xTag = [],
    validationRules,
    disableValidationCheck,
    onChange,
    onBlur,
    onFocus,
    defaultValue,
    outboundAdaptor,
    hidden,
    ...props
}) => {
    const { variant, form, onChangeHandler, onBlurHandler, onFocusHandler } = useContext(XFormContext),
        { fieldBuilder, handlerProps, setFieldDisableValCheck, fieldRemover } = useContext(XFormContext);
    const [name = "noNameField", label, Component] = xTag;
    const { isTouched, fields } = form || {};
    const { value, error } = fields && fields[name] ? fields[name] : {};

    /**
     * build Field via XForm onLoad
     */
    useEffect(() => {
        fieldBuilder({ validationRules, name, label, defaultValue, outboundAdaptor });

        return () => {
            fieldRemover({ name });
        };
    }, []);

    useEffect(() => {
        setFieldDisableValCheck(disableValidationCheck, name);
    }, [disableValidationCheck]);

    /**
     * onItemChange
     */
    const onItemChange = (val, name) => {
        // Check if incoming val is valid. Return undefined for inValid vals.
        let isValid = true;

        if (val) {
            const type = typeOfAdvanced(val);
            if (!val) isValid = false;
            else if ((type === "string" || type === "array") && val.length < 1) isValid = false;
            else if (type === "number" && (val + "").length < 1) isValid = false;
            else if (type === "object" && Object.keys(val).length < 1) isValid = false;
        }

        if (onChange) onChange(isValid ? val : undefined, name);
        onChangeHandler(isValid ? val : undefined, name);
    };

    /**
     * onItemBlur
     */
    const onItemBlur = (e, name) => {
        if (onBlur) onBlur(e, name);
        onBlurHandler(e, name);
    };

    /**
     * onItemFocus
     */
    const onItemFocus = (e, name) => {
        if (onFocus) onFocus(e, name);
        onFocusHandler(e, name);
    };

    /**
     * Return
     */
    const Return = useMemo(
        () =>
            hidden ? null : (
                <Component
                    {...(variant && { variant: variant })}
                    name={name}
                    label={label}
                    onChange={onItemChange}
                    onBlur={onItemBlur}
                    onFocus={onItemFocus}
                    value={value}
                    error={isTouched && error ? error : false}
                    // other props are passed directly to the component.
                    {...handlerProps}
                    {...props}
                />
            ),
        [value, error, isTouched, handlerProps, props],
    );
    return Return;
};
