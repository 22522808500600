import { useContext, MainContext, useEffect, Suspense } from "UIV2";

export const PrivateRoute = ({ page, index, children, ...rest }) => {
    const { isUserAllowedFor, addToSnackbar, goTo, remindCallbackLocation, location } = useContext(MainContext);

    const pageName = page[0];
    const PageComponent = page[1];
    // const pageRoot = page[2];
    const allowedPerms = page[3];
    // const sidebarSubDirectory = page[4];
    const additionalProps = page[5];

    if (isUserAllowedFor(allowedPerms)) {
        return (
            <Suspense fallback={<ActivatePageLoading />}>
                <PageComponent {...{ pageName }} {...additionalProps} />
            </Suspense>
        );
    } else if (isUserAllowedFor("GUEST_OR_INVALIDATED")) {
        remindCallbackLocation();
        addToSnackbar({
            status: "error",
            msg: "You are not logged in yet. Redirecting to /sign-in",
        });
        goTo(`/sign-in`);
    } else if (isUserAllowedFor("ALL_PUBLISHERS")) {
        console.log(location);
        addToSnackbar({
            status: "success",
            msg: "You're already signed in. Redirecting to the home.",
        });
        goTo("/");
    } else {
        remindCallbackLocation();
        goTo("/error/404");
    }
};

const ActivatePageLoading = () => {
    const { isDevMode, setIsLoading, setIsMobileMenuOpen } = useContext(MainContext);
    useEffect(() => {
        setIsLoading(isDevMode ? false : true);
        setIsMobileMenuOpen(false);
    }, []);
    return <>suspense</>;
};
