import { styled } from "UIV2/base/styledMixins/styledMixins.js";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;
    z-index: 12;
    overflow-x: visible;
    margin-top: 2rem;

    & > div {
        overflow-x: visible;
    }
`;
