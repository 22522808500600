// import { useContext, MainContext } from "UIV2";
import { LogoArea, Footer, SubLinks, InlineLink, InlineLinkOut } from "../MobileMenu.styled.js";
import { Logo } from "UIV2/components/Logo/Logo.js";

export const FooterComponent = () => {
    /**
     * Return
     * */
    return (
        <Footer>
            <LogoArea>
                <Logo negative />
            </LogoArea>
            <SubLinks>
                <InlineLink to="terms-of-use">Terms of Use</InlineLink>
                <InlineLink to="policies">Policies</InlineLink>
                <InlineLinkOut href="https://www.clickout.com/contact">Contact</InlineLinkOut>
            </SubLinks>
        </Footer>
    );
};
