// import { CircleTopLeftContainer } from "./Layout.styled.js";
import { styled } from "UIV2/base/styledMixins/styledMixins.js";

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    opacity: 0.5;
    width: 100%;
    pointer-events: none;

    & > svg {
        width: 80%;
    }
`;

export const CircleTopLeft = () => (
    <Container>
        <svg width="329" height="266" viewBox="0 0 329 266" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="50.8005"
                cy="-12.1994"
                r="227.8"
                transform="rotate(-90 50.8005 -12.1994)"
                stroke="#0042D1"
                strokeWidth="100"
                preserveAspectRatio="none"
            />
        </svg>
    </Container>
);
