import { useContext, MainContext } from "UIV2";
import { Button } from "UIV2/libraries/useXForm";

export const GoTo = ({ url, label, out }) => {
    const { goTo } = useContext(MainContext);

    if (out)
        return (
            <Button variant="text" label={label} onClick={() => window.open(url, "_blank", "noopener,noreferrer")} />
        );
    return <Button variant="text" label={label} onClick={() => goTo(url)} />;
};
