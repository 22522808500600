import { styled, g } from "UIV2/base/styledMixins/styledMixins.js";
import { Link } from "react-router-dom";
import { Logo as LogoOrg } from "UIV2/components/Logo/Logo.js";

export const Main = styled.div`
    padding: 3rem;
    width: 44rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;
export const LogoSized = styled.div`
    max-width: 15rem;
    margin-right: 2rem;
    pointer-events: none;
`;
export const Header = styled.div`
    flex: 0 0 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > span:nth-child(2) {
        font-size: 3rem;
        margin-top: -1.2rem;
        margin-left: -0.3rem;
        font-weight: 300;
    }
`;
export const LinkArea = styled.div`
    flex: 1;
    display: block;
    & > a {
        display: block;
        width: max-content;
    }
`;

export const SidebarLinks = styled(Link)`
    position: relative;
    font-size: 2.2rem;
    line-height: 1.75;
    max-width: 40rem;
    line-height: 1.5;
    font-weight: 300;
    color: ${g.getColor("background")};
    text-decoration: none;
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    &::after {
        display: block;
        position: absolute;
        margin-top: 0.2rem;
        content: "";
        width: 0%;
        height: 0.2rem;
        background-color: ${g.getColor("background")};
        transition: all 150ms ease-in-out;
    }
    &:hover,
    &:active {
        text-decoration: none;
        color: ${g.getColor("background")};
        &::after {
            width: 100%;
        }
    }
`;
export const Footer = styled.div`
    flex: 0 0 25rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;
export const Line = styled.div`
    width: 40rem;
    height: 0.1rem;
    background-color: ${g.getColor("background")};
    margin-top: 2rem;
    margin-bottom: 2rem;
`;
export const SubLinks = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const LogoArea = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
export const Logo = styled(LogoOrg)`
    fill: ${g.getColor("background")};
    width: 12rem;
    margin-right: 2rem;
`;
export const InlineLink = styled(Link)`
    position: relative;
    text-decoration: none;
    color: ${g.getColor("background")};
    display: inline;
    margin: 0;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    transition: all 250ms ease-in-out;

    &:active,
    &:hover {
        text-decoration: none;
        color: ${g.getColor("background")};
    }

    &::after {
        background-color: ${g.getColor("background")};
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        top: 1.7rem;
        height: 0.2rem;
        width: 0%;
        transition: all 200ms ease-in-out;
    }

    &:hover::after {
        width: 100%;
    }
`;
export const InlineLinkToOut = styled.a`
    position: relative;
    text-decoration: none;
    color: ${g.getColor("background")};
    display: inline;
    margin: 0;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    transition: all 250ms ease-in-out;

    &:active,
    &:hover {
        text-decoration: none;
        color: ${g.getColor("background")};
    }

    &::after {
        background-color: ${g.getColor("background")};
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        top: 1.7rem;
        height: 0.2rem;
        width: 0%;
        transition: all 200ms ease-in-out;
    }

    &:hover::after {
        width: 100%;
    }
`;
