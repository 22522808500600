import { styled, g } from "UIV2/base/styledMixins/styledMixins.js";

export const LinksWithIcon = styled.a`
    text-decoration: none;
    color: ${g.getColor("background")};
    display: block;
    margin-top: 1rem;
    width: max-content;

    &:active,
    &:hover {
        text-decoration: none;
        color: ${g.getColor("background")};
    }

    & > div {
        display: inline;
        top: 0.2rem;
        margin-right: 1rem;
    }
`;
export const DivsWithIcon = styled.div`
    text-decoration: none;
    color: ${g.getColor("background")};
    display: block;
    margin-top: 1rem;
    width: max-content;
    cursor: pointer;

    &:active,
    &:hover {
        text-decoration: none;
        color: ${g.getColor("background")};
    }

    & > div {
        display: inline;
        top: 0.2rem;
        margin-right: 1rem;
    }
`;
