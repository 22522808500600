import { Icon, IconFilterClear } from "UIV2";
import { styled, g } from "UIV2/base/styledMixins/styledMixins.js";

const Container = styled.button`
    height: 5rem;
    width: 5rem;
    min-width: 5rem;
    margin-bottom: 0.3rem;
    border-radius: 1rem;
    border: 0.1rem solid ${g.getColor("grey40")};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 250ms ease-in-out;
    cursor: pointer;

    &:hover {
        background: ${g.getColor("grey20")};
    }

    & > div {
        pointer-events: none;
    }
`;

export const ResetButton = (props) => {
    const { onClick } = props || {};

    /**
     * Return
     */
    return (
        <Container onClick={onClick} type="reset">
            <Icon color="primary" icon={IconFilterClear} width={1.7} />
        </Container>
    );
};
