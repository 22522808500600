import "../scss/BodyStyles.scss";
import "../scss/FontSylesLib.scss";
import "UIV2/base/scss/modifiedAntd.css";
import "UIV2/base/scss/modifiedAntd_additionalLines.css";
import globalColors from "../scss/_colors.module.scss";
import globalBreakpoints from "../scss/_breakpoints.module.scss";
import { VAR } from "./variables/variables";
import { useState, useCallback, createContext, useRef } from "react";
import { useNavigate, useLocation, _, useXState, clientEventsFn } from "UIV2";
import { EffectsAndListeners } from "./EffectsAndListeners/EffectsAndListeners";
import { IdleManager } from "./IdleManager/IdleManager";
import { Loading } from "./Loading/Loading.js";
import { FullScreenController } from "./FullScreenController/FullScreenController.js";
import { Snackbar } from "./Snackbar/Snackbar.js";
import { addToSnackbarISO, removeFromSnackbarISO, clearAllSnackbarISO } from "./functions/snackbarISO.js";
import { callApiISO } from "./functions/callApiISO";
import { copyUrlToClipboardISO } from "./functions/copyUrlToClipboardISO";
import { getTimesISO, generateTimesISO } from "./functions/getTimesISO";
import { newPageISO } from "./functions/newPageISO";
import { goToISO } from "./functions/goToISO";
import { getMetadataFromLocalStorage, sendMetadataToLocalStorage } from "./functions/metadataISO";
import { getLatestHashesISO, clearMetadataISO } from "./functions/metadataISO";
import { getMetadataISO, deleteMetadaFromLocalStorage } from "./functions/metadataISO";
import { isUserAllowedForISO, loginISO, updateUserISO, logoutISO } from "./functions/userdataISO";

let isDevMode = !process?.env?.NODE_ENV || process?.env?.NODE_ENV === "development";
// let isDevMode = false;
const hideApiCallsLogs = true;

export const MainContextState = ({ children }) => {
    const [apiLoading, setApiLoading] = useState(isDevMode ? false : true);
    const [clientEvents, setClientEvents] = useState(clientEventsFn(globalBreakpoints));
    const [currentTimes, setCurrentTimes] = useState(generateTimesISO());
    const [isLoading, setIsLoading] = useState(isDevMode ? false : true);
    const [isLoadingAfterDelay, setIsLoadingAfterDelay] = useState(isLoading);
    const [isLoadingAfterDelayX2, setIsLoadingAfterDelayX2] = useState(isLoading);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(true);
    const [latestHashes, setLatestHashes] = useState();
    const [latestApiResponses, setLatestApiResponses] = useXState();
    const [metadata, setMetadata, refMetadata] = useXState(getMetadataFromLocalStorage());
    const [pageName, setPageName] = useState();
    const [snackState, setSnackState, getSnackState] = useXState();
    const [user, setUser] = useState();
    const [userPerm, setUserPerm] = useState();
    const [isFullScreen, setIsFullScreen] = useState();
    const callbackLocation = useRef();
    const navigate = useNavigate();
    const location = useLocation();

    const states = {
        apiLoading,
        callbackLocation,
        clientEvents,
        currentTimes,
        deleteMetadaFromLocalStorage,
        getMetadataFromLocalStorage,
        getSnackState,
        globalBreakpoints,
        globalColors,
        hideApiCallsLogs,
        isDevMode,
        isFullScreen,
        isLoading,
        isLoadingAfterDelay,
        isLoadingAfterDelayX2,
        isMobileMenuOpen,
        latestApiResponses,
        latestHashes,
        location,
        metadata,
        navigate,
        pageName,
        refMetadata,
        sendMetadataToLocalStorage,
        setApiLoading,
        setClientEvents,
        setCurrentTimes,
        setIsFullScreen,
        setIsLoading,
        setIsLoadingAfterDelay,
        setIsLoadingAfterDelayX2,
        setIsMobileMenuOpen,
        setLatestApiResponses,
        setLatestHashes,
        setMetadata,
        setPageName,
        setSnackState,
        setUser,
        setUserPerm,
        snackState,
        user,
        userPerm,
        VAR,
    };

    /** COMMON FUNCTIONS */
    const addToSnackbar = (p) => addToSnackbarISO(p, states); // addToSnackbar({ status: "error", msg: parsed.message });
    const clearAllSnackbar = () => clearAllSnackbarISO(states);
    const removeFromSnackbar = (key) => removeFromSnackbarISO(key, states);
    const goTo = (val, newTarget) => goToISO(val, newTarget, states);
    const callApi = (p) => callApiISO(p, { ...states, addToSnackbar, goTo });
    const commonFn = { addToSnackbar, clearAllSnackbar, callApi, goTo, removeFromSnackbar };

    const disableIsLoadingWithDelay = useCallback(_.debounce(setIsLoading, 250), []);
    const getTimes = () => getTimesISO(states);
    const clearMetadata = () => clearMetadataISO(states);
    const copyUrlToClipboard = () => copyUrlToClipboardISO(addToSnackbar);
    const getLatestHashes = () => getLatestHashesISO({ ...states, ...commonFn });
    const getMetadata = (key) => getMetadataISO(key, { ...states, ...commonFn }); // useLayoutEffect(() => getMetadata("geos"), []); const options = useMemo(() => metadata?.geos?.data, [metadata?.geos?.data]);
    const isUserAllowedFor = (permGroupNameOrGroupArray) => isUserAllowedForISO(permGroupNameOrGroupArray, userPerm);
    const login = async (incoming) => await loginISO(incoming, { ...states, ...commonFn });
    const logout = async () => await logoutISO({ ...states, ...commonFn });
    const newPage = (pageName) => newPageISO(pageName, states);
    const remindCallbackLocation = () => (callbackLocation.current = location);
    const updateUser = (data) => updateUserISO(data, { ...states, ...commonFn });

    /**
     * Return
     * * Ctrl+P and type the greater than sign (>). Next type sort and choose Sort Lines Ascending
     */
    const values = {
        ...states,
        ...commonFn,
        clearMetadata,
        copyUrlToClipboard,
        disableIsLoadingWithDelay,
        getLatestHashes,
        getMetadata,
        getTimes,
        isUserAllowedFor,
        login,
        logout,
        newPage,
        remindCallbackLocation,
        updateUser,
    };
    return (
        <MainContext.Provider value={values}>
            <EffectsAndListeners />
            <IdleManager />
            <Loading />
            <Snackbar />
            <FullScreenController />
            {!latestHashes || !userPerm ? <div>Waiting for latest Hashes and userPerm</div> : children}
        </MainContext.Provider>
    );
};

export const MainContext = createContext();
