//  background-color: ${(props) => (props.test2Color ? props.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.phone(css` background-color: green; `)}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};

import { styled } from "UIV2/base/styledMixins/styledMixins.js";

export const ThumbsArea = styled.div`
    width: 100%;
    display: grid;
    box-sizing: border-box;
    gap: 2rem;
    grid-template-columns: ${(p) => `repeat(${p.column}, 1fr)`};

    & > div {
        &:after {
            content: "";
            display: block;
            padding-bottom: 85%;
        }
    }
`;

export const LoadMoreContainer = styled.div`
    width: 100%;
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: ${(p) => (p.header ? "unset" : "15rem")};
`;

export const PageNumbers = styled.div`
    width: ${(props) => (props.header ? "100%" : "12rem")};
`;
export const Top = styled.div`
    width: 12rem;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    user-select: none;
`;
export const ButtonArea = styled.div`
    max-width: 20rem;
`;
export const Seperator = styled.div`
    grid-column-start: 1;
    grid-column-end: ${(p) => p.column + 1};
    max-height: 8rem;
    display: grid;
    grid-template-columns: 5rem 1fr 5rem;
    & > div {
        width: max-content;
        padding-top: 2.5rem;
        user-select: none;
    }
    & > div:nth-child(2) {
        justify-self: center;
    }
    & > div:nth-child(3) {
        justify-self: end;
    }
`;
