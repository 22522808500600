import { useState, useEffect, useRef, useEventListener, useContext, MainContext, PublisherContext } from "UIV2";
import { ButtonArea } from "./ButtonArea/ButtonArea.js";
import { Logo } from "./Logo/Logo.js";
import { SidebarSubLinks } from "./SidebarSubLinks/SidebarSubLinks.js";
import { Blurbackground } from "./Blurbackground/Blurbackground.js";
import { Container, Background, SeperatorLine } from "./Sidebar.styled.js";

export const Sidebar = () => {
    const { isLoadingAfterDelay, isLoadingAfterDelayX2, currentBreakpoint, isFullScreen } = useContext(MainContext);
    const { plAssets } = useContext(PublisherContext);
    const { isMouseOver, setIsMouseOver } = plAssets || {};
    const [selectedItem, setSelectedItem] = useState();
    const timeoutRef = useRef();
    const overMode = isMouseOver || selectedItem;

    /**
     * OnMouseEnter - Leave Functions
     */
    const onMouseEnter = () => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => setIsMouseOver(true), 250);
    };
    const onMouseLeave = () => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            setIsMouseOver(false);
            setSelectedItem();
        }, 250);
    };

    const closeSidebarWhenMouseLeaveWindow = () => {
        clearTimeout(timeoutRef.current);
        setIsMouseOver(false);
        setSelectedItem(false);
    };
    const setMouseOverImmediately = () => {
        clearTimeout(timeoutRef.current);
        setIsMouseOver(true);
    };
    useEventListener("blur", closeSidebarWhenMouseLeaveWindow, window);
    useEffect(() => () => clearTimeout(timeoutRef.current), []);

    /**
     * If isModal or isLoadingAfterDelay is changed,
     * change overflow-x: hidden with a delay.
     */
    const overflowTimeoutRef = useRef();
    const [isOverflowHidden, setIsOverflowHidden] = useState(false);
    useEffect(() => {
        clearTimeout(overflowTimeoutRef.current);

        overflowTimeoutRef.current = setTimeout(() => setIsOverflowHidden(), 1000);
    }, []);

    /**
     * Return
     */
    if (currentBreakpoint === "phone") return null;
    return (
        <Container
            {...{ overMode, isLoadingAfterDelay, isLoadingAfterDelayX2, isOverflowHidden, isFullScreen }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <Logo {...{ selectedItem }} />
            <ButtonArea
                {...{
                    selectedItem,
                    setSelectedItem,
                    setMouseOverImmediately,
                    closeSidebarWhenMouseLeaveWindow,
                    isMouseOver,
                    setIsMouseOver,
                }}
            />
            <SeperatorLine {...{ overMode, isMouseOver, selectedItem }} />
            <SidebarSubLinks {...{ selectedItem }} />
            <Background {...{ overMode, isMouseOver, selectedItem }} />
            <Blurbackground {...{ selectedItem, setSelectedItem, closeSidebarWhenMouseLeaveWindow }} />
        </Container>
    );
};
