import { CallFromSiteMap } from "./CallFromSiteMap/CallFromSiteMap.js";
import { UserSubMenu } from "./UserSubMenu/UserSubMenu.js";
import { Container } from "./SidebarSubLinks.styled.js";

export const SidebarSubLinks = (props) => {
    const { selectedItem } = props;

    /**
     * Return
     */
    return (
        <Container {...{ selectedItem }}>
            {selectedItem === "report" ? (
                <CallFromSiteMap get="report" />
            ) : selectedItem === "market" ? (
                <CallFromSiteMap get="market" />
            ) : selectedItem === "howTo" ? (
                <CallFromSiteMap get="howTo" />
            ) : selectedItem === "user" ? (
                <UserSubMenu />
            ) : null}
        </Container>
    );
};
