import { useMemo, useRef, useState, useEffect, _ } from "UIV2";
import { optionCreator, blurOnSelectForSingleSelect, clearTitlesOfSelectedItem } from "./Select.functions.js";
import { AntSelect, LandingContainer, PublisherContainer } from "./Select.styled.js";
import "./antDropdownClass.scss";

export const Select = ({
    // args which come from XFromItem
    name,
    value,
    label,
    error,
    required,
    style,
    variant,
    onBlur,
    onFocus,
    onChange,
    disabled,

    // args which special to this component
    options = [],
    optionButtons,
    CustomOptionComponent,
    sortByKey,
    getKey,
    getLabel,
    additionalDropdownStyle,
    children,
    mode,
    ...props
}) => {
    const selectRef = useRef();
    const [autocompleteDisabled, setAutocompleteDisabled] = useState();
    const [isFocused, setIsFocused] = useState();

    /**
     * Define Variants
     */
    const Variant = useMemo(() => (variant === "landing" ? LandingContainer : PublisherContainer), [variant]);

    /**
     * Generate Options
     */
    const generatedOptions = useMemo(
        () => optionCreator({ arr: options, getKey, getLabel, sortByKey, CustomOptionComponent, optionButtons, value }),
        [isFocused === true],
    );

    /**
     * onChange
     */
    const onChangeHandler = (val) => {
        let newVal = val;
        if (optionButtons) {
            _.forEach(optionButtons, (m) => {
                if (val.includes(m.id)) {
                    if (m?.result) newVal = m.result;
                    return false;
                }
            });
        }
        onChange(newVal, name);
    };

    /**
     * Hack for selectedItem "title" prop
     */
    const onSelect = () => {
        blurOnSelectForSingleSelect(mode, selectRef);
        clearTitlesOfSelectedItem();
    };
    useEffect(() => clearTitlesOfSelectedItem(), []);

    /**
     * Hack for autuFill and autoComplete
     */
    const onInnerFocus = (e) => {
        if (onFocus) onFocus(e, name);
        if (!autocompleteDisabled) {
            const el = document.getElementsByClassName("ant-select-selection-search-input");
            _.forEach(el, (it) => {
                it.setAttribute("autocomplete", `new-${name}`);
                it.setAttribute("name", `new-${name}`);
                it.setAttribute("id", `new-${name}`);
            });
            setAutocompleteDisabled(true);
        }
    };
    const onInnerBlur = (e) => (onBlur ? onBlur(e, name) : undefined);
    const onDropdownVisibleChange = (p) => {
        setIsFocused(p);
        if (!p) selectRef.current?.blur();
    };

    /**
     * Return
     */
    return (
        <Variant
            aria-label={label}
            {...{ value, error, isFocused, disabled, multiple: mode === "multiple" }}
            style={{ ...style }}
        >
            <label htmlFor={name}>{label + (required ? "*" : "") + (error ? " " + error : "")}</label>
            <AntSelect
                ref={selectRef}
                name={name}
                autoComplete={`${name}`}
                value={value}
                onChange={onChangeHandler}
                placeholder={label + (required ? "*" : "") + (error ? " " + error : "")}
                //
                // DEFAULT SETTINGS
                //
                showSearch
                size="small"
                allowClear
                autoClearSearchValue
                filterOption={(input, option) => {
                    return option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                dropdownClassName="antDropdownClass"
                dropdownStyle={{ ...additionalDropdownStyle }}
                onDropdownVisibleChange={onDropdownVisibleChange}
                onSelect={onSelect}
                onFocus={onInnerFocus}
                onBlur={onInnerBlur}
                mode={mode}
                // SOME OTHER API
                // defaultOpen
                // maxTagCount="responsive"
                maxTagCount={2}
                // autoFocus
                disabled={disabled}
                {...props}
            >
                {generatedOptions}
            </AntSelect>
        </Variant>
    );
};
