import { useState, _, useEffect, calc } from "UIV2";
import { ResponsiveBar } from "@nivo/bar";

const chartColors = {
    // bar colour, text colour
    10: ["#135072", "#fff"],
    9: ["#2B6280", "#fff"],
    8: ["#42738E", "#fff"],
    7: ["#5A859C", "#fff"],
    6: ["#7196AA", "#454545"],
    5: ["#89A8B9", "#454545"],
    4: ["#A1B9C7", "#454545"],
    3: ["#B8CAD5", "#454545"],
    2: ["#D0DCE3", "#454545"],
    1: ["#E7EDF1", "#454545"],
    0: ["#FFFFFF", "#454545"],
};

/**
 * Calculate Max Min Amounts on data change.
 */
const calcMaxMinAmounts = (chartData) => {
    let min = 0,
        max = 0;
    _.forEach(chartData, (it) => {
        if (it.value > max) max = it.value;
        if (it.value < min) min = it.value;
    });
    return { min, max };
};

/**
 * Define color opacity depends on max - min val.
 */
const getColor = (bar, amounts) => {
    const min = amounts.min;
    const max = amounts.max;
    const prc = (max - min) / 100;
    const current = 1 - (max - bar.value) / prc / 100 || 1;
    const definedCurrent = Number(current.toFixed(1)) * 10;
    return chartColors[definedCurrent > 10 ? 10 : definedCurrent < 0 ? 0 : definedCurrent][0];
};

/**
 * Define Text Color depending on bar colour
 */
const getTextColor = (bar) => {
    const foundKey = _.findKey(chartColors, (o) => o[0] === bar.color);
    return chartColors[foundKey][1];
};

/**
 * Component
 */
export const NivoResponsiveBar = (props) => {
    const { chartData } = props || {};
    const [amounts, setAmounts] = useState(calcMaxMinAmounts(chartData));

    /**
     * Effect
     */
    useEffect(() => setAmounts(calcMaxMinAmounts(chartData)), [chartData]);

    /**
     * Return
     */
    return (
        <ResponsiveBar
            data={chartData}
            indexBy="date"
            keys={["value"]}
            margin={{ top: 20, right: 20, bottom: 23, left: 20 }}
            padding={0.3}
            colors={(bar) => getColor(bar, amounts)}
            axisTop={null}
            axisRight={null}
            axisLeft={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "date",
                legendPosition: "middle",
                legendOffset: 32,
            }}
            valueFormat={(value) => calc.fix(value)}
            labelTextColor={getTextColor}
            role="application"
            motionConfig="molasses"
            ariaLabel="Performance Report"
            animate={false}
        />
    );
};
