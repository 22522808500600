import { Icon, IconLink, IconKey, copyToClipboard } from "UIV2";
import { LinksWithIcon, DivsWithIcon } from "./AccountManager.styled.js";
import { getAccountManagerName } from "./getAccountManagerName.js";

export const AccountManager = (props) => {
    const { user } = props || {};
    const { accountManager, apiToken, apiDocs } = user || {};
    const name = getAccountManagerName(accountManager);

    /**
     * return
     */
    return (
        <div>
            {name && <span>Your account manager is {name}</span>}
            {!apiToken ? null : (
                <DivsWithIcon onClick={() => copyToClipboard(apiToken)}>
                    <Icon color="background" icon={IconKey} width={1.4} />
                    Copy Your Api Token
                </DivsWithIcon>
            )}
            {!apiDocs ? null : (
                <LinksWithIcon href={apiDocs} target="_blank">
                    <Icon color="background" icon={IconLink} width={1.4} />
                    Go To Api Documentation
                </LinksWithIcon>
            )}
        </div>
    );
};
