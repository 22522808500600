import { styled, css, g, keyframes } from "UIV2/base/styledMixins/styledMixins.js";

const buttonAnimation = keyframes`
  0% {
        background-color: ${g.getColor("background")};
        width: 0rem;
    }
    100% {
        background-color: ${g.getColor("accent")};
        width: 11rem;
    }


`;
export const Button = styled.button`
    font-weight: 700;
    position: relative;
    display: flex;
    height: 10rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1.15rem;
    color: white;
    text-transform: uppercase;
    user-select: none;
    transition: all 100ms ease-in-out;
    overflow-x: visible;
    width: ${(props) => (props.overMode ? "10rem" : "5rem")};

    &::before {
        content: "";
        width: 0rem;
        height: 10rem;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        overflow-x: visible;
    }

    &:hover {
        &::before {
            animation: ${buttonAnimation} 200ms forwards 150ms;
        }
    }

    ${(props) => {
        if (props.selected) {
            return css`
                /* pointer-events: none;
                cursor: default; */

                &:hover {
                    &::before {
                        animation: none;
                    }
                }

                &::before {
                    content: "";
                    width: 11rem;
                    height: 10rem;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: ${g.getColor("accentShade")};
                }
            `;
        }
    }}
`;
export const Line = styled.div`
    height: 0.1rem;
    margin-top: 1rem;

    width: 40%;
    background-color: ${g.getColor("grey30")};

    ${(props) => {
        if (props.overMode) {
            return css`
                width: 60%;
                background-color: ${g.getColor("background")};
            `;
        }
    }}
`;
export const LineSup = styled(Line)`
    margin-bottom: 2rem;
`;
export const Label = styled.span`
    margin-top: 1rem;
    opacity: 0;
    transition: all 250ms ease-in-out;
    color: ${g.getColor("background")};
    white-space: nowrap;

    ${(props) => {
        if (props.overMode) {
            return css`
                opacity: 1;
                color: ${g.getColor("background")};
            `;
        }
    }}
`;
