import { Icon, IconDot } from "UIV2";
import { styled, g } from "UIV2/base/styledMixins/styledMixins.js";

export const Container = styled.button`
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    align-items: center;
    max-width: max-content;
    margin-right: 2.5rem;
    cursor: pointer;
    text-transform: capitalize;
    user-select: none;
    top: 0.2rem;

    color: ${g.getColor("grey40")};
    font-size: 1.4rem;
    font-weight: 500;

    & > div:first-child {
        margin-right: 0.5rem;
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0.2rem;
        width: 0%;
        height: 2px;
        border-radius: 5px;
        transition: all 150ms ease-in-out 250ms;
        background-color: ${g.getColor("grey20")};
    }

    &:hover {
        &:after {
            transition: all 150ms ease-in-out;
            width: 100%;
        }
    }
`;

export const ActionButton = ({ action }) => {
    const [
        IconComponent = IconDot,
        iconSize = 1,
        actionLabel = "Missing Name",
        actionFn = () => console.log("Missing action"),
    ] = action || [];

    /**
     * Return
     */
    if (!action) return null;
    return (
        <Container aria-label="ActionButton" onClick={actionFn} tabindex="1">
            <Icon color="grey60" icon={IconComponent} width={iconSize * 1.2} />
            {actionLabel}
        </Container>
    );
};
