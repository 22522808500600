import { useMemo, useDebounce, useContext, MainContext, Scrollbars } from "UIV2";
import { updateColumnsISO, onChangeISO, filteredDataISO } from "./AntTable.functions.js";
import { TableContainer } from "./AntTable.styled.js";
import { Table } from "antd";

export const AntTable = ({ features, antProps, ...props }) => {
    const {
        columns,
        data: dataSource,
        searchValue,
        searchAt,
        pageSize = 20,
        currentPage,
        onPageChange,
        sortBy,
        sortOrder,
    } = features || {};
    const [debouncedSearch] = useDebounce(searchValue, 1000);
    const { clientEvents } = useContext(MainContext);
    const { winW } = clientEvents || {};
    const minWidthPx = 1000;

    /**
     * Isolated Functions
     */
    const onChange = (pagination, filters, sorter) => onChangeISO(pagination, filters, sorter, features);
    const updatedCols = useMemo(() => updateColumnsISO(features), [columns, sortBy, sortOrder]);
    const filteredData = useMemo(
        () => filteredDataISO(dataSource, debouncedSearch, searchAt),
        [dataSource, debouncedSearch],
    );

    /**
     * Scroll Wrapper
     */
    const ScrollWrapper = ({ children }) => {
        if (winW < minWidthPx)
            return (
                <Scrollbars style={{ width: "100%" }} autoHeight autoHeightMin={0} autoHeightMax={20000}>
                    {children}
                </Scrollbars>
            );
        return <>{children}</>;
    };

    /**
     * Return
     */
    return (
        <ScrollWrapper>
            <TableContainer aria-label="AntTable" {...{ minWidthPx }}>
                <Table
                    bordered
                    size="small"
                    onChange={onChange}
                    dataSource={filteredData}
                    columns={updatedCols}
                    sortDirections={["ascend", "descend", "ascend"]}
                    showSorterTooltip={false}
                    pagination={
                        filteredData?.length <= pageSize
                            ? false
                            : {
                                  position: ["none", "bottomCenter"],
                                  simple: true,
                                  pageSize,
                                  current: currentPage || 1,
                                  onChange: onPageChange,
                              }
                    }
                    {...antProps}
                />
            </TableContainer>
        </ScrollWrapper>
    );
};
