import { styled } from "UIV2/base/styledMixins/styledMixins.js";

export const Container = styled.div`
    width: max-content;
    display: inline-flex;
    justify-content: center;
    align-items: center;
`;

export const SmallNum = styled.span`
    margin-left: 0.3rem;
    font-size: 1.1rem;
`;
export const LargeNum = styled.span`
    display: ${(props) => (props.hide ? "none" : "inline")};
    font-size: ${(props) => (props.table ? "2.8rem" : "1.6rem")};
    opacity: ${(props) => (props.table ? 0.4 : 1)};
    font-weight: 400;
`;
export const Prefix = styled(LargeNum)`
    font-size: ${(props) => (props.table ? "1.8rem" : "1.3rem")};
`;

export const Rounder = styled(LargeNum)`
    font-size: ${(props) => (props.table ? "1.8rem" : "1.3rem")};
`;
export const Suffix = styled(LargeNum)`
    font-size: ${(props) => (props.table ? "1.8rem" : "1.3rem")};
`;
