import { _, AnimatedShortNumber } from "UIV2";
import { styled } from "UIV2/base/styledMixins/styledMixins.js";

const HeaderCellContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const HeaderTitle = styled.div`
    width: 100%;
    font-size: 1.2rem;
    font-weight: 700;
    & > span {
        font-weight: 400;
    }
`;

export const updateColumnsISO = (features) => {
    const { columns, sortBy, sortOrder } = features || {};

    _.forEach(columns, (col) => {
        /**
         * Add Sort Features
         */
        if (col.sortable !== false) {
            col.sortOrder = (sortBy === col.dataIndex || sortBy === col.key) && sortOrder;
        }

        /**
         * BREAK FOR EACH IF col is ALREADY UP TO DATE.
         */
        if (col.updated) return;

        /**
         * Add Animated Numbers
         */
        if (col.shortNum !== undefined && col.shortNum !== null) {
            col.title = (
                <HeaderCellContainer>
                    <AnimatedShortNumber
                        name={col.title}
                        dontShorten={col.dontShorten}
                        num={col.shortNum}
                        animated={false}
                        table
                        pre={col?.shortNumPrefix || false}
                        suf={col?.shortNumSuffix || false}
                    />
                    <HeaderTitle>
                        {col?.title}
                        <span>
                            {col?.shortNumPrefix || col?.shortNumSuffix ? " (" : ""}
                            {col?.shortNumPrefix || ""}
                            {col?.shortNumSuffix || ""}
                            {col?.shortNumPrefix || col?.shortNumSuffix ? ")" : ""}
                        </span>
                    </HeaderTitle>
                </HeaderCellContainer>
            );
        }

        /**
         * Add Ellipsis + Tooltip Feature
         */
        if (col.ellipsis !== false) {
            col.ellipsis = true;
            // col.render = (data) => ( <Tooltip placement="topLeft" title={data}> {data} </Tooltip> );
        }

        /**
         * Add "sorter" Key
         */
        if (col.sortable !== false) col.sorter = (a, b) => ("" + a[col.dataIndex]).localeCompare(b[col.dataIndex]);

        /**
         * Add "key" Key
         */
        if (!col.key) col.key = col.dataIndex;

        /**
         * Add Align Right
         */
        if (!col.align) col.align = "center";

        /**
         * Add a lock for multiple update
         */
        col.updated = true;
    });

    return columns;
};
export const onChangeISO = (pagination, filters, sorter, features) => {
    const { onSortChange } = features || {};
    if (onSortChange) {
        onSortChange({ sortBy: sorter.columnKey, sortDirection: sorter.order });
    }
};
export const filteredDataISO = (dataSource = [], searchValue, searchAt) => {
    if (!searchValue || !searchAt) return dataSource;

    const checkForAllSearchAtItems = (o) => {
        let boo = false;
        _.forEach(searchAt, (it) => {
            if ((o[it] + "").toLowerCase().includes((searchValue + "").toLowerCase())) boo = true;
        });
        return boo;
    };
    return dataSource.filter((o) => checkForAllSearchAtItems(o));
};

/**
 * Adaptor between useXState filters and AntTable
 * filters use "asc" and "desc" for sortDirection. But antTable needs "ascend" - "descend"
 * also, this function fixes changing sortDirection when only sortBy is changed
 * IF FILTER'S PROP NAMES ARE CHANGED, THIS FUNCTION DOESN'T WORK.
 */
export const onSortChangeOutboundAdaptor = (sortBy, sortDirection, filters) => {
    const currentSortBy = filters?.values?.sortBy;
    if (currentSortBy !== sortBy) filters?.fn?.setField(sortBy, "sortBy");
    else filters?.fn?.setField(sortDirection === "ascend" ? "asc" : "desc", "sortDirection");
};
export const onSortChangeInboundAdaptor = (val) => {
    if (val?.toLowerCase() === "asc") return "ascend";
    return "descend";
};
export const onPageChangeAdaptor = (newPage, filters) => {
    filters?.fn?.setField(newPage, "page");
};
