import { useContext, MainContext, useMemo } from "UIV2";
import { Container } from "./ObserverBar.styled.js";

export const ObserverBar = (props) => {
    const { user, userPerm, logout } = useContext(MainContext);
    const isObserver = useMemo(() => (userPerm === "observer" ? true : false), [userPerm]);

    /**
     * Return
     */
    if (!isObserver) return null;
    return (
        <Container aria-label="ObserverBar" onClick={logout}>
            You're observing {user?.email} <span>[ Logout ]</span>
        </Container>
    );
};
