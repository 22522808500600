import { useState, useContext, useRef, useLayoutEffect, MainContext } from "UIV2";
import { ClickOutLogoAnimation, startTimeoutFnISO } from "./Loading.functions.js";
import { closeTriggersISO, setScrollISO } from "./Loading.functions.js";
import { Purple, LogoArea, UpCircle, UpSmallCircle } from "./Loading.styled.js";

export const Loading = () => {
    const {
        isLoadingAfterDelay,
        setIsLoadingAfterDelay,
        setIsLoadingAfterDelayX2,
        isDevMode,
        isLoading = false,
        apiLoading = false,
        isMobileMenuOpen = false,
    } = useContext(MainContext);

    const [innerApiTrigger, setInnerApiTrigger] = useState(apiLoading);
    const [innerTimer, setInnerTimer] = useState(true);
    const [playTriggerForLottie, setPlayTriggerForLottie] = useState(0);
    const [isFirstPlay, setIsFirstPlay] = useState(true);
    const timeoutRef = useRef();
    const timeoutRefX2 = useRef();

    const states = {
        timeoutRef,
        timeoutRefX2,
        setIsLoadingAfterDelayX2,
        setIsFirstPlay,
        isFirstPlay,
        playTriggerForLottie,
        setPlayTriggerForLottie,
        isLoading,
        apiLoading,
        innerTimer,
        setInnerTimer,
        setInnerApiTrigger,
        setIsLoadingAfterDelay,
    };

    /**
     * Open loading scene immediately, if isLoading is true
     */
    const startTimeoutFn = (forIsLoading) => startTimeoutFnISO({ forIsLoading, ...states });
    const closeTriggers = () => closeTriggersISO({ ...states });
    useLayoutEffect(
        () => setScrollISO(isLoadingAfterDelay, innerApiTrigger, isMobileMenuOpen, isDevMode),
        [isLoadingAfterDelay, innerApiTrigger, isMobileMenuOpen],
    );

    /**
     * Check triggers
     */
    useLayoutEffect(() => {
        if (isLoading) {
            setIsLoadingAfterDelay(true);
            setIsLoadingAfterDelayX2(true);
            startTimeoutFn(true);
        } else if (apiLoading) {
            setInnerApiTrigger(true);
            if (!isFirstPlay) startTimeoutFn(false);
        } else closeTriggers();
    }, [isLoading, apiLoading]);

    /**
     * Check InnerTimer's behaivours
     */
    useLayoutEffect(() => closeTriggers(), [innerTimer]);

    // if (isDevMode || !isLoadingAfterDelay) return null;
    if (isDevMode) return null;
    return (
        <Purple showMe={isLoadingAfterDelay}>
            <UpCircle>
                <CircleSvg />
            </UpCircle>
            <UpSmallCircle>
                <SmallCircleSvg />
            </UpSmallCircle>
            <LogoArea>
                <ClickOutLogoAnimation play={isLoadingAfterDelay} />
            </LogoArea>
        </Purple>
    );
};

const CircleSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" fill="#fff">
        <path d="M250,0h-50.17c-.05,13.37-1.42,26.71-4.08,39.66-2.63,12.86-6.56,25.51-11.67,37.6-5.02,11.88-11.25,23.34-18.49,34.07-7.19,10.64-15.44,20.64-24.53,29.73-9.09,9.09-19.1,17.35-29.73,24.53-10.73,7.25-22.19,13.47-34.07,18.49-12.09,5.11-24.74,9.04-37.6,11.67-12.95,2.65-26.29,4.02-39.66,4.08v50.17c137.87-.45,249.55-112.13,250-250Z" />
    </svg>
);
const SmallCircleSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 100" fill="#FAAD13">
        <path d="M100-97C153.49-97,197-53.49,197,0s-43.51,97-97,97S3,53.49,3,0,46.51-97,100-97m0-3C44.77-100,0-55.23,0,0S44.77,100,100,100,200,55.23,200,0s-44.77-100-100-100h0Z" />
    </svg>
);
