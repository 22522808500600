import { Icon, IconCheck } from "UIV2";
import { Container, HeaderTitle, BulletContainer, IconArea, TextArea } from "./AccelerateYourRevenue.styled.js";

export const AccelerateYourRevenue = () => {
    /**
     * Return
     */
    return (
        <Container aria-label="DontHaveAnAccount">
            <HeaderTitle>Accelerate your revenue and monetize your traffic.</HeaderTitle>
            {bullets.map((it) => (
                <Bullet text={it} />
            ))}
        </Container>
    );
};

const bullets = [
    "Exclusive offers across varied niches",
    "Weekly guaranteed payments (also in BTC/Fiat/USTD)",
    "Dedicated account managers",
    "Real time dashboards to track performance",
    "Advanced optimization techniques to improve revenue",
];

const Bullet = ({ text }) => {
    /**
     * Return
     */
    return (
        <BulletContainer>
            <IconArea>
                <Icon color="background" icon={IconCheck} width={2} />
            </IconArea>
            <TextArea>{text}</TextArea>
        </BulletContainer>
    );
};
