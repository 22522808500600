import { styled } from "UIV2/base/styledMixins/styledMixins.js";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;
export const HeaderTitle = styled.div`
    font-size: 2rem;
    font-weight: 700;
`;
export const BoxContainer = styled.div`
    width: 100%;
    padding: 3rem 2rem;
    margin: 1rem 0;
    border: 0.1rem solid white;
    background-color: rgba(255, 255, 255, 0);
    transition: all 250ms ease-in-out;
    display: flex;
    cursor: pointer;

    &:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }
`;
export const Col1 = styled.div`
    flex: 1 auto;
    padding-right: 2rem;
`;
export const TitleArea = styled.div`
    font-size: 1.6rem;
    font-weight: 700;
`;
export const SubTextArea = styled.div``;
export const Col2 = styled.div`
    flex: 0 3rem;
    align-self: center;
`;
