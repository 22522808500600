import { useState, useContext, MainContext, useEffect, _, useRef, useMemo, useLayoutEffect } from "UIV2";
import { Container, TokenButton, LineDiv } from "./PostbackSelector.styled.js";
import { PopCloser, TextAreaPopArea2, MarkTextArea } from "./PostbackSelector.styled.js";
import { TextArea } from "UIV2/libraries/useXForm";

export const PostbackSelector = ({
    name,
    label,
    onChange,
    onBlur: extOnBlur,
    onFocus: extOnFocus,
    value,
    ...props
}) => {
    const { metadata, getMetadata } = useContext(MainContext);
    useLayoutEffect(() => getMetadata("postbackVariables"), []);

    const [onFocus, setOnFocus] = useState(false);
    const [domain, setDomain] = useState();
    const [tokens, setTokens] = useState(); // one single string after domain
    const [tokensArr, setTokensArr] = useState(); // metadata
    const [selectedTokens, setSelectedTokens] = useState(); // ['{{clickid}}', '{{source}}']
    const [manualTokens, setManualTokens] = useState();
    const [selectedTokenStrings, setSelectedTokenStrings] = useState(); // {{{clickid}}: 'cid={{clickid}}', {{source}}: 'source={{source}}'}
    const innerFocus = useRef();
    const textAreaRef = useRef();
    const markArea = useRef();

    /**
     * Get Values And Split Them
     */
    const splitValues = () => {
        if (!value) {
            setDomain();
            setTokens();
            return;
        }
        let newValue = _.clone(value);

        // Find domain
        newValue = newValue.split("?");
        const domain = newValue[0];
        newValue.splice(0, 1).join("");
        setDomain(domain);

        // Split tokens
        newValue = newValue + "";
        setTokens(newValue + "");
    };
    useEffect(() => splitValues(), [value]);

    /**
     * Get current tokens
     */
    useEffect(() => setTokensArr(metadata?.postbackVariables?.data), [metadata?.postbackVariables?.data]);

    /**
     * Check current value and detect tokens
     */
    const detectTokens = () => {
        const selectedTokens = [];
        const manualTokens = [];
        const selectedTokenStrings = {};

        if (tokens && tokensArr) {
            const splittedTokens = tokens.split("&");

            _.forEach(splittedTokens, (splittedToken) => {
                let added = false;
                _.forEach(tokensArr, (token) => {
                    if (splittedToken.includes(token)) {
                        added = true;
                        selectedTokens.push(token);
                        selectedTokenStrings[token] = splittedToken;
                    }
                });
                if (!added && !manualTokens.includes(splittedToken)) {
                    manualTokens.push(splittedToken);
                }
            });
        }
        setManualTokens(manualTokens);
        setSelectedTokens(selectedTokens);
        setSelectedTokenStrings(selectedTokenStrings);
    };
    useEffect(() => detectTokens(), [tokens, tokensArr]);

    /**
     * Token Handler
     */
    const tokenHandler = (e, token) => {
        // console.log(token); // {{clickid}}
        let newTokens = _.clone(tokens);
        if (!newTokens) newTokens = "";

        const addTokenToString = () => {
            const tokenWithoutBrackets = token.split("{").join("").split("}").join("");
            const string = `${tokenWithoutBrackets}=${token}`;
            const lastCharacter = newTokens[newTokens.length - 1];
            newTokens =
                newTokens +
                ((lastCharacter && lastCharacter === "}") || (manualTokens.length > 0 && selectedTokens.length === 0)
                    ? "&"
                    : "") +
                string;
        };

        const removeTokenFromString = () => {
            const string = selectedTokenStrings[token]; // "dynamicKey={{clickid}}"
            const indexOfStr = newTokens.indexOf(string);
            newTokens = newTokens.split(string).join("");
            // remove string
            if (indexOfStr > -1 && newTokens[indexOfStr - 1] === "&") {
                newTokens = newTokens.slice(0, indexOfStr - 1) + newTokens.slice(indexOfStr);
            }
            // remove question mark
            if (string.length === tokens?.length) {
                newTokens = newTokens.slice(0, newTokens.length - 1);
            }
            // remove ampersand if first token is deleted.
            if (newTokens.slice(0, 1) === "&") {
                newTokens = newTokens.slice(1);
            }
        };

        if (selectedTokens.includes(token)) removeTokenFromString();
        else addTokenToString();

        const newValue = `${domain ? domain : ""}${newTokens ? "?" + newTokens : ""}`;
        onChange(newValue, name);
        detectTokens(tokensArr, newValue, setSelectedTokens);
        e.preventDefault();
    };

    /**
     * Focus Blur Handlers
     */
    const onFocusHandler = (e) => {
        if (extOnFocus) extOnFocus(e, name);
        setOnFocus(true);
    };
    const onBlurHandler = (e) => {
        if (extOnBlur) extOnBlur(e, name);
        if (innerFocus.current) return;
        setOnFocus(false);
        innerFocus.current = false;
    };

    /**
     * InnerFocusHandlers
     */
    const innerOnFocus = () => (innerFocus.current = true);
    const innerOnBlur = () => (innerFocus.current = false);

    /**
     * textAreaScroll
     */
    const textAreaScroll = () => {
        const el = textAreaRef?.current?.resizableTextArea?.textArea;
        const divEl = markArea?.current;
        if (!el || !divEl) return;
        divEl.scrollTop = el.scrollTop;
    };

    /**
     * Highlight textArea tokens
     */
    const highlightedTokens = useMemo(() => {
        let newValue = _.clone(value);
        if (newValue && tokensArr && selectedTokenStrings) {
            _.forEach(tokensArr, (token) => {
                const findTokenString = selectedTokenStrings[token];
                newValue = newValue.replace(findTokenString, `<mark>${findTokenString}</mark>`);
            });
        }
        return `${newValue}`;
    }, [value, selectedTokens, selectedTokenStrings]);

    /**
     * Return
     */
    return (
        <Container>
            <TextArea
                name={name}
                label={label}
                onChange={onChange}
                value={value}
                onFocus={onFocusHandler}
                onBlur={onBlurHandler}
                forwardRef={textAreaRef}
                onScroll={textAreaScroll}
                {...props}
            />
            <MarkTextArea ref={markArea} dangerouslySetInnerHTML={{ __html: highlightedTokens }} />
            {!onFocus || !tokensArr ? null : (
                <>
                    <TextAreaPopArea2 onMouseEnter={innerOnFocus} onMouseLeave={innerOnBlur}>
                        <Line title="Tokens">
                            {tokensArr.map((token) => (
                                <TokenButton
                                    selected={selectedTokens.includes(token) ? true : false}
                                    onMouseDown={(e) => tokenHandler(e, token)}
                                    type="button"
                                >
                                    {token}
                                </TokenButton>
                            ))}
                        </Line>
                    </TextAreaPopArea2>
                    <PopCloser
                        onClick={() => {
                            setOnFocus(false);
                            innerFocus.current = false;
                        }}
                    />
                </>
            )}
        </Container>
    );
};

const Line = ({ title, children }) => {
    return (
        <LineDiv>
            <div>{title}</div>
            <div>{children}</div>
        </LineDiv>
    );
};
