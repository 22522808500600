//  background-color: ${(props) => (props.test2Color ? props.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.phone(css` background-color: green; `)}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};

import { styled, g } from "UIV2/base/styledMixins/styledMixins.js";

export const RangeButton = styled.div`
    display: inline-block;
    text-align: center;
    background-color: ${g.getColor("accentExtraLight")};
    padding: 0rem 0.7rem;
    transition: all 250ms ease-in-out;
    cursor: pointer;
    margin-right: 1.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    line-height: 3;

    &:hover {
        background-color: ${g.getColor("accent")};
        color: white;
    }
`;

export const PublisherContainer = styled.div`
    position: relative;
    width: 100%;
    min-width: 30rem;
    height: 5rem;
    margin-bottom: 0.3rem;
    cursor: pointer !important;
    transition: all 250ms ease-in-out;

    & > label {
        position: absolute;
        width: max-content;
        font-size: 1.2rem;
        font-weight: 500;
        margin-top: -1rem;
        margin-left: 1.5rem;
        padding: 0 1.5rem;
        z-index: 2;
        opacity: ${(props) => (props.value || props.isFocused ? 1 : 0)};
        color: ${(props) => (props.error ? g.getColor("error") : g.getColor("grey40"))};
        pointer-events: none;
        user-select: none;
        background: ${g.getColor("background")};
        border-radius: 1rem;
        white-space: nowrap;
    }

    & > .ant-picker {
        width: 100% !important;
        height: 5rem;
        background-color: transparent;
        border: ${(props) => `0.1rem solid ${props.error ? g.getColor("error") : g.getColor("grey40")}`} !important;
        border-radius: 1rem;
        z-index: 1;
        &:focus,
        &:hover {
            outline: 0 !important;
            box-shadow: 0 0 0 0px rgba(0, 0, 0, 0) !important;
        }

        & > .ant-picker-input-active {
            background-color: ${g.getColor("accentExtraLight")};
            & > input {
                font-size: 1.5rem;
                line-height: 5rem;
                height: 5rem;
                color: ${g.getColor("primary")};
            }
        }

        & > .ant-picker-input > input {
            height: 5rem;
            background-color: transparent;
            font-size: 1.4rem;
            padding-left: 2.7rem;
            color: ${(props) => (props.error ? g.getColor("error") : g.getColor("grey60"))};
            border-radius: 1rem;

            &::placeholder {
                font-size: 1.4rem;
                color: ${(props) => (props.error ? g.getColor("error") : g.getColor("grey60"))};
                font-variant-ligatures: no-common-ligatures;
                line-height: 5rem;
                height: 5rem;
            }
            &:hover {
                cursor: pointer;
                background-color: rgba(0, 0, 0, 0.1);
            }
        }

        & > .ant-picker-suffix {
            display: none;
        }
        & > .ant-picker-range-separator {
            /* display: none; */
            & > span {
                font-size: 2rem;
            }
        }
        & > .ant-picker-clear {
            margin-right: 1rem;
            background: ${g.getColor("background")} !important;
            /* & > span > svg {
                fill: ${g.getColor("background")} !important;
            } */
        }
    }
`;
