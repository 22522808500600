import { useContext, MainContext, _ } from "UIV2";

export const Flags = (props) => {
    const { code, label, width, height } = props || {};
    const { metadata } = useContext(MainContext);

    const getCode = (code, label) => {
        // Imps.
        if (code === "0" || code === "Global" || label === "Global") return "_global";

        // Standart code
        if (!code && !label) return "_blank";
        if (code) return code.toLowerCase();
        if (label) {
            const geoList = metadata?.geos?.data;
            const index = _.findIndex(geoList, (o) => o.country === label);
            return geoList[index].code.toLowerCase();
        }
    };

    /**
     * Return
     */
    return (
        <img
            src={require(`./svgs/${getCode(code, label)}.svg`).default}
            alt={label}
            style={{ width: width || "100%", height: height || "auto" }}
        />
    );
};
