import { styled, g, css } from "UIV2/base/styledMixins/styledMixins.js";
import { Link } from "react-router-dom";

export const Container = styled.div`
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    padding: 0 2rem;
    top: 0;
    left: 0;
    z-index: 10001;
    background-color: ${g.getColor("primary")};
    height: 0;
    transition: all 0ms ease-in-out;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    ${(props) => {
        if (props.isMobileMenuOpen) {
            return css`
                transition: all 500ms ease-in-out;
                height: 100vh;
            `;
        }
    }}
`;

export const LogoArea = styled.button`
    width: 15rem;
`;

export const Header = styled.div`
    flex: 0 7rem;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
`;

export const Content = styled.div`
    flex: 1 auto;
    color: ${g.getColor("background")};
    padding: 2rem;
    text-align: center;
`;
export const Links = styled.div`
    position: relative;
    font-weight: 300;
    line-height: 1.5;
    font-size: 2rem;
    color: ${g.getColor("background")};
    text-decoration: none;
    text-transform: uppercase;
    margin: 0;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: left;
`;

export const Footer = styled.div`
    flex: 0 14rem;
    color: ${g.getColor("background")};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 0.1rem solid ${g.getColor("background")};
    padding-top: 3rem;
`;
export const InlineLink = styled(Link)`
    position: relative;
    text-decoration: none;
    color: ${g.getColor("background")};
    display: inline;
    margin: 0;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
`;
export const InlineLinkOut = styled.a`
    position: relative;
    text-decoration: none;
    color: ${g.getColor("background")};
    display: inline;
    margin: 0;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
`;
export const SubLinks = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
`;
