// import { CircleTopLeftContainer } from "./Layout.styled.js";
import { styled } from "UIV2/base/styledMixins/styledMixins.js";

const Container = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 0;
    opacity: 0.2;
    display: flex;
    align-items: flex-end;
    transform: rotate(-270deg);
    pointer-events: none;
`;

export const CircleBottomRight = () => (
    <Container>
        <svg width="355" height="324" viewBox="0 0 355 324" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="345.5"
                cy="345.5"
                r="150"
                transform="rotate(-90 345.5 345.5)"
                stroke="#0042D1"
                strokeWidth="50"
                preserveAspectRatio="none"
            />
        </svg>
    </Container>
);
