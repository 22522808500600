import { styled, g } from "UIV2/base/styledMixins/styledMixins.js";

export const PublisherContainer = styled.div`
    position: relative;
    width: 100%;
    height: 5rem;
    margin-bottom: 0.3rem;
    cursor: pointer !important;
    transition: all 250ms ease-in-out;
    border: ${(props) => `0.1rem solid ${g.getColor("grey40")}`};
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;
