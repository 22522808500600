import { useMemo, Icon, IconPasswordEyeOpen, IconPasswordEyeClose, useState } from "UIV2";
import { LandingContainer, AntInput, EyeIcon, PublisherContainer } from "./Input.styled.js";

export const Input = ({
    name,
    value,
    label,
    error,
    required,
    style,
    type,
    variant,
    onBlur,
    onFocus,
    onChange,
    ...props
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const [isFocused, setIsFocused] = useState();

    /**
     * Define Variants
     */
    const Variant = useMemo(() => (variant === "landing" ? LandingContainer : PublisherContainer), [variant]);

    /**
     * onChange
     */
    const onChangeHandler = (e) => onChange(e.target.value, name);

    /**
     * Toggle Password Viewer
     */
    const togglePasswordEye = () => setShowPassword(!showPassword);

    /**
     * onBlur onFocus
     */
    const onInnerFocus = (e) => {
        if (onFocus) onFocus(e, name);
        setIsFocused(true);
    };
    const onInnerBlur = (e) => {
        if (onBlur) onBlur(e, name);
        setIsFocused(false);
    };

    /**
     * Return
     */
    return (
        <Variant aria-label={label} {...{ value, error, isFocused }} style={{ ...style }}>
            <label htmlFor={name}> {label + (required ? "*" : "") + (error ? " " + error : "")} </label>
            <AntInput
                value={value}
                type={type === "password" && showPassword ? "text" : type || "text"}
                onChange={onChangeHandler}
                placeholder={label + (required ? "*" : "") + (error ? " " + error : "")}
                name={name}
                //
                // DEFAULT SETTINGS
                //
                size="small"
                autoComplete={name}
                onFocus={onInnerFocus}
                onBlur={onInnerBlur}
                {...props}
            />
            {type !== "password" ? null : (
                <EyeIcon>
                    <Icon
                        color="grey40"
                        icon={!showPassword ? IconPasswordEyeOpen : IconPasswordEyeClose}
                        width={2}
                        onClick={togglePasswordEye}
                    />
                </EyeIcon>
            )}
        </Variant>
    );
};
