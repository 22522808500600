export const sendNewCode = async (p) => {
    const { user, callApi, addToSnackbar, email } = p || {};
    const userEmail = user?.email || email;
    if (!callApi || !addToSnackbar) return;
    else if (!userEmail) {
        addToSnackbar({ status: "error", msg: "E-mail address is missing." });
        return;
    }
    const { isValid } = await callApi({
        method: "PUT",
        url: "publisher/account/resend",
        params: { email: userEmail },
        token: false,
    });
    if (isValid) addToSnackbar({ status: "success", msg: "A new code has been sent." });
};
