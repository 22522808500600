import { typeOfAdvanced, _ } from "UIV2";
import { LOCAL_STORAGE_NAMES } from "UIV2/base/context/variables/variables.js";

const localStoreName = LOCAL_STORAGE_NAMES.metadata;
export const getMetadataFromLocalStorage = () => JSON.parse(localStorage.getItem(localStoreName));
export const sendMetadataToLocalStorage = (newMd) => localStorage.setItem(localStoreName, JSON.stringify(newMd));
export const deleteMetadaFromLocalStorage = () => localStorage.removeItem(localStoreName);
/**
 *
 */
export const clearMetadataISO = (states) => {
    const { setMetadata } = states || {};
    setMetadata();
    deleteMetadaFromLocalStorage();
};
const saveNewDataAndHash = (key, newMdKeyData, newMdKeyHash, states) => {
    const { setMetadata, refMetadata } = states || {};
    if (!key || !newMdKeyData || !newMdKeyHash) return;
    setMetadata(
        {
            data: newMdKeyData,
            hash: newMdKeyHash,
        },
        key,
    );
    sendMetadataToLocalStorage(refMetadata());
};
export const getLatestHashesISO = async (states) => {
    const { callApi, setLatestHashes } = states || {};
    const { isValid, data } = await callApi({
        method: "GET",
        url: "metadata/hashes",
        token: false,
        hideApiLoading: true,
    });
    if (isValid) setLatestHashes(data);
};

const getMetadataApiCallISO = async (key, states) => {
    const { callApi } = states || {};
    const { isValid, data } = await callApi({
        method: "GET",
        url: "metadata/" + key,
        token: false,
        killOnError: true,
    });
    const newMdKeyData = data && data[key];
    const newMdKeyHash = data?.hashes && data.hashes[key];
    if (!isValid || !newMdKeyData || !newMdKeyHash) return { isValid: false };
    return { isValid: true, newMdKeyData, newMdKeyHash };
};
export const getMetadataISO = (key, states) => {
    const { refMetadata, latestHashes = {} } = states || {};
    const metadata = refMetadata() || {};
    const singleKeyFn = async (singleKey) => {
        if (metadata && metadata[singleKey]) {
            const currentData = metadata[singleKey].data;
            const currentHash = metadata[singleKey].hash;
            const newMdKeyHash = latestHashes[singleKey];
            if (currentHash === newMdKeyHash) {
                return currentData;
            }
        }
        const { isValid, newMdKeyData, newMdKeyHash } = await getMetadataApiCallISO(singleKey, states);
        if (!isValid) return;
        saveNewDataAndHash(singleKey, newMdKeyData, newMdKeyHash, states);
        return newMdKeyData;
    };

    const typeOf = typeOfAdvanced(key);
    if (typeOf === "array") _.forEach(key, (it) => singleKeyFn(it));
    else singleKeyFn(key);
};
