//  background-color: ${(props) => (props.test2Color ? props.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.phone(css` background-color: green; `)}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};

import { styled } from "UIV2/base/styledMixins/styledMixins.js";

export const IconComponent = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 500ms ease;
    width: ${(props) => props.width + "rem"};
    height: ${(props) => props.width + "rem"};
    background-color: ${(props) => props.bColorStyle};
    pointer-events: ${(props) => (props.hoverEnabled ? "auto" : "none")};

    &:hover {
        cursor: ${(props) => (props.hoverEnabled ? "pointer" : "default")};
    }

    & > svg {
        transition: all 500ms ease;
    }
`;
export const SvgWrapper = styled.svg`
    transition: all 500ms ease;
    pointer-events: none;
    width: ${(props) => props.width + "rem"};
    fill: ${(props) => props.colorStyle};
`;
