import { Icon, useMemo, useContext, PublisherContext } from "UIV2";
import { Button, Line, Label } from "./SidebarButton.styled.js";

export const SidebarButton = ({ label, name, icon, onClick, selectedItem, noBorder, ...props }) => {
    const { isMouseOver, selectedItem: selectionIsOn } = useContext(PublisherContext);
    const selected = selectedItem === name ? true : false;
    const iconColor = useMemo(() => (isMouseOver ? "background" : "grey30"), [isMouseOver]);
    const overMode = selectionIsOn || isMouseOver;

    return (
        <Button {...{ overMode, selected, isMouseOver }} onClick={() => onClick(name)}>
            <Icon color={iconColor} icon={icon} width={2} />
            <Label {...{ overMode }}>{label}</Label>
            {noBorder ? null : <Line {...{ overMode }} />}
        </Button>
    );
};
