import {
    getFieldValuesISO,
    getFormValuesISO,
    bulkUpdateForFieldValuesISO,
    createSchemaISO,
    fieldBuilderISO,
    fieldRemoverISO,
    setFieldValueISO,
    setFieldIsTouchedISO,
    localStoreUrlParamsISO,
    resetFormISO,
    setIsFormReadyISO,
    setFieldDisableValCheckISO,
} from "./XForm.functions.js";
import { useLayoutEffect, useState, useMemo, createContext, useContext, useEffect } from "react";
import { useXState, MainContext, useDebouncedCallback } from "UIV2";
import { ErrorDirtyController } from "./ErrorDirtyController/ErrorDirtyController";
import { styled } from "UIV2/base/styledMixins/styledMixins.js";

const Form = styled.form`
    position: relative;
    width: 100%;
    text-align: center;
    margin: 0 auto;
`;

export const XForm = ({
    children,
    xState,
    enableUrlParamsLocalStore,
    localStoreName,
    urlParamsInboundAdaptor,
    urlParamsOutboundAdaptor,
    variant,
    onChange: extOnChange,
    onSubmit: extOnSubmit,
    onReset: extOnReset,
    onBlur: extOnBlur,
    onFocus: extOnFocus,
    onFormLoad: extOnFormLoad,
    ...props
}) => {
    const { location, navigate } = useContext(MainContext);
    const subtitute = useXState();
    const [form, setForm, getForm] = xState || subtitute;
    const [isFormLoaded, setIsFormLoaded] = useState();

    /**
     * LocalStorage & UrlSearch Param Settings
     */
    const obj2 = { localStoreName, enableUrlParamsLocalStore, urlParamsInboundAdaptor, urlParamsOutboundAdaptor };
    const obj = useMemo(() => localStoreUrlParamsISO({ location, navigate, ...obj2 }), []);
    const { localStore, setLocalStorage, urlParams, setUrlParams } = obj || {};

    /**
     * Form Functions
     */
    const setFieldValue = (val, name) => setFieldValueISO(val, name, setForm);
    const setFieldIsTouched = (val, name) => setFieldIsTouchedISO(val, name, setForm);
    const setFieldDisableValCheck = (val, name) => setFieldDisableValCheckISO(val, name, setForm);
    const bulkUpdateForFieldValues = (obj) => bulkUpdateForFieldValuesISO(obj, setForm, getForm);
    const getFormValues = () => getFormValuesISO(getForm);
    const getFieldValues = () => getFieldValuesISO(getForm);
    const resetForm = () => resetFormISO(setForm, getForm);
    const fieldBuilder = (p) => fieldBuilderISO({ p, setForm });
    const fieldRemover = (p) => fieldRemoverISO({ p, setForm });
    const setIsFormReady = () => setIsFormReadyISO({ setForm, isFormLoaded, setIsFormLoaded });

    /**
     * onChange
     */
    const externalOnChangeTrigger = (val, name) => {
        const { values, isFormValid, form } = getFormValues();
        extOnChange({
            lastChangedValue: val,
            lastChangedField: name,
            values,
            isFormValid,
            form,
        });
    };
    const debouncer = useDebouncedCallback(externalOnChangeTrigger, 1000);
    const onChangeHandler = (val, name) => {
        setFieldValue(val, name);
        const { values } = getFormValues();
        setLocalStorage(values);
        setUrlParams(values);
        if (extOnChange) debouncer(val, name);
    };

    /**
     * onFocus
     */
    const onFocusHandler = (e, name) => {
        setFieldIsTouched(true, name);
        if (extOnFocus) {
            const { values, isFormValid, form } = getFormValues();
            extOnFocus({
                e,
                lastBluredField: name,
                values,
                isFormValid,
                form,
            });
        }
    };

    /**
     * onBlur
     */
    const onBlurHandler = (e, name) => {
        if (extOnBlur) {
            const { values, isFormValid, form } = getFormValues();
            extOnBlur({
                e,
                lastBluredField: name,
                values,
                isFormValid,
                form,
            });
        }
    };

    /**
     * onSubmit
     */
    const onSubmitHandler = (e) => {
        e.preventDefault();
        setForm(true, "isSubmitting");
        if (extOnSubmit) {
            const { values, isFormValid, form } = getFormValues();
            extOnSubmit({
                e,
                values,
                isFormValid,
                form,
            });
        }
    };

    /**
     * onReset
     */
    const onResetHandler = (e) => {
        resetForm();
        const { values, isFormValid, form } = getFormValues();
        setLocalStorage(values);
        setUrlParams(values);
        if (extOnReset) {
            extOnReset({
                e,
                values,
                isFormValid,
                form,
            });
        }
    };

    /**
     * onFirstLoad
     */
    const onFormLoad = () => {
        if (extOnFormLoad) {
            const { values, isFormValid, form } = getFormValues();
            extOnFormLoad({
                values,
                isFormValid,
                form,
            });
        }
    };
    useEffect(() => {
        if (isFormLoaded) onFormLoad();
    }, [isFormLoaded]);

    /**
     * Create Form on Mount
     */
    let createObj = { setForm, getFieldValues, getFormValues, onResetHandler, bulkUpdateForFieldValues };
    createObj = { ...createObj, setIsFormLoaded, setFieldValue, onChangeHandler, urlParams, localStore };
    useLayoutEffect(() => createSchemaISO(createObj), []);

    /**
     * Return
     */
    if (!children) return null;
    const providerValues = {
        xState,
        form,
        setForm,
        getForm,
        fieldBuilder,
        fieldRemover,
        setFieldValue,
        setFieldIsTouched,
        setFieldDisableValCheck,
        getFormValues,
        getFieldValues,
        resetForm,
        bulkUpdateForFieldValues,
        isFormLoaded,
        setIsFormLoaded,
        localStore,
        setLocalStorage,
        urlParams,
        setUrlParams,
        urlParamsInboundAdaptor,
        urlParamsOutboundAdaptor,
        enableUrlParamsLocalStore,
        localStoreName,
        handlerProps: props,
        variant,
        onChangeHandler,
        onBlurHandler,
        onFocusHandler,
        setIsFormReady,
    };
    return (
        <XFormContext.Provider value={providerValues}>
            <ErrorDirtyController />
            <Form onSubmit={onSubmitHandler} onReset={onResetHandler} action="#">
                {children}
            </Form>
        </XFormContext.Provider>
    );
};
export const XFormContext = createContext();
