import { useContext, MainContext, useState, Icon, IconArrowLeft, IconClose } from "UIV2";
import { FooterComponent } from "./FooterComponent/FooterComponent.js";
import { siteMap } from "UIV2/base/routes/siteMap.js";
import { Container, Header, Content, Links } from "./MobileMenu.styled.js";

export const MobileMenu = () => {
    const { isMobileMenuOpen, setIsMobileMenuOpen, goTo, user } = useContext(MainContext);
    const [selectedMenu, setSelectedMenu] = useState();

    const closeMobileMenu = () => setIsMobileMenuOpen();

    /**
     * Return
     */
    return (
        <Container {...{ isMobileMenuOpen }}>
            <Header>
                {selectedMenu ? (
                    <Icon
                        color="background"
                        icon={IconArrowLeft}
                        width={2.5}
                        hoverColor="background"
                        onClick={() => setSelectedMenu()}
                    />
                ) : (
                    <div />
                )}
                <Icon
                    color="background"
                    icon={IconClose}
                    width={2.5}
                    hoverColor="background"
                    onClick={closeMobileMenu}
                />
            </Header>
            <Content>
                {!selectedMenu ? (
                    <>
                        <Links onClick={() => setSelectedMenu("report")}>REPORTS</Links>
                        <Links onClick={() => setSelectedMenu("market")}>OFFERS</Links>
                        <Links onClick={() => setSelectedMenu("user")}>{`${user.firstname} ${user.lastname}`}</Links>
                    </>
                ) : (
                    siteMap.map((item) => {
                        if (item[4] === selectedMenu) return <Links onClick={() => goTo(item[2])}>{item[0]}</Links>;
                        else return null;
                    })
                )}
            </Content>
            <FooterComponent />
        </Container>
    );
};
