//  background-color: ${(props) => (props.test2Color ? props.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.phone(css` background-color: green; `)}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};

import { styled } from "UIV2/base/styledMixins/styledMixins.js";

export const Container = styled.div`
    width: 100%;
    display: flex;
`;
export const IconDiv = styled.div`
    display: inline-block;
    flex: 0 2.5rem;
    margin-right: 1rem;
`;
export const LabelArea = styled.div`
    display: inline-block;
    flex: 1 auto;
    width: 2.5rem;
    margin-right: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
`;
