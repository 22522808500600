import { useContext, MainContext, PublisherContext } from "UIV2";
import { Container, Svg } from "./Logo.styled.js";

export const Logo = ({ selectedItem }) => {
    const { isMouseOver } = useContext(PublisherContext);
    const { goTo } = useContext(MainContext);

    return (
        <Container {...{ isMouseOver }} onClick={() => goTo("/")}>
            <div>
                <Svg viewBox="0 0 17.3 17.3" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.9,7.9L2,12.7c-0.2,0.1-0.1,0.4,0.1,0.4h2.5c0.2,0,0.3,0.2,0.2,0.3l-1.1,1.8c-0.1,0.1,0,0.2,0.1,0.3L5,16.2
	c0.1,0.1,0.2,0,0.3-0.1l1.1-1.8c0.1-0.2,0.3-0.2,0.4,0L8,16.5c0.1,0.2,0.4,0.1,0.4-0.1l0.7-8.3C9.3,7.9,9.1,7.8,8.9,7.9z M6.4,0.3
	c-4.7,1.2-7.3,6-6.1,10.6c0.1,0.4,0.3,0.8,0.4,1.2l3.1-2l0,0c0,0,0-0.1,0-0.1C3,7.3,4.6,4.7,7.4,3.9c2.7-0.7,5.5,0.8,6.1,3.4
	c0.7,2.6-0.8,5.3-3.6,6.1c0,0,0,0-0.1,0l-0.2,3.8c0.4-0.1,0.8-0.1,1.2-0.2c4.7-1.2,7.4-6,6.1-10.6C15.8,1.8,11.1-0.9,6.4,0.3z"
                    />
                </Svg>
            </div>
        </Container>
    );
};

export const LogoFull = ({ selectedItem }) => {
    const { isMouseOver } = useContext(PublisherContext);
    const { goTo } = useContext(MainContext);

    return (
        <Container>
            <div>
                <Svg
                    viewBox="0 0 17.3 90"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => goTo("")}
                    {...{ isMouseOver, selectedItem }}
                >
                    <path
                        d="M9,56.4l-3.5-5c-0.1-0.1-0.3-0.1-0.3,0.1l0,1.9c0,0.1-0.1,0.2-0.2,0.1l-1.3-0.8c-0.1,0-0.2,0-0.2,0.1l-0.5,0.9
		c0,0.1,0,0.2,0.1,0.2l1.3,0.8c0.1,0.1,0.1,0.2,0,0.3l-1.6,0.9c-0.1,0.1-0.1,0.3,0.1,0.3l6.1,0.5C8.9,56.6,9,56.5,9,56.4z"
                    />
                    <path
                        d="M8.4,13.7c-2.1,0-3.6,1.5-3.6,3.5c0,1.1,0.4,1.9,1,2.6c0.1,0.1,0.1,0.3,0,0.4l-1.5,1.3h0
		c-0.1,0.1-0.2,0.1-0.4,0c-1.1-1.1-1.8-2.6-1.8-4.3c0-3.5,2.8-6.3,6.3-6.3c3.5,0,6.3,2.8,6.3,6.3c0,1.7-0.7,3.3-1.8,4.3
		c-0.1,0.1-0.2,0.1-0.4,0l-1.5-1.3c-0.1-0.1-0.1-0.2,0-0.4c0.6-0.7,1-1.6,1-2.6C12.1,15.3,10.5,13.7,8.4,13.7z M15.1,25.3v-2.1
		c0-0.2-0.1-0.3-0.3-0.3H2.6c-0.2,0-0.3,0.1-0.3,0.3v2.1c0,0.2,0.1,0.3,0.3,0.3h12.3C15,25.6,15.1,25.5,15.1,25.3z M15.2,28.4
		c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4C14.6,29.8,15.2,29.2,15.2,28.4z M10.9,29.5v-2.1h0
		c0-0.2-0.1-0.3-0.3-0.3h-8c-0.2,0-0.3,0.1-0.3,0.3v2.1c0,0.2,0.1,0.3,0.3,0.3h8C10.8,29.8,10.9,29.7,10.9,29.5z M5.2,37.5
		c0.1-0.1,0.1-0.2,0-0.4v0c-0.4-0.5-0.7-1-0.7-1.6c0-1.1,0.9-1.9,2.1-1.9s2.1,0.8,2.1,1.9c0,0.6-0.2,1.1-0.7,1.6
		c-0.1,0.1-0.1,0.3,0,0.4l1.2,1.3c0.1,0.1,0.2,0.1,0.4,0c0.9-0.8,1.5-2,1.5-3.3c0-2.5-2-4.4-4.5-4.4c-2.5,0-4.5,1.9-4.5,4.4
		c0,1.4,0.6,2.6,1.5,3.4c0.1,0.1,0.3,0.1,0.4,0L5.2,37.5z M10.9,48.8v-2.4c0-0.1,0-0.2-0.1-0.3l-3-3.4h7.1c0.2,0,0.3-0.1,0.3-0.3
		v-2.1c0-0.2-0.1-0.3-0.3-0.3H2.6c-0.2,0-0.3,0.1-0.3,0.3v2.1c0,0.2,0.1,0.3,0.3,0.3h1.9l1.1,1l-3.2,2.4c-0.1,0.1-0.2,0.2-0.2,0.3
		v2.3c0,0.2,0.1,0.3,0.3,0.2l4.7-3.5l3.2,3.6C10.7,49.1,10.9,49,10.9,48.8z M10.9,72v-2.1c0-0.2-0.1-0.3-0.3-0.3H6.1
		c-0.6,0-1.5-0.5-1.5-1.6c0-0.7,0.4-1.5,1.8-1.5h4.3c0.2,0,0.3-0.1,0.3-0.3v-2.1c0-0.2-0.1-0.3-0.3-0.3H5.8c-2.3,0-3.7,1.4-3.7,3.3
		c0,1,0.6,1.9,1.4,2.5l-0.9,0.1c-0.2,0-0.3,0.1-0.3,0.3v2c0,0.2,0.1,0.3,0.3,0.3h8v0C10.8,72.3,10.9,72.2,10.9,72z M10.9,78.8v-1.2
		h2.4c0.2,0,0.3-0.1,0.3-0.3v-2.1c0-0.2-0.1-0.3-0.3-0.3h-2.4v-1.2c0-0.2-0.1-0.3-0.3-0.3H9.1c-0.2,0-0.3,0.1-0.3,0.3V75H2.6
		c-0.2,0-0.3,0.1-0.3,0.3v2.1c0,0.2,0.1,0.3,0.3,0.3h6.2v1.2c0,0.2,0.1,0.3,0.3,0.3h1.6h0C10.8,79.1,10.9,79,10.9,78.8z M14.5,54.6
		c-0.9-3.4-4.4-5.4-7.7-4.5c-0.3,0.1-0.6,0.2-0.9,0.3l1.5,2.3l0,0c0,0,0,0,0.1,0c1.9-0.5,3.9,0.6,4.4,2.6c0.5,2-0.6,4-2.5,4.5
		c-1.9,0.5-3.9-0.6-4.4-2.6c0,0,0,0,0,0L2.1,57c0,0.3,0.1,0.6,0.2,0.9c0.9,3.4,4.4,5.4,7.7,4.5C13.4,61.4,15.4,58,14.5,54.6z"
                    />
                </Svg>
            </div>
        </Container>
    );
};
