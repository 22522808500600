import { typeOfAdvanced } from "UIV2";

const hasToBeFilled = (val) => {
    const msg = "has to be filled.";
    let isValid = true;

    if (!val) {
        isValid = false;
    } else {
        const typeOf = typeOfAdvanced(val);
        if ((typeOf === "array" || typeOf === "string") && val.length < 1) isValid = false;
        else if (typeOf === "object" && Object.keys(val).length < 1) isValid = false;
        else if (typeOf === "number") isValid = true;
    }

    if (!isValid) return { msg: msg, status: false };
    else return { msg: "", status: true };
};
const hasToBeFilledForWhatsApp = (val, values) => {
    const msg = "has to be filled.";
    let isValid = true;

    if (values?.imName?.value === "WhatsApp") {
        isValid = true;
    } else if (!val) {
        isValid = false;
    } else {
        const typeOf = typeOfAdvanced(val);
        if ((typeOf === "array" || typeOf === "string") && val.length < 1) isValid = false;
        else if (typeOf === "object" && Object.keys(val).length < 1) isValid = false;
        else if (typeOf === "number") isValid = true;
    }

    if (!isValid) return { msg: msg, status: false };
    else return { msg: "", status: true };
};
const hasToBeAValidPassword = (val) => {
    // empty vals return true. To check if the field is empty or not, use hasToBeFilled
    const msg = "has to include  8 characters at least.";

    if (!val || val.length > 7) return { msg: "", status: true };
    else return { msg: msg, status: false };
};
const hasToBeAValidPhoneNumber = (val) => {
    // empty vals return true. To check if the field is empty or not, use hasToBeFilled
    const msg = "has to include  8 numbers at least.";

    if (!val || val.length > 7) return { msg: "", status: true };
    else return { msg: msg, status: false };
};
const hasToBeAValidEmail = (val) => {
    // empty vals return true. To check if the field is empty or not, use hasToBeFilled
    const msg = "has to be a valid e-mail";
    const reg = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

    if (!val || reg.test(String(val).toLowerCase())) return { msg: "", status: true };
    else return { msg: msg, status: false };
};
const hasToBeAValidUrl = (val) => {
    // empty vals return true. To check if the field is empty or not, use hasToBeFilled
    const trueObj = { msg: "", status: true };
    const validURL = () => {
        var pattern = new RegExp(
            "^(https?:\\/\\/)?" +
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
                "((\\d{1,3}\\.){3}\\d{1,3}))" +
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
                "(\\?[;&a-z\\d%_.~+=-]*)?" +
                "(\\#[-a-z\\d_]*)?$",
            "i",
        );
        return !!pattern.test(val);
    };
    if (!val) return trueObj;
    else if (!val.includes("https://")) return { msg: "has to include 'https://'", status: false };
    else if (!validURL()) return { msg: "has to be a valid url", status: false };
    else return trueObj;
};
const hasToBeAValidPostback = (val) => {
    // empty vals return true. To check if the field is empty or not, use hasToBeFilled
    const msg = "has to be a valid postback url which includes 'https://'";
    var reg = new RegExp("^(https?:\\/\\/)", "i");

    if (!val || !!reg.test(val)) return { msg: "", status: true };
    else return { msg: msg, status: false };
};
const hasToBeEqualToNewPassword = (val, values) => {
    const msg = "has to be equal to New Password";
    if (values?.newPassword?.value === val) return { msg: "", status: true };
    else return { msg: msg, status: false };
};
const hasToBeAValidWalletId = (val, values) => {
    const msg = "has to be a valid wallet id";
    const isValid = val && val.length > 40 && /^[0-9a-zA-Z]+$/.test(val);

    if (isValid) return { msg: "", status: true };
    else return { msg: msg, status: false };
};

/**
 * Export All
 */
export const rules = {
    hasToBeFilled,
    hasToBeAValidPassword,
    hasToBeAValidEmail,
    hasToBeAValidUrl,
    hasToBeEqualToNewPassword,
    hasToBeAValidPostback,
    hasToBeAValidPhoneNumber,
    hasToBeAValidWalletId,
    hasToBeFilledForWhatsApp,
};
