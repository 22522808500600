import { _, DateTime } from "UIV2";
import { LOCAL_STORAGE_NAMES, LOCAL_STORAGE_VALID_TIME } from "UIV2/base/context/variables/variables.js";

export const cleanUpLocalStorageISO = () => {
    const currTs = DateTime.now().ts;
    const lSName = LOCAL_STORAGE_NAMES?.latestApiResponses;
    const currLSData = JSON.parse(localStorage.getItem(lSName));
    const newLSObj = {};
    _.forOwn(currLSData, (v, k) => {
        const existingTs = v?.ts;
        if (existingTs + LOCAL_STORAGE_VALID_TIME > currTs) newLSObj[k] = v;
    });
    localStorage.setItem(lSName, JSON.stringify(newLSObj));
};
