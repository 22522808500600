import { styled, css, g } from "UIV2/base/styledMixins/styledMixins.js";

export const Container = styled.div`
    display: none;
    ${g.phone(css`
        display: inline;
        pointer-events: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 8rem;
    `)}
`;

export const Svg = styled.svg`
    fill: ${g.getColor("background")};
`;

export const LogoForMobile = () => {
    return (
        <Container>
            <Svg viewBox="0 0 166.1 32.1" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M111.2,15.6L110,30.4c0,0.4-0.5,0.5-0.7,0.2l-2.3-3.9c-0.2-0.3-0.5-0.3-0.7,0l-1.9,3.2c-0.1,0.1-0.3,0.2-0.5,0.1l-2.1-1.2
	c-0.1-0.1-0.2-0.3-0.1-0.5l1.9-3.2c0.2-0.3,0-0.6-0.3-0.6h-4.5c-0.4,0-0.5-0.5-0.2-0.7l12.2-8.5C111.1,15.1,111.5,15.3,111.2,15.6z
	 M45.3,10.5h-5.1c-0.4,0-0.6,0.2-0.6,0.6v19.6c0,0.4,0.2,0.6,0.6,0.6h5.1c0.4,0,0.6-0.2,0.6-0.6V11.2C45.9,10.8,45.7,10.5,45.3,10.5
	z M35.1,0.3H30c-0.4,0-0.6,0.2-0.6,0.6v29.9c0,0.4,0.2,0.6,0.6,0.6h5.1c0.4,0,0.6-0.2,0.6-0.6V0.9C35.7,0.6,35.5,0.3,35.1,0.3z
	 M22.7,23.1c-0.3-0.3-0.6-0.3-0.9,0c-1.7,1.5-3.8,2.4-6.4,2.4c-4.9,0-8.6-3.8-8.6-8.9s3.8-8.9,8.5-9c2.6,0,4.7,0.9,6.4,2.4
	c0.3,0.3,0.6,0.3,0.9,0l3.3-3.6c0.3-0.3,0.3-0.6,0-0.9c-2.6-2.6-6.3-4.3-10.5-4.3C6.9,1.2,0,8.1,0,16.6C0,25.2,6.9,32,15.5,31.9
	c4.1,0,7.9-1.7,10.5-4.4c0.3-0.3,0.3-0.6,0-0.9L22.7,23.1z M64.9,24.5c-0.3-0.3-0.6-0.3-0.9,0c-1.2,1-2.4,1.6-4,1.6
	c-2.8,0-4.7-2.2-4.7-5s2-5,4.7-5c1.5,0,2.6,0.6,3.8,1.6c0.3,0.3,0.6,0.3,0.9,0l3.1-3c0.2-0.3,0.3-0.6,0-0.9c-2-2.3-4.8-3.7-8.1-3.7
	c-6,0-10.7,4.8-10.7,11c0,6.1,4.7,11,10.7,11c3.3,0,6.2-1.5,8.2-3.7c0.3-0.3,0.2-0.6,0-0.9L64.9,24.5z M42.7,0
	c-1.9,0-3.5,1.5-3.5,3.5c0,1.9,1.6,3.4,3.5,3.4s3.4-1.5,3.4-3.4C46.1,1.5,44.6,0,42.7,0z M149,10.5h-5.1c-0.4,0-0.6,0.2-0.6,0.6
	v11.1c0,1.6-1.2,3.7-4,3.7c-1.6,0-3.7-0.9-3.7-4.3V11.2c0-0.4-0.2-0.6-0.6-0.6h-5.1c-0.4,0-0.6,0.2-0.6,0.6V23c0,5.6,3.3,9,8,9
	c2.5,0,4.6-1.4,6.1-3.5l0.2,2.3c0,0.4,0.2,0.6,0.6,0.6h4.9c0.4,0,0.6-0.2,0.6-0.6V11.2C149.6,10.8,149.4,10.5,149,10.5z M92.3,10.5
	h-5.8c-0.3,0-0.5,0.1-0.7,0.3l-8.3,7.4V0.9c0-0.4-0.2-0.6-0.6-0.6h-5.1c-0.4,0-0.6,0.2-0.6,0.6v29.9c0,0.4,0.2,0.6,0.6,0.6h5.1
	c0.4,0,0.6-0.2,0.6-0.6v-4.7l2.5-2.7l5.8,7.7c0.2,0.3,0.4,0.4,0.8,0.4h5.5c0.5,0,0.6-0.3,0.4-0.8l-8.6-11.5l8.8-7.8
	C93.1,10.9,92.9,10.5,92.3,10.5z M165.5,10.5h-3V4.8c0-0.4-0.2-0.6-0.6-0.6h-5.1c-0.4,0-0.6,0.2-0.6,0.6v5.8h-3
	c-0.4,0-0.6,0.2-0.6,0.6v3.9c0,0.4,0.2,0.6,0.6,0.6h3v15.1c0,0.4,0.2,0.6,0.6,0.6h5.1c0.4,0,0.6-0.2,0.6-0.6V15.7h3
	c0.4,0,0.6-0.2,0.6-0.6v-3.9C166.1,10.8,165.9,10.5,165.5,10.5z M106.4,1.8c-8.3,2.2-13,10.7-10.9,18.8c0.2,0.7,0.5,1.4,0.7,2.1
	l5.5-3.6c0,0,0-0.1,0-0.2c-1.3-4.7,1.5-9.4,6.4-10.7c4.8-1.3,9.7,1.4,10.9,6.1c1.3,4.7-1.5,9.5-6.4,10.8c0,0,0,0-0.1,0l-0.3,6.8
	c0.7-0.1,1.4-0.2,2.1-0.4c8.3-2.2,13.1-10.7,10.9-18.9C123.1,4.5,114.7-0.4,106.4,1.8z"
                />
            </Svg>
        </Container>
    );
};
