import { useContext, useMemo, IconOffer, IconUser, IconReport, MainContext, IconQuestion } from "UIV2";
import { IconShare } from "UIV2";
import { SidebarButton } from "../SidebarButton/SidebarButton.js";
import { Container } from "./ButtonArea.styled.js";
import { siteMap } from "UIV2/base/routes/siteMap.js";

export const ButtonArea = (props) => {
    const { selectedItem, setSelectedItem, setMouseOverImmediately } = props;
    const { goTo, user = { firstname: "Unknown", lastname: "User" } } = useContext(MainContext);
    const firstLettersOfName = useMemo(() => {
        if (!user) return "";
        const getFirstLetter = (str) => str?.slice(0, 1)?.toUpperCase();
        return getFirstLetter(user.firstname) + getFirstLetter(user.lastname);
    }, [user]);

    const onButtonClick = (name) => {
        const filteredMenu = siteMap.filter((o) => o[4] === name);
        if (filteredMenu.length > 1) {
            setMouseOverImmediately();
            setSelectedItem(selectedItem === name ? undefined : name);
        } else goTo(filteredMenu[0][2]);
    };

    /**
     * Return
     */
    return (
        <Container>
            <div>
                <SidebarButton
                    label="Report"
                    name="report"
                    icon={IconReport}
                    onClick={onButtonClick}
                    selectedItem={selectedItem}
                />
                <SidebarButton
                    label="Market"
                    name="market"
                    icon={IconOffer}
                    onClick={onButtonClick}
                    selectedItem={selectedItem}
                />
                <SidebarButton
                    label="Share & Earn"
                    name="shareEarn"
                    icon={IconShare}
                    onClick={onButtonClick}
                    selectedItem={selectedItem}
                />
            </div>
            <div>
                <SidebarButton
                    label="How To"
                    name="howTo"
                    icon={IconQuestion}
                    onClick={onButtonClick}
                    selectedItem={selectedItem}
                />
                <SidebarButton
                    label={firstLettersOfName}
                    name="user"
                    icon={IconUser}
                    onClick={onButtonClick}
                    selectedItem={selectedItem}
                    noBorder
                />
            </div>
        </Container>
    );
};
