import { useContext, useRef, useEffect, MainContext } from "UIV2";

export const IdleManager = () => {
    const { getTimes, getLatestHashes } = useContext(MainContext);
    const timeoutRef = useRef();
    const time = 60 * 60 * 1000; // 60*60*1000 // 1h

    /**
     * Renew Even Never Timeout
     */
    const setRenewTimeout = () => {
        timeoutRef.current = setTimeout(() => {
            renewData();
        }, time);
    };
    const renewData = () => {
        console.log("idleManager updated the latest hashes and currentTimes");
        clearTimeout(timeoutRef.current);
        getLatestHashes();
        getTimes();
        setRenewTimeout();
    };
    useEffect(() => setRenewTimeout(), []);

    /**
     * CleanUp
     */
    useEffect(
        () => () => {
            console.log("cleanUp for IdleManager");
            clearTimeout(timeoutRef.current);
        },
        [],
    );

    /**
     * Return
     */
    return null;
};
