// import { CircleTopLeftContainer } from "./Layout.styled.js";
import { styled } from "UIV2/base/styledMixins/styledMixins.js";

const Container = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
    opacity: 0.5;
    mix-blend-mode: multiply;
    pointer-events: none;
`;

export const ContentCircleBottom = () => (
    <Container>
        <svg width="592" height="723" viewBox="0 0 592 723" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="890.5" cy="890.712" r="840.25" stroke="#F5F9FF" strokeWidth="100" />
        </svg>
    </Container>
);
