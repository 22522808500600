//  background-color: ${(props) => (props.test2Color ? props.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.phone(css` background-color: green; `)}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};

import { styled, g, css } from "UIV2/base/styledMixins/styledMixins.js";

export const Container = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;

    ${g.media(
        "uhd, uhd8",
        css`
            & > div {
                width: 100%;
            }
        `,
    )}
`;
