import { useContext, MainContext } from "UIV2";
import { styled } from "UIV2/base/styledMixins/styledMixins.js";

export const InfoText = styled.div`
    width: 100%;
    font-size: 2rem;
    font-weight: 300;
    padding-top: 5rem;
    padding-left: 5rem;
`;

export const LoadingOrEmpty = (props) => {
    const { isEmpty = false, children } = props || {};
    const { apiLoading = false } = useContext(MainContext);

    /**
     * Return
     */
    if (apiLoading) return <InfoText>Loading...</InfoText>;
    if (isEmpty) return <InfoText>There is no data to show.</InfoText>;
    return <>{children}</>;
};
