import { siteMap } from "UIV2/base/routes/siteMap.js";
import { CustomScrollbar } from "UIV2";
import { Container, SidebarLinks } from "./CallFromSiteMap.styled.js";

export const CallFromSiteMap = ({ get, ...props }) => {
    return (
        <CustomScrollbar>
            <Container>
                {siteMap.map((item, key) => {
                    if (item[4] === get)
                        return (
                            <SidebarLinks key={key} to={item[2]}>
                                {item[0]}
                            </SidebarLinks>
                        );
                    else return null;
                })}
            </Container>
        </CustomScrollbar>
    );
};
