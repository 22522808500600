import { useLayoutEffect, useEffect, useContext } from "react";
import { MainContext } from "UIV2";
import { Container, SideArea, ContentArea } from "./Layout.styled.js";
import { CircleTopLeft } from "./CircleTopLeft.js";
import { CircleTopCenter } from "./CircleTopCenter.js";
import { CircleBottomRight } from "./CircleBottomRight.js";
import { SideAreaBack } from "./SideAreaBack.js";
import { ContentCircleTop } from "./ContentCircleTop.js";
import { ContentCircleBottom } from "./ContentCircleBottom.js";
import { LandingBox } from "./LandingBox.js";

export const Layout = (props) => {
    const { children, pageName, ready4Screen, Side, logoSuffix, title, BoxFooter, wideBox } = props || {};
    const { FreeStyle, hideAdvertisersSignUp } = props || {};
    const { newPage, disableIsLoadingWithDelay, isLoadingAfterDelay } = useContext(MainContext);

    /**
     * New page properties
     */
    useLayoutEffect(() => newPage(pageName), [pageName]);
    useEffect(() => {
        if (ready4Screen) disableIsLoadingWithDelay();
    }, [ready4Screen]);

    /**
     * Return
     */
    if (isLoadingAfterDelay || !ready4Screen) return null;
    return (
        <Container aria-label="Layout">
            {!Side ? null : (
                <SideArea aria-label="SideArea">
                    <SideAreaBack />
                    <CircleTopLeft />
                    <CircleTopCenter />
                    <CircleBottomRight />
                    <Side hideAdvertisersSignUp={hideAdvertisersSignUp} />
                </SideArea>
            )}

            <ContentArea>
                {FreeStyle ? (
                    children
                ) : (
                    <LandingBox {...{ children, Side, logoSuffix, title, BoxFooter, wideBox }}>{children}</LandingBox>
                )}
            </ContentArea>
            <ContentCircleTop {...{ Side }} />
            <ContentCircleBottom />
        </Container>
    );
};
