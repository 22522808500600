//  background-color: ${(props) => (props.test2Color ? props.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.phone(css` background-color: green; `)}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};

import { styled, g } from "UIV2/base/styledMixins/styledMixins.js";
import { Select, Tag } from "antd";

export const AntSelect = styled(Select)`
    min-width: 15rem;
`;
export const AntTag = styled(Tag)`
    border: none;
    background: rgba(0, 0, 0, 0);
    margin-top: 0.3rem;
    font-size: 1.4rem;
    color: ${g.getColor("grey60")};
    /* width: 7.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
`;
export const PublisherContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 100%;
    min-width: min-content;
    height: 5rem;
    margin-bottom: 0.3rem;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")} !important;
    transition: all 250ms ease-in-out;

    &:hover {
        background: ${g.getColor("grey10")};
    }

    & > label {
        position: absolute;
        width: max-content;
        font-size: 1.2rem;
        font-weight: 500;
        margin-top: -1rem;
        margin-left: 1rem;
        padding: 0 1rem;
        z-index: 2;
        opacity: ${(props) => (props.value || props.isFocused ? 1 : 0)};
        color: ${(props) => (props.error ? g.getColor("error") : g.getColor("grey40"))};
        pointer-events: none;
        user-select: none;
        background: ${g.getColor("background")};
        border-radius: 1rem;
        white-space: nowrap;
    }

    // hack ant select
    & > .ant-select {
        height: 5rem;
        width: 100%;

        & > .ant-select-selector {
            width: 100%;
            height: 5rem !important;
            font-size: 1.4rem;
            cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")} !important;
            padding: 0;
            padding-left: 2rem !important;
            background-color: transparent;
            transition: none !important;
            border: ${(props) => `0.1rem solid ${props.error ? g.getColor("error") : g.getColor("grey40")}`} !important;
            border-radius: 1rem;
            outline: 0;
            box-shadow: 0 0 0 0px rgba(0, 0, 0, 0) !important;

            & > .ant-select-selection-search > .ant-select-selection-search-input {
                height: 5rem !important;
                width: 100%;
                padding-left: 2.2rem;
                cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")} !important;
            }
            & > .ant-select-selection-placeholder,
            & > .ant-select-selection-item {
                text-align: left;
                width: 100%;
                line-height: 5rem !important;
                height: 5rem !important;
                font-size: 1.4rem;
                padding-left: ${(props) => (props.multiple ? "1rem" : "0rem")};
                color: ${(props) => (props.error ? g.getColor("error") : g.getColor("grey60"))};
                opacity: ${(props) => (props.isFocused ? 0 : 1)};
                font-variant-ligatures: no-common-ligatures;
                text-align: left;
            }
            & > .ant-select-selection-overflow {
                display: flex;
                flex-wrap: nowrap;

                & > .ant-select-selection-overflow-item {
                    font-size: 1.4rem;
                    color: ${(props) => (props.error ? g.getColor("error") : g.getColor("grey60"))};
                    font-variant-ligatures: no-common-ligatures;

                    & > .ant-select-selection-item {
                        height: 2.5rem;
                        line-height: 2.5rem;
                        background: rgba(0, 0, 0, 0) !important;
                        border: none;
                        cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
                    }
                }
            }
        }

        & > .ant-select-clear {
            margin-right: 1rem;
            background: ${g.getColor("background")} !important;
        }

        & > .ant-select-arrow {
            margin-right: 1rem;
        }
    }
    &
        > .ant-select-focused
        > .ant-select-selector
        > .ant-select-selection-overflow
        > .ant-select-selection-overflow-item {
        max-width: 0px !important;
        opacity: 0 !important;
        pointer-events: none !important;
    }
    &
        > .ant-select-focused
        > .ant-select-selector
        > .ant-select-selection-overflow
        > .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
        opacity: 1 !important;
        pointer-events: auto !important;
        max-width: 70% !important;
    }
`;
export const LandingContainer = styled(PublisherContainer)`
    & > label {
        background: white;
    }
`;
