import { css } from "styled-components";
import { clientEventsFn } from "UIV2/base/context/functions/clientEventsFn.js";
import globalBreakpoints from "UIV2/base/scss/_breakpoints.module.scss";
import globalColors from "UIV2/base/scss/_colors.module.scss";

export { default as styled, css, keyframes } from "styled-components";

/**
 * Get Global Variables
 */

const clientEvents = clientEventsFn(globalBreakpoints);
const lib = clientEvents.bpArray;
const maxRatio = clientEvents.maxAspRatio * 10 + "/10";
const minRatio = clientEvents.minAspRatio * 10 + "/10";

/**
 * Mixin Functions
 */
export const getColor = (colorName) => globalColors[colorName] || "#000";
export const phone = (content) => css`
    @media (max-aspect-ratio: ${maxRatio}), (min-aspect-ratio: ${minRatio}), (max-width: ${lib.phone[1] + "px"}) {
        ${content}
    }
`;
export const tablet = (content) => css`
    @media (min-aspect-ratio: ${maxRatio}) and (max-aspect-ratio: ${minRatio}) and (min-width: ${lib.tablet[0] +
        "px"}) and (max-width: ${lib.tablet[1] + "px"}) {
        ${content}
    }
`;
export const desktop = (content) => css`
    @media (min-aspect-ratio: ${maxRatio}) and (max-aspect-ratio: ${minRatio}) and (min-width: ${lib.desktop[0] +
        "px"}) and (max-width: ${lib.desktop[1] + "px"}) {
        ${content}
    }
`;
export const large = (content) => css`
    @media (min-aspect-ratio: ${maxRatio}) and (max-aspect-ratio: ${minRatio}) and (min-width: ${lib.large[0] +
        "px"}) and (max-width: ${lib.large[1] + "px"}) {
        ${content}
    }
`;
export const uhd = (content) => css`
    @media (min-aspect-ratio: ${maxRatio}) and (max-aspect-ratio: ${minRatio}) and (min-width: ${lib.uhd[0] +
        "px"}) and (max-width: ${lib.uhd[1] + "px"}) {
        ${content}
    }
`;
export const uhd8 = (content) => css`
    @media (min-aspect-ratio: ${maxRatio}) and (max-aspect-ratio: ${minRatio}) and (min-width: ${lib.uhd8[0] +
        "px"}) and (max-width: ${lib.uhd8[1] + "px"}) {
        ${content}
    }
`;
export const media = (arr, content) => {
    let result = [];

    if (arr.includes("phone")) result = [...result, ...phone(content)];
    if (arr.includes("tablet")) result = [...result, ...tablet(content)];
    if (arr.includes("desktop")) result = [...result, ...desktop(content)];
    if (arr.includes("large")) result = [...result, ...large(content)];
    if (arr.includes("uhd")) result = [...result, ...uhd(content)];
    if (arr.includes("uhd8")) result = [...result, ...uhd8(content)];

    return result;
};

/**
 * Export All
 */
export const g = {
    phone,
    tablet,
    desktop,
    large,
    uhd,
    uhd8,
    media,
    getColor,
};
