import { Scrollbars } from "react-custom-scrollbars";
import c from "./CustomScrollbar.module.scss";

export const CustomScrollbar = ({ children, variant = "", ...props }) => {
    return (
        <Scrollbars
            {...props}
            renderTrackVertical={(p) => (
                <div className={variant === "Home" ? c.TrackVerticalHome : c.TrackVertical} {...p} />
            )}
            renderThumbVertical={(p) => (
                <div className={variant === "Home" ? c.ThumbVerticalHome : c.ThumbVertical} {...p} />
            )}
            autoHide
            // autoHideTimeout={5000}
            // autoHideDuration={200}
        >
            {children}
        </Scrollbars>
    );
};
