import { _ } from "UIV2";

export const clientEventsFn = (globalBreakpoints) => {
    /**
     * Remove minAspRatio maxAspRatio from globalBreakpoints
     */
    const convertStringMathToNumber = (str) => {
        if (!str) return;
        const split = str.split('"').join("").split("/");
        return Number(split[0]) / Number(split[1]);
    };
    const globalBreakpointsClone = _.cloneDeep(globalBreakpoints);
    const minAspRatio = convertStringMathToNumber(globalBreakpoints.minAspRatio);
    delete globalBreakpointsClone["minAspRatio"];
    const maxAspRatio = convertStringMathToNumber(globalBreakpoints.maxAspRatio);
    delete globalBreakpointsClone["maxAspRatio"];

    /**
     * Vars
     */
    const WINW = window.innerWidth || document.documentElement.clientWidth || 0;
    const WINH = window.innerHeight || document.documentElement.clientHeight || 0;
    const aRatio = Number((WINW / WINH).toFixed(2));
    const isMobile = WINW < 601 ? true : false;
    // const { detect } = require("detect-browser"); // yarn add detect-browser
    // const detectBrowser = detect();
    let currentBreakpoint;
    let isSafeSize;
    let isOutOfRatio;
    let sortedBreakpoints = Object.entries(globalBreakpointsClone).sort((a, b) => a[1] - b[1]);

    /**
     * Create bpArray for styled Components
     * ["phone",min,max]
     */
    let bpArray2 = _.cloneDeep(sortedBreakpoints);
    let bpArray = {};
    _.forEach(sortedBreakpoints, (item, index) => {
        if (index === bpArray2.length - 1) {
            item[2] = "100000";
        } else {
            item[2] = bpArray2[index + 1][1];
        }
        item[1] = Number(item[1]);
        item[2] = Number(item[2]);
        bpArray[item[0]] = [item[1], item[2]];
    });

    /**
     * Define currentBP
     */
    if (aRatio < maxAspRatio || aRatio > minAspRatio) {
        isOutOfRatio = true;
        isSafeSize = false;
        currentBreakpoint = sortedBreakpoints[0][0];
    } else {
        isSafeSize = WINW > 960 ? true : false;
        let i = 0;
        while (i < sortedBreakpoints.length && Number(sortedBreakpoints[i][1]) < WINW) i++;
        currentBreakpoint = sortedBreakpoints[i - 1 >= 0 ? i - 1 : 0][0];
    }
    sortedBreakpoints = sortedBreakpoints.map((item) => item[0]);

    /**
     * Return
     */
    return {
        winW: WINW,
        winH: WINH,
        minAspRatio,
        maxAspRatio,
        bpArray,
        isOutOfRatio,
        sortedBreakpoints,
        currentBreakpoint,
        isSafeSize,
        isMobile,
        // browser: detectBrowser.name || "undefined",
        // browserVersion: detectBrowser.version || "undefined",
        // os: detectBrowser.os || "undefined",
        language: navigator.language || navigator.userLanguage || "undefined",
    };
};

//

/**
 * List of Language Codes
 * Here is the full list:
 * https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 *
 * en   English
 * tr   Turkish
 * pt   Portegues
 * es   Spanish
 * th   Thai
 * de   German
 * fr   French
 * zh   Chinese
 * it   Italian
 */
