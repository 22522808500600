import { useLayoutEffect, useRef, Fragment } from "react";
import { Button } from "UIV2/libraries/useXForm";
import { LoadMoreContainer, PageNumbers, Top, ButtonArea, ThumbsArea, Seperator } from "./CardsGridGenerator.styled.js";

export const CardsGridGenerator = (props) => {
    const { SingleCardComponent, data = [], totalLength, offset, setOffset, featuredItemsLength } = props || {};
    const top = useRef();
    const bottom = useRef();
    const { offsetEnd, column, maxThumbInAPage } = offset || {};

    /**
     * Calculate new offsetEnd
     */
    const calculateNewOffsetEnd = () => {
        if (!data || data.length < 1 || !offsetEnd) setOffset(maxThumbInAPage, "offsetEnd");
        else setOffset(Math.max(Math.floor(offsetEnd / maxThumbInAPage), 1) * maxThumbInAPage, "offsetEnd");
    };
    useLayoutEffect(() => calculateNewOffsetEnd(), [offset?.maxThumbInAPage]);

    /**
     * Basic Functions
     */
    const onLoadMore = () =>
        setOffset({
            ...offset,
            offsetEnd: offsetEnd + maxThumbInAPage,
            offsetStart: data?.length + featuredItemsLength + 1 || 1,
        });
    const scrollToTop = () => top.current.scrollIntoView();
    const scrollToBottom = () => bottom.current.scrollIntoView();

    /**
     * Return
     */
    return (
        <>
            <div ref={top} />
            <InfoRows {...{ onLoadMore, header: true, offsetEnd, totalLength, data }} />
            <ThumbsArea {...{ column }}>
                {data?.slice(0, offsetEnd).map((cardData, key) => {
                    if (
                        key + featuredItemsLength + 1 > maxThumbInAPage &&
                        (key + featuredItemsLength + 1) % maxThumbInAPage === 1
                    ) {
                        return (
                            <Fragment key={key}>
                                <Seperator {...{ column }}>
                                    <Top onClick={() => scrollToBottom()}>Bottom</Top>
                                    <div>Page {(key + featuredItemsLength) / maxThumbInAPage + 1}</div>
                                    <Top onClick={() => scrollToTop()}>Top</Top>
                                </Seperator>
                                <SingleCardComponent {...{ cardData, column }} />
                            </Fragment>
                        );
                    } else {
                        return <SingleCardComponent key={key} {...{ cardData, column }} />;
                    }
                })}
            </ThumbsArea>
            <InfoRows {...{ onLoadMore, scrollToTop, offsetEnd, totalLength, data }} />
            <div ref={bottom} />
        </>
    );
};

const InfoRows = (props) => {
    const { onLoadMore, header, scrollToTop, offsetEnd, totalLength, data } = props;

    /**
     * Return
     */
    return (
        <LoadMoreContainer header={header}>
            <PageNumbers header={header}>{`1 - ${
                offsetEnd < totalLength ? data?.length : totalLength
            } offers of ${totalLength}`}</PageNumbers>
            {header || data?.length >= totalLength ? null : <LoadMoreButton {...{ onLoadMore }} />}
            {header ? null : <Top onClick={() => scrollToTop()}>Top</Top>}
        </LoadMoreContainer>
    );
};

const LoadMoreButton = ({ onLoadMore }) => (
    <ButtonArea>
        <Button
            onClick={onLoadMore}
            // loading={form?.isSubmitting}
            // variant="landing"
            // priority="primary"
            // type="submit"
            // disabled={!form?.isValid || !form?.isDirty}
        >
            Load More
        </Button>
    </ButtonArea>
);
