export const goToISO = (val = "", newTarget = false, states) => {
    const { navigate } = states || {};
    if (!newTarget) {
        if (val && val[0] === "/") navigate(val);
        else navigate("/" + val);
    } else {
        const origin = window?.location?.origin;
        window.open(val && val[0] === "/" ? origin + val : origin + "/" + val);
    }
};
