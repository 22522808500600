import { _, generateRandomText } from "UIV2";
export const addToSnackbarISO = (p, states) => {
    const { status, msg, actions } = p || {};
    const { setSnackState } = states || {};
    const newKey = generateRandomText(12);
    setSnackState({ status, msg, actions, key: newKey }, newKey);
};
export const removeFromSnackbarISO = (key, states) => {
    const { setSnackState, getSnackState } = states || {};
    const newSnackState = _.cloneDeep(getSnackState());
    delete newSnackState[key];
    setSnackState(newSnackState);
};
export const clearAllSnackbarISO = (states) => {
    const { setSnackState } = states || {};
    setSnackState();
};
