import { styled, g } from "UIV2/base/styledMixins/styledMixins.js";
import { Link } from "react-router-dom";

export const Container = styled.div`
    padding: 3rem;
    padding-top: 20rem;
    width: 44rem;

    & > a {
        display: block;
        width: max-content;
    }
`;

export const SidebarLinks = styled(Link)`
    position: relative;

    font-size: 2.2rem;
    line-height: 1.75;
    max-width: 40rem;
    line-height: 1.5;
    font-weight: 300;
    color: ${g.getColor("background")};
    text-decoration: none;
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    &::after {
        display: block;
        position: absolute;
        margin-top: 0.2rem;
        content: "";
        width: 0%;
        height: 0.2rem;
        background-color: ${g.getColor("background")};
        transition: all 150ms ease-in-out;
    }
    &:hover,
    &:active {
        text-decoration: none;
        color: ${g.getColor("background")};
        &::after {
            width: 100%;
        }
    }
`;
