import { styled, g, css } from "UIV2/base/styledMixins/styledMixins.js";

export const Container = styled.div`
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: nowrap;
    user-select: none;
    background: #f6f9ff;
    font-variant-ligatures: no-common-ligatures;
`;

/**
 * SIDEBAR
 */
export const SideArea = styled.div`
    position: sticky;
    flex: 0 30%;
    width: 30%;
    min-height: 70rem;
    max-width: 60rem;
    min-width: 30%;
    height: 100vh;
    top: 0px;

    display: flex;
    padding: 5rem;
    padding-right: 10rem;
    align-items: center;
    color: white;
    overflow: hidden;
    ${g.media(
        "phone,tablet",
        css`
            display: none;
        `,
    )}
`;

/**
 * CONTENT AREA
 */

export const ContentArea = styled.div`
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${g.media(
        "phone,tablet",
        css`
            padding-top: 5rem;
            justify-content: flex-start;
        `,
    )}
`;

export const BoxArea = styled.div`
    position: relative;
    width: 100%;
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem;
    ${g.phone(
        css`
            padding: 2rem;
        `,
    )}
`;
export const BoxDefaults = styled.div`
    max-width: 70rem;
    width: 100%;
    min-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

/**
 * BOX HEADER
 */
export const BoxHeaderArea = styled(BoxDefaults)`
    display: flex;
    width: 100%;
    max-width: 100%;
    align-items: baseline;
    margin-bottom: 1rem;
`;
export const LogoArea = styled.div`
    width: 11rem;
    cursor: pointer;
`;
export const LogoSuffix = styled.div`
    top: -0.3rem;
    margin-left: 1rem;
    font-size: 2.6rem;
    font-weight: 700;
    color: ${g.getColor("secondary")};
    font-variant-ligatures: no-common-ligatures;
`;

/**
 * BOX
 */
export const BoxTitle = styled.div`
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 2.2rem;
    font-variant-ligatures: no-common-ligatures;
    align-self: center;
    text-align: center;
`;
export const Box = styled(BoxDefaults)`
    max-width: ${(props) => (props.Side ? "70rem" : props.wideBox ? "80rem" : "50rem")};
    padding: 6rem 3rem;
    background: white;
    border-radius: 3rem;
    box-shadow: 3rem 3rem 3rem rgba(25, 25, 27, 0.1);
    z-index: 3;
    flex-direction: column;
    justify-content: center;
`;

/**
 * BOX FOOTER
 */
export const BoxFooterArea = styled(BoxDefaults)`
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-variant-ligatures: no-common-ligatures;

    & > div {
        display: flex;
    }
`;

/**
 * Full Col.
 */
export const FullWidth = styled(BoxDefaults)`
    width: 100%;
    display: block;
    & > form,
    & > div {
        width: 100%;
    }
`;

/**
 * PAGE FOOTER
 */
export const FooterArea = styled.div`
    flex: 0 5rem;
    width: 90%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 2rem;

    ${g.media(
        "phone,tablet",
        css`
            flex-direction: column-reverse;
            justify-content: flex-start;
            order: 2;
            width: 100%;

            & > div:nth-child(2) {
                margin-bottom: 2rem;
                order: 1;
            }
        `,
    )}
`;
