import { MainContext, useContext, useEventListener, _, clientEventsFn, useLayoutEffect } from "UIV2";
import { checkUserISO } from "../functions/userdataISO";
import { cleanUpLocalStorageISO } from "../functions/cleanUpLocalStorageISO.js";

export const EffectsAndListeners = () => {
    const states = useContext(MainContext);
    const { setClientEvents, globalBreakpoints, getLatestHashes } = states || {};

    /**
     * Update clientEvents on resize with debounce
     */
    const reDefineClientEvents = () => setClientEvents(clientEventsFn(globalBreakpoints));
    useEventListener("resize", _.debounce(reDefineClientEvents, 500), window, { passive: true });

    /**
     * Get latest metadata hashes on the first load
     */
    useLayoutEffect(() => {
        const fn = async () => await getLatestHashes(states);
        fn();
    }, []);

    /**
     * Check user perms on the first load
     */
    useLayoutEffect(() => {
        const fn = async () => await checkUserISO(states);
        fn();
    }, []);

    /**
     * Make a clean up via local storage for latest_api_calls
     * Clean items which are older than expected (10mins) time.
     */
    useLayoutEffect(() => cleanUpLocalStorageISO(), []);

    return null;
};
