//  background-color: ${(props) => (props.test2Color ? props.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.phone(css` background-color: green; `)}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};

import { styled, css, g } from "UIV2/base/styledMixins/styledMixins.js";

export const Container = styled.div`
    width: 100%;
    max-width: 100vw;
    min-height: 100vh;
    box-sizing: border-box;
    position: relative;
    overflow: unset;
    border-right: 1px solid ${g.getColor("grey20")};

    ${g.media(
        "phone,tablet",
        css`
            flex-direction: column;
            align-items: flex-start;
        `,
    )}
`;
export const ContentArea = styled.div`
    width: ${(props) => (props.isFullScreen ? "100%" : "calc(100% - 5rem)")};
    min-height: 100vh;
    margin-left: ${(props) => (props.isFullScreen ? 0 : props.isMouseOver ? "10rem" : "5rem")};
    opacity: ${(props) => (props.isLoadingAfterDelayX2 ? 0 : props.isMouseOver ? 0.3 : 1)};
    transition: all 500ms ease-in-out;
    display: block;
    z-index: 101;

    ${g.media(
        "phone,tablet",
        css`
            width: 100%;
            margin-left: 0;
        `,
    )}
`;
export const ObserverBar = styled.div`
    width: 100%;
    height: 3rem;
    background: green;
    color: white;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
`;
