import { Flags } from "UIV2";
import {
    AllPlatforms,
    Android,
    DesktopAll,
    Ios,
    Linux,
    MacOs,
    MobileAll,
    Windows,
    WinMobile,
} from "UIV2/libraries/IconLib/Platforms.js";
import { Container, IconDiv, LabelArea } from "./CustomOptions.styled.js";

export const CustomOptionsForGeos = (props) => {
    const { label } = props || {};

    return (
        <Container>
            <IconDiv>
                <Flags label={label} />
            </IconDiv>
            <LabelArea>{label}</LabelArea>
        </Container>
    );
};
export const CustomOptionsForPlatforms = (props) => {
    const { label } = props || {};
    const size = 2;
    return (
        <Container>
            <IconDiv>
                {label === "Mobile - Android" ? (
                    <Android size={size} />
                ) : label === "Mobile - iOS" ? (
                    <Ios size={size} />
                ) : label === "Mobile - Windows" ? (
                    <WinMobile size={size} />
                ) : label === "Select All Mobile" ? (
                    <MobileAll size={size} />
                ) : label === "Desktop - Windows" ? (
                    <Windows size={size} />
                ) : label === "Desktop - MacOs" ? (
                    <MacOs size={size} />
                ) : label === "Desktop - Linux" ? (
                    <Linux size={size} />
                ) : label === "Select All Desktop" ? (
                    <DesktopAll size={size} />
                ) : label === "Select All" ? (
                    <AllPlatforms size={size} />
                ) : (
                    <>•</>
                )}
            </IconDiv>
            <LabelArea>{label}</LabelArea>
        </Container>
    );
};
