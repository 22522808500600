//  background-color: ${(props) => (props.test2Color ? props.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.phone(css` background-color: green; `)}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};

import { Icon, IconThreeDot, useState } from "UIV2";
import { styled } from "UIV2/base/styledMixins/styledMixins.js";
import { Popover } from "antd";

/**
 * Styles
 */
export const ActionButton = styled.div`
    font-size: 1.3rem;
    background-color: rgba(0, 0, 0, 0);
    transition: all 250ms ease-in-out;
    padding: 1rem;
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;
export const ActionsDiv = styled.div`
    width: 100%;
    padding: 0;
`;
export const ThreeDotButton = styled.button`
    width: 5rem;
    height: 5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    & > div {
        transition: all 250ms ease-in-out;
        transform: ${(props) => (props.visible ? "rotate(90deg)" : "rotate(0deg)")};
    }

    &:hover > div {
        transform: rotate(90deg);
    }
`;

/**
 * Component
 */
export const ActionPopover = (props) => {
    const {
        actions = [
            { label: "Test Action1", fn: () => console.log("1") },
            { label: "Test Action2", fn: () => console.log("2") },
        ],
    } = props || {};
    const [visible, setVisible] = useState(false);

    /**
     * Return
     */
    if (!actions) return;
    return (
        <Popover
            content={
                <span>
                    <ActionsDiv>
                        {actions.map((item, index) => (
                            <ActionButton key={index} onClick={() => item.fn()}>
                                {item.label}
                            </ActionButton>
                        ))}
                    </ActionsDiv>
                </span>
            }
            trigger="click"
            visible={visible}
            onVisibleChange={() => setVisible(!visible)}
        >
            <ThreeDotButton {...{ visible }}>
                <Icon color={"primary"} icon={IconThreeDot} width={1.7} />
            </ThreeDotButton>
        </Popover>
    );
};
