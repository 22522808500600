import { styled, g, css } from "UIV2/base/styledMixins/styledMixins.js";

export const Container = styled.div`
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    z-index: 999;
    overflow-x: ${(props) => (props.isFullScreen ? "hidden" : props.isOverflowHidden ? "hidden" : "visible")};
    transition: all 100ms linear;
    width: ${(props) => (props.isFullScreen ? 0 : props.overMode ? "10rem" : "5rem")};

    ${g.media(
        "phone,tablet",
        css`
            display: none;
        `,
    )}
`;

export const SeperatorLine = styled.div`
    width: 0.1rem;
    height: 100%;
    background-color: ${g.getColor("grey20")};
    z-index: 11;
    position: absolute;
    top: 0;
    transition: all 100ms linear;
    left: ${(props) => (props.overMode ? "10rem" : "5rem")};
`;

export const Background = styled.div`
    width: ${(props) =>
        props.isLoadingAfterDelay || props.isModal
            ? "0rem"
            : props.selectedItem
            ? "60rem"
            : props.isMouseOver
            ? "10rem"
            : "5rem"};
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 9;
    transition: all 100ms ease-in-out;
    background: linear-gradient(120deg, #707070, #353535);
    opacity: ${(props) => (props.overMode ? 1 : 0)};

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: ${(props) => (props.selectedItem ? "50rem" : "0rem")};
        opacity: ${(props) => (props.selectedItem ? 1 : 0)};
        height: 100%;
        transition: opacity 150ms linear 150ms;
        background-color: rgba(0, 0, 0, 0.25);
    }
`;
