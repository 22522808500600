//  background-color: ${(props) => (props.test2Color ? props.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.phone(css` background-color: green; `)}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};

import { styled, g } from "UIV2/base/styledMixins/styledMixins.js";

export const OuterDiv = styled.div`
    position: relative;
    width: 100%;
    max-width: 100%;
    opacity: ${(props) => (props.isFullScreen ? 0 : 1)};
    display: ${(props) => (props.isFullScreen ? "none" : "block")};
    overflow: hidden;
`;
export const InnerDiv = styled.div`
    min-width: max-content;
    padding: 10px 0;
`;
export const ThumbHorizontal = styled.div`
    border-radius: 5rem;
    background-color: ${g.getColor("grey30")};
    cursor: pointer;
    height: 0.5rem;
`;
export const TrackHorizontal = styled.div`
    border-radius: 5rem;
    background-color: ${g.getColor("grey90")};
    cursor: pointer;
`;
