import { styled, css, g } from "UIV2/base/styledMixins/styledMixins.js";

export const Container = styled.div`
    position: absolute;
    width: 0;
    left: 10rem;
    height: 100%;
    overflow: hidden;
    z-index: 10;
    color: ${g.getColor("background")};
    /* transition: all 100ms ease-in-out; */

    ${(props) => {
        if (props.selectedItem) {
            return css`
                width: 50rem;
            `;
        }
    }}
`;
